import React, { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { formats } from '~/helper';
import { gray, primary, quaternary, secondary, white, lightGray, tertiary } from '~/components/mixins/color';
import { MdFirstPage, MdLastPage, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import Loading from '~/components/loading';
import EmptyState from '~/components/empyt-state';
import DataTableComponent, { createTheme } from 'react-data-table-component';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${quaternary.hex()};
  color: ${lightGray.hex()};

  > div:first-child {
    flex: 1;
    padding: ${(props) => props.padding || '10px'};
    overflow: hidden;
    height: 100%;
    margin: 0;
    > div {
      height: calc(100% - ${(props) => props.noPagination ? '0px' : '46px'});
      display: block;
    }
  }
  ${(props) => (!props.noPagination && !props.isEmpty) && css`
    > div:last-child {
      flex: 0 0 56px;
    }
  `}

  .rdt_Pagination {
    justify-content: center;
  }
  .rdt_TableBody {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 32px);
  }
  .rdt_Table {
    height: calc(100%);
  }
  .rdt_TableRow {
    button:hover:not(:disabled), button:focus, button:hover {
      background: transparent;
    }
  }
  .rdt_TableHeadRow {
    min-height: 40px;
  }
  .rdt_TableCol_Sortable:hover {
    color: ${white.hex()};
  }

`;

createTheme('adept', {
  text: {
    primary: white.hex(),
    secondary: lightGray.hex(),
  },
  background: {
    default: quaternary.hex(),
  },
  selected: {
    text: white.hex(),
    default: primary.hex(),
  },
  striped: {
    text: white.hex(),
    default: tertiary.hex(),
  },
  highlightOnHover: {
    text: white.hex(),
    default: secondary.hex(),
  },
  sortFocus: {
    text: white.hex()
  },
  context: {
    background: quaternary.hex(),
    text: white.hex(),
  },
  divider: {
    default: tertiary.hex(),
  },
  button: {
    default: lightGray.hex(),
    disabled: gray.hex(),
    focus: white.hex(),
    hover: white.hex(),
  },
  action: {
    button: lightGray.hex(),
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
});

const customStyles = {
  cells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  },
  headCells: {
    style: {
      paddingLeft: '10px',
      paddingRight: '10px',
    }
  }
};

const PaginationContainer = styled.div`
  display: flex !important;
  height: 56px !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid ${secondary.hex()};
  background: transparent;
  gap: 2px;

  span {
    font-size: 13px;
    color: ${lightGray.hex()};
    margin: 0 10px 0 0 !important;
    user-select: none;
  }
`;

const IconButton = styled.button`
  width: 40px;
  height: 40px;
  padding: 8px;
  display: inline-flex;
  margin: 0;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${lightGray.hex()};
  border: none;
  border-radius: 5px;
  background: transparent;

  &:hover:enabled, &:focus:enabled {
    background: ${secondary.hex()};
  }
  ${(props) => props.disabled && css`
    opacity: 0.5;
    cursor: default;
    user-select: none;
  `}
  .left-spacing {
    margin-left: 15px;
  }
`;

const Pagination = (data, onChangePage) => () => {
  let page = parseInt((data.offset || 0) / (data.limit || 1), 10) + 1,
    lastPage = Math.ceil(data.count / data.limit),
    fromNum = data.offset + 1,
    toNum = Math.min(data.limit * page, data.count),
    isFirst = page === 1,
    isLast = page === lastPage;

  return <PaginationContainer>
    <span>
      {fromNum}-{toNum} de {formats.number(data.count)}
    </span>
    <IconButton title="Primeiro" disabled={isFirst} onClick={() => onChangePage(1)}>
      <MdFirstPage />
    </IconButton>
    <IconButton title="Anterior" disabled={isFirst} onClick={() => onChangePage(page - 1)}>
      <MdNavigateBefore />
    </IconButton>
    <IconButton title="Próximo" disabled={isLast} onClick={() => onChangePage(page + 1)}>
      <MdNavigateNext />
    </IconButton>
    <IconButton title="Último" disabled={isLast} onClick={() => onChangePage(lastPage)}>
      <MdLastPage />
    </IconButton>
  </PaginationContainer>
};

function DataTable({ data, pageSize, columns, keyFieldFn, padding, keyField = 'id', extraOptions, loading, emptyText, noPagination, onRowClicked, onChangePage }) {
  const [ selected, setSelected ] = useState(null);
  const dataList = _.cloneDeep(data.rows);
  const isEmpty = _.isEmpty(data.rows);

  const updateState = useCallback((value) => {
    onRowClicked(value);
    setSelected(keyFieldFn ? keyFieldFn(value) : _.get(value, keyField))
  }, [onRowClicked, keyField, keyFieldFn]);

  const handleChangePage = useCallback((page) => {
    onChangePage(page);
  }, [onChangePage]);

  const selectableRowSelected = (row) => {
    return (keyFieldFn ? keyFieldFn(row) : _.get(row, keyField)) === selected;
  }
  const tableColumns = useMemo(() => columns, [columns]);

  return (
    <Container noPagination={noPagination} isEmpty={isEmpty} padding={padding}>
      <DataTableComponent
        striped
        highlightOnHover
        pointerOnHover
        dense
        persistTableHead
        showRowHover
        overflowY
        pagination={!noPagination}
        selectableRowsHighlight={true}
        selectableRowSelected={selectableRowSelected}
        noHeader
        theme="adept"
        paginationServer
        columns={tableColumns}
        keyField={keyField}
        data={dataList}
        paginationComponent={Pagination(data, (page) => handleChangePage((page - 1) * (data.limit || 0)) )}
        noDataComponent={<EmptyState text={emptyText} visible={true} />}
        onRowClicked={updateState}
        progressComponent={<Loading size={39} label="Carregando..." />}
        paginationPerPage={data.limit || pageSize}
        progressPending={loading}
        paginationTotalRows={data.count || 0}
        paginationComponentOptions={{ noRowsPerPage: true, rangeSeparatorText: 'de' }}
         customStyles={_.merge(customStyles, _.get(extraOptions, 'customStyles') || {})}
        {..._.omit(extraOptions, 'customStyles')}
        />
    </Container>
  );
}

DataTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.array,
    limit: PropTypes.number,
    count: PropTypes.number
  }).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hide: PropTypes.oneOf([ 'sm', 'md', 'lg' ]), // sm (599px), md (959px), lg (1280px)
      selector: PropTypes.string,
      cell: PropTypes.func
    })
  ).isRequired,
  pageSize: PropTypes.number,
  emptyText: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  noPagination: PropTypes.bool.isRequired,
  keyField: PropTypes.string,
  keyFieldFn: PropTypes.func,
  onRowClicked: PropTypes.func,
  onChangePage: PropTypes.func.isRequired,
  extraOptions: PropTypes.object,
}

DataTable.defaultProps = {
  data: {
    rows: [],
    limit: 1,
    count: 0
  },
  loading: false,
  selected: false,
  pageSize: 20,
  emptyText: 'No record',
  onRowClicked: _.noop,
  onChangePage: _.noop,
  noPagination: false,
  extraOptions: {}
}

export default DataTable;
