import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage, objectToFormData } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  term: null,
  offset: 0,
  model: null,
  data: { rows: [], count: 0, offset: 0 },
};

const useProducts = () => {
  const { state: { products = initialState }, dispatch } = useGlobalContext('products'),
    { actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: 'products/list',
    params: { term: products.term, offset: products.offset },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Produtos',
      count: _.get(data, 'count', 0),
      loading: _.get(products, 'loading', false),
      onSearch: (text) => list(0, text),
    });
    // eslint-disable-next-line
  }, [data, products]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true });
      let model = { active: true, features: [], modules: [], images: [] };

      if (data.id) {
        const response = await api.get('products/load', {
          params: { id: data.id },
        });
        model = response.data;
      }
      dispatch({ model, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      const tmp = {
        ..._.omit(model, 'modules'),
        modules: _.chain(model)
          .get('modules', [])
          .map((module) => _.pick(module, 'id', 'code', 'name', 'value'))
          .value(),
      };
      const formData = objectToFormData(tmp);
      await api({
        method,
        url: `products/${path}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      mutate(data);
      showMessage('success', `Produto ${editing ? 'atualizado' : 'criado'} com sucesso`);

      actions.toogleForm && actions.toogleForm();
      dispatch({ formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const removeImage = async (model, filename) => {
    try {
      dispatch({ formLoading: true });

      const images = model.images.filter((f) => f !== filename);
      const removedImages = (model.removedImages || []).concat(
        model.images.filter((f) => f === filename)
      );

      dispatch({
        model: { ..._.omit(model, 'images'), images, removedImages },
        formLoading: false,
      });
    } catch (error) {
      failure(error);
    }
  };

  const listModules = async (term, callback) => {
    try {
      const response = await api.get(`products/list-modules`, { params: { term }});
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...products,
      data: data || {},
      loading: !data,
    },
    actions: {
      pageChange,
      load,
      list,
      createOrUpdate,
      removeImage,
      listModules,
    },
  };
};

export default useProducts;
