import React from 'react';
import _ from 'lodash';
import { MdCheck, MdClear } from 'react-icons/md';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import FormFilter from '~/views/contract-models/filter';
import { Container } from '~/views/contract-models/styles';
import useContractModels from '~/view-data/use-contract-models';
import useHeader from '~/view-data/use-header';

export const columns = [
  {
    name: 'Nome',
    selector: 'name',
  },
  {
    name: 'Título',
    selector: 'title',
  },
  {
    name: 'Tipo',
    selector: 'contractType',
    hide: 'md',
    width: '170px',
    format: (row) => {
      const map = { 
        'contract': 'Contrato mãe',
        'addition': 'Contrato de aditamento',
        'additive': 'Aditivo',
        'cancellation': 'Distrato' 
      };
      return map[_.get(row, 'contractType')];
    }
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  }
];

export default function ContractModels({ history, match, location, acls }) {
  const canWrite = acls.includes('W');
  const { state, actions } = useContractModels();
  const { state: headerState, actions: headerActions } = useHeader();
  const showFilter = _.get(headerState, 'filter.visible');

  return (
    <Container>
      <Crud
        openForm={showFilter}
        useOpenForm={true}
        columns={columns}
        formTitle={(data) => 'Filtro'}
        emptyText="Nenhum módulo encontrado"
        data={state.data}
        hideAdd={!canWrite}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onCloseFilter={headerActions.hideFilter}
        onChangePage={actions.pageChange}
        onRowClicked={actions.load}
        formOptions={{
          initialValues: _.get(headerState, 'filter.data'),
        }}
        renderForm={(args) => (
          <FormFilter {...args} />
        )}
        actions={[
          {
            label: 'Limpar Filtro',
            icon: MdClear,
            action: () => actions.changeFilter({}),
          },
          {
            label: 'Aplicar Filtro',
            icon: MdCheck,
            action: (data) => actions.changeFilter(data),
          },
        ]}
      />
    </Container>
  );
}
