import React from 'react'
import { Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import Modal from '~/components/modal'
import { MdAdd, MdDelete, MdEdit, MdSave } from 'react-icons/md'
import { FlexRow } from '~/components/layout'
import {
  Autocomplete,
  InputLabel,
  InputGroup,
  InputMask,
  Select,
  CurrencyField,
} from '~/components/form'
import DataTable from '~/components/datatable'
import {
  Discount,
  ProductsContainer,
  ModalContainer,
} from '~/views/partners/contract/styles'
import { IconButton } from '~/components/button'
import Fieldset from '~/components/fieldset'
import { MASK_CNPJ, MASK_CPF, licenseModesMap } from '~/constants'
import confirm from '~/components/confirm'
import { onlyNumbers, formats, parseStringToDecimal } from '~/helper'

const CustomerSchema = Yup.object().shape({
  identify: Yup.string().nullable().required('Informe o CNPJ/CPF'),
  licenseMode: Yup.mixed().nullable().required('Informe o tipo de licença'),
})

const columns = (arrayHelpers, form) => [
  {
    name: 'Produto / Módulo',
    selector: 'name',
    format: (r) => `${r.groupName} / ${r.moduleName}`,
  },
  {
    name: 'Tipo de licença',
    selector: 'licenseMode',
    width: '330px',
    center: true,
    format: (r) => licenseModesMap[r.licenseMode] || r.licenseMode,
  },
  {
    name: 'Valor (R$)',
    selector: 'value',
    right: true,
    width: '120px',
    format: (r) => formats.decimal(isNaN(r.value) ? 0 : r.value * 1),
  },
  {
    name: 'Desconto (%)',
    selector: 'discount',
    right: true,
    width: '120px',
    format: (r) => {
      let val = parseStringToDecimal(r.discount)
      return formats.decimal(isNaN(val) ? 0 : val * 1)
    },
  },
  {
    name: 'Total (R$)',
    selector: 'value',
    right: true,
    width: '120px',
    format: (r) => {
      let discount = parseStringToDecimal(r.discount) || 0,
        value = isNaN(r.value) ? 0 : r.value * 1
      return formats.decimal(value - value * (discount / 100))
    },
  },
  {
    name: 'Ações',
    selector: '',
    hide: 'md',
    width: '80px',
    center: true,
    cell: (row, index) => {
      return (
        <>
          <IconButton
            size={32}
            type="button"
            title="Editar"
            onClick={() => editModule(form, row, index)}
          >
            <MdEdit />
          </IconButton>
          <IconButton
            size={32}
            type="button"
            title="Remover"
            onClick={() => handleRemoveModule(arrayHelpers, row, index)}
          >
            <MdDelete />
          </IconButton>
        </>
      )
    },
  },
]

const createOrUpdateModules = (arrayHelpers, form, values, row) => {
  console.log('createOrupdate', { arrayHelpers, form, values, row })

  let baseValue = {
    ..._.omit(row, 'module', 'index'),
    ...(_.get(row, 'module.raw') || {}),
  }

  if (row && row.index && !_.isNaN(row.index)) {
    form.setFieldValue(`modules[${row.index}]`, baseValue)
  } else {
    arrayHelpers.insert(0, baseValue)
  }
  form.setFieldValue('addForm', {})
}

const isValidModule = (row = {}) => {
  const module = _.get(row, 'module')
  return !!row.licenseMode && module
}

const editModule = (form, row, index) => {
  let data = _.pick(row, 'licenseMode', 'discount'),
    mod = _.omit(row, 'licenseMode', 'discount')
  mod.raw = _.cloneDeep(mod)
  form.setFieldValue('addForm', { ...data, module: mod, index })
}

const handleRemoveModule = async (arrayHelpers, row, index) => {
  const result = await confirm.show({
    title: 'Atenção',
    text: `Deseja remover o produto/módulo '${row.groupName}/${row.moduleName}' ?`,
  })

  if (result) {
    arrayHelpers.remove(index)
  }
}

const getSubTotal = (row) => {
  return _.sumBy(_.get(row, 'modules') || [], (r) => {
    return isNaN(r.value) ? 0 : r.value * 1
  })
}

const getDiscountValue = (row) => {
  return _.sumBy(_.get(row, 'modules') || [], (r) => {
    let val = isNaN(r.value) ? 0 : r.value * 1,
      discount = parseStringToDecimal(r.discount)
    return val * (discount / 100) || 0
  })
}

const getTotal = (row) => {
  let value = getSubTotal(row),
    discount = getDiscountValue(row)
  return value - discount
}

function CustomerModal({
  data,
  isOpen,
  loading,
  title,
  closeModal,
  onListModules,
  handleOnSubmit,
  onSearchIdentify,
  disabled,
}) {
  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={CustomerSchema}
      initialValues={data}
      initialTouched={{ identify: true }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleReset,
        setFieldValue,
        setFieldTouched,
        ...rest
      }) => {
        return (
          <Modal
            width="84%"
            height="96%"
            hideClose={true}
            open={isOpen}
            hide={closeModal}
            title={title}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset()
                  closeModal()
                },
                disabled: loading,
              },
              {
                label: 'Salvar',
                action: () => handleOnSubmit(values, { ...rest }),
                primary: true,
                disabled:
                  loading ||
                  !values.identify ||
                  !_.isEmpty(
                    values.modules &&
                      _.find(values.modules, (f) => !f.licenseMode),
                  ),
              },
            ]}
          >
            <ModalContainer>
              <section>
                <FlexRow>
                  <div style={{ flex: '0 0 150px' }}>
                    <Select
                      name="identifyType"
                      label="Tipo"
                      hasError={
                        _.get(errors, 'identifyType') &&
                        _.get(touched, 'identifyType')
                      }
                      disabled={disabled || values.id}
                      onChange={(evt) => {
                        setFieldValue('identifyType', evt.target.value)
                        setFieldValue('identify', '')
                      }}
                      options={{
                        values: [
                          { value: '', label: '' },
                          { value: 'company', label: 'CNPJ' },
                          { value: 'person', label: 'CPF' },
                        ],
                      }}
                    />
                  </div>

                  <div style={{ flex: '0 0 200px' }}>
                    {_.get(values, 'identifyType') !== 'person' && (
                      <InputMask
                        disabled={
                          disabled ||
                          values.id ||
                          _.get(values, 'identifyType') !== 'company'
                        }
                        mask={MASK_CNPJ}
                        name="identify"
                        label={'CNPJ'}
                        hasError={
                          _.get(errors, 'identify') &&
                          _.get(touched, 'identify')
                        }
                        onBlur={(e) => {
                          const value = onlyNumbers(e.target.value) || ''
                          setFieldTouched('identify', true)
                          if (
                            _.size(value) === 14 &&
                            !values.name &&
                            !values.tradeName
                          ) {
                            onSearchIdentify(value, true)
                          }
                        }}
                      />
                    )}

                    {_.get(values, 'identifyType') === 'person' && (
                      <InputMask
                        disabled={disabled || values.id}
                        mask={MASK_CPF}
                        name="identify"
                        label={'CPF'}
                        hasError={
                          _.get(errors, 'identify') &&
                          _.get(touched, 'identify')
                        }
                        onBlur={(e) => {
                          const value = onlyNumbers(e.target.value) || ''
                          setFieldTouched('identify', true)
                          if (
                            _.size(value) === 11 &&
                            !values.name &&
                            !values.tradeName
                          ) {
                            onSearchIdentify(value, true)
                          }
                        }}
                      />
                    )}
                  </div>

                  <InputGroup
                    type="text"
                    name="name"
                    label={
                      _.get(values, 'identifyType') === 'person'
                        ? 'Nome (*)'
                        : 'Razão social (*)'
                    }
                    disabled={
                      disabled || values.id || !_.get(values, 'identifyType')
                    }
                    hasError={errors.name && touched.name}
                  />
                </FlexRow>
              </section>

              <Fieldset label="Produtos">
                <FieldArray
                  id="client_modules"
                  name="modules"
                  render={({ form, ...arrayHelpers }) => (
                    <ProductsContainer>
                      <FlexRow span={6}>
                        <Autocomplete
                          name="addForm.module"
                          keyField="id"
                          label="Produto/Módulo"
                          value={_.get(values, 'addForm.module')}
                          valueFormat={(row) =>
                            `${row.groupName} - ${row.moduleName}`
                          }
                          loadData={onListModules}
                          emptyText={'Pesquise um produto ou módulo'}
                          disabled={!values.identify}
                          tipText={'Digite... '}
                          loadingText={'Carregando...'}
                          notFoundText={'Não encontrado'}
                        />

                        <div style={{ flex: '0 0 425px' }}>
                          <Select
                            name="addForm.licenseMode"
                            label="Tipo de licença"
                            hasError={
                              _.get(errors, 'addForm.licenseMode') &&
                              _.get(touched, 'addForm.licenseMode')
                            }
                            disabled={!values.identify}
                            options={{
                              values: [
                                {
                                  value: '',
                                  label: 'Selecione o tipo de licença',
                                },
                                ..._.map(licenseModesMap, (label, value) => ({
                                  value,
                                  label,
                                })),
                              ],
                            }}
                          />
                        </div>

                        <div style={{ flex: '0 0 120px' }}>
                          <InputLabel
                            label="Valor"
                            value={formats.currency(
                              _.get(values, 'addForm.module.raw.value') || 0,
                            )}
                          />
                        </div>

                        <div style={{ flex: '0 0 120px' }}>
                          <CurrencyField
                            type="discount"
                            name="addForm.discount"
                            label="Desconto (%)"
                            value={_.get(values, 'addForm.discount') || 0}
                            disabled={!values.identify}
                          />
                        </div>

                        <div style={{ flex: '0 0 46px' }}>
                          <IconButton
                            type="button"
                            title="Adicionar"
                            disabled={
                              !values.identify || !isValidModule(values.addForm)
                            }
                            onClick={() =>
                              createOrUpdateModules(
                                arrayHelpers,
                                form,
                                values,
                                values.addForm,
                              )
                            }
                          >
                            {isNaN(_.get(values, 'addForm.index')) ? (
                              <MdAdd />
                            ) : (
                              <MdSave />
                            )}
                          </IconButton>
                        </div>
                      </FlexRow>

                      <div className="modules-table">
                        <DataTable
                          emptyText="Nenhum produto/módulo vinculado"
                          noPagination={true}
                          data={{ rows: values.modules || [] }}
                          columns={columns(arrayHelpers, form)}
                          extraOptions={{
                            ignoreRowClicked: true,
                            selectableRows: false,
                            selectableRowsHighlight: false,
                          }}
                        />
                      </div>
                    </ProductsContainer>
                  )}
                />
              </Fieldset>

              <Discount>
                <span>Subtotal: {formats.currency(getSubTotal(values))}</span>
                <span>
                  Desconto: {formats.currency(getDiscountValue(values))}
                </span>
                <strong>Total: {formats.currency(getTotal(values))}</strong>
              </Discount>
            </ModalContainer>
          </Modal>
        )
      }}
    </Formik>
  )
}

export default CustomerModal
