import React from 'react';
import { ErrorMessage, Field } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { accent, gray, primary, symbol, tertiary, white } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  & > svg,
  & > .addon {
    position: absolute;
    right: 15px;
    align-self: center;
    color: ${symbol.hex()};
    transition: color 0.2s ease 0s;
  }

  &:focus-within > input {
    border-color: ${accent.hex()};
  }

  &:focus-within > svg,
  &:focus-within > .addon {
    color: ${accent.hex()};
  }

  input[type='textarea'] {
    height: 100px;
    resize: none;
    padding: 0px 10px;
    line-height: 100px;

    &::placeholder {
      transform: translateY(-37px);
      font-size: 70%;
    }
  }

  input {
    background: ${primary.hex()};
    border: 2px solid ${tertiary.hex()};
    border-radius: 3px;
    padding: ${(props) => props.hasIcon ? '19px 40px 6px 10px' : '19px 5px 6px 10px'};
    color: ${white.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;
    height: 46px;
    min-width: 50px;

    &:disabled {
      background-color: ${primary.lighten(0.8).hex()};
      color: ${gray.hex()};
    }

    &:focus + label,
    &:not([value='']) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
    }
    &:not([value='']) + label {
      color: ${gray.hex()};
    }
    &:focus + label {
      color: ${white.hex()};
    }
    &:focus::placeholder {
      color: ${white.hex()};
    }
    & + label {
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.7;
    }
  }
`;

const hasVal = (val) => {
  return !_.isUndefined(val) && !_.isNull(val) && val !== '';
};

const InputGroup = ({ type, name, noMargin, label, hasError, icon: Icon, ...rest }) => {
  const style = rest.style || {};

  return (<Container>
    <Field id={name} name={name}>
      {({ field }) => (
        <Group noMargin={noMargin} hasIcon={!!Icon}>
          <input
            type={type}
            className={!!hasError ? 'error' : ''}
            {...field}
            {...rest}
            placeholder={label}
            value={hasVal(field.value) ? field.value : ''}
            style={hasVal(field.value) ? { ...style } : { padding: '15px 8px', ...style }}
          />
          {hasVal(field.value) && <label htmlFor={name}>{label}</label>}
          {Icon && <Icon size={20} />}
        </Group>
      )}
    </Field>
    <ErrorMessage name={name} className='error' component='div' />
  </Container>)
};

InputGroup.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  icon: PropTypes.func,
};

InputGroup.defaultProps = {
  hasError: false,
};

export default InputGroup;
