import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/button';
import { InputGroup } from '~/components/form';
import Logo from '~components/logo';
import { Form, Formik } from 'formik';
import useAuth from '~/view-data/use-auth';
import actionImg from '~/assets/action.svg';
import { RiMailLine, RiLockPasswordLine } from 'react-icons/ri';
import { primary, accent, white, link } from '~/components/mixins/color';

const gradient = `linear-gradient(135deg, ${accent.hex()}, ${accent.hex()}, ${accent.fade(0.35).string()})`;

const Container = styled.div`
  display: flex;
  flex: 1;
  background: ${primary.hex()};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40%;
  height: 100vh;
  padding: 80px;
  background: ${gradient};

  ${Logo} {
    left: 50px;
  }
  @media (max-width: 1280px) {
    padding: 50px;
  }
`;

const ImgAction = styled.img`
  width: 25%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 7.5%;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0 100px 0;
  color: ${white.hex()};

  h2 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
    margin-top: 10px;
    font-weight: normal;
  }
`;

const Right = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .forget {
    position: absolute;
    top: 20px;
    right: 20px;
    font-weight: 500;
    font-size: 14px;
    color: ${link.hex()};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding: 10px;
    font-size: 16px;
    color: ${link.hex()};
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  button {
    width: 200px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    color: ${link.fade(0.2).string()};
    padding: 30px;
  }
`;

const renderLogin = ({ errors, touched, values }) => {
  return (
    <>
      <InputGroup
        icon={() => <RiMailLine />}
        type="username"
        name="username"
        maxLength={100}
        label="Usuário (login)"
        autoFocus
        autoComplete="new-password"
        value={values.username}
        hasError={errors.username && touched.username}
      />

      <InputGroup
        icon={() => <RiLockPasswordLine />}
        type="password"
        name="password"
        maxLength={50}
        label="Senha"
        autoComplete="new-password"
        value={values.password}
        hasError={errors.password && touched.password}
      />
    </>
  )
}

const renderForgetPassword = ({ errors, touched, values }) => {
  return (
    <>
      <InputGroup
        icon={() => <RiMailLine />}
        type="username"
        name="username"
        maxLength={100}
        label="Usuário (login)"
        autoFocus
        autoComplete="new-password"
        value={values.username}
        hasError={errors.username && touched.username}
      />
    </>
  )
};

const Login = () => {
  const { actions } = useAuth();
  const [isLoginMode, changeLoginMode] = useState(true);

  const handleLoginSubmit = (data, action) => {
    actions.loginSubmit(data, action);
  };

  const redefinePassword = (data, action) => {
    actions.forgetPasswordSubmit(data, { ...action, toogleForm: () => changeLoginMode(true) });
  };

  return (
    <Container>
      <Left>
        <Logo inverse={true} height="50px" />

        <Block>
          <div>
            <h2>A plataforma para gestão de contratos do Grupo Skill.</h2>
          </div>
        </Block>

        <ImgAction src={actionImg} alt="action" />
      </Left>

      <Right>
        <span className="forget" onClick={() => changeLoginMode(!isLoginMode)}>
          {isLoginMode ? 'Esqueci minha senha' : 'Acessar sistema'}
        </span>

        <div />

        <FormContainer>
          <h3>{isLoginMode ? 'Dados de acesso' : 'Solicitar nova senha'}</h3>
          <Formik
            initialValues={isLoginMode ? { username: '', password: '' } : { username: '' }}
            enableReinitialize={true}
            validateOnMount={false}
            onSubmit={isLoginMode ? handleLoginSubmit : redefinePassword}
            initialTouched={{ username: true }}
            >
            {({ isValid, isSubmitting, handleReset, values, handleSubmit, ...formArgs }) => (
              <Form>
                {isLoginMode && renderLogin({ ...formArgs, values, changeLoginMode })}
                {!isLoginMode && renderForgetPassword({ ...formArgs, values, changeLoginMode })}
                <Button unselected={true} type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Aguarde...' : (isLoginMode ? 'Entrar' : 'Enviar')}
                </Button>
              </Form>
            )}
          </Formik>
        </FormContainer>

        <p>Plataforma simplificada de gestão de contratos.</p>
      </Right>
    </Container>
  )
};

export default Login;
