import React from 'react';
import _ from 'lodash';
import { MdSave, MdAdd, MdPrint } from 'react-icons/md';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import { PartnerForm, PartnerSchema } from '~/views/partners/form';
import { Container } from '~/views/partners/styles';
import CellStatus from '~/components/datatable/cell-status';
import usePartners from '~/view-data/use-partners';
import confirm from '~/components/confirm';
import { SubtitleItem, SubtitleBox } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';

const columns = [
  {
    name: '# CNPJ',
    selector: 'identity',
    width: '180px',
    cell: row => {
      let text = formats.cnpj(row.identity);
      return (
        <CellStatus title={text} color={row.active ? green.hex() : red.hex()}>
          <strong>{text}</strong>
        </CellStatus>
      );
    }
  },
  {
    name: 'Razão Social',
    selector: 'name',
  },
  {
    name: 'Nome de Fantasia',
    selector: 'tradeName',
    hide: 'md'
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: row =>
      formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  }
];

function Partners({ acls }) {
  const { state, actions } = usePartners();
  const canWrite = acls.includes('W');
  const actionsList = [
    {
      label: 'Salvar',
      icon: MdSave,
      isSubmit: true,
      isDisabled: ({ isValid }) => !isValid || !canWrite,
      action: actions.createOrUpdate
    }
  ];

  if (_.get(state, 'model.id')) {
    actionsList.push({
      label: 'Baixar PDF do contrato',
      icon: MdPrint,
      action: actions.downloadContractPdf
    });

    if (state.formTab === 'contracts') {
      actionsList.push({
        label: 'Novo contrato de aditamento',
        icon: MdAdd,
        action: () => actions.newContract(_.get(state, 'model.id'))
      });
    }
  }

  const handleRemoveContract = async (data) => {
    const result = await confirm.show({
      width: 450,
      title: 'Atenção',
      text: `Deseja realmente remover esse contrato de aditamento? <br/>
        Licenças e históricos vinculados serão perdidos e essa ação não pode ser revertida.`
    });

    if (result) {
      let params = {
          id: data.id,
          name: data.groupName,
          partnerId: _.get(state, 'model.id')
        };
      actions.removeContract(params);
    }
  };

  return (
    <Container>
      <SubtitleBox bottom={35} left={100}>
        <span>Legenda:</span>
        <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
        <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
      </SubtitleBox>

      <Crud
        columns={columns}
        formTitle={data =>
          _.get(data, 'id') ? `Parceiro #${data.id}` : `Novo parceiro`
        }
        emptyText="Nenhum parceiro encontrado"
        data={state.data}
        hideAdd={!canWrite}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={actions.pageChange}
        onRowClicked={actions.load}
        rightWidth="600px"
        formOptions={{
          validationSchema: PartnerSchema,
          initialValues: state.model
        }}
        renderForm={args => (
          <PartnerForm
            {...args}
            previewMode={!canWrite}
            contractModels={state.contractModels}
            formTab={state.formTab}
            onSearchCnpj={actions.searchCnpj}
            changeFormtab={actions.changeFormtab}
            goToContract={actions.goToContract}
            handleRemoveContract={handleRemoveContract}
          />
        )}
        actions={actionsList}
      />
    </Container>
  );
}

export default Partners;
