import { useHistory } from 'react-router-dom';
import { getAvatar } from '~/constants';
import { showMessage } from '~/helper';
import _ from 'lodash';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';

const initialState = {
  isLoginMode: true,
};

function useAuth() {
  const history = useHistory(),
    { state: { auth = initialState }, dispatch } = useGlobalContext('auth');

  const failure = (error) => {
    dispatch({ signed: false, isLoginMode: true });
    showMessage('error', error);
  };

  const changeLoginMode = (isLoginMode) => {
    dispatch({ isLoginMode });
  };

  const loginSubmit = async (params, action) => {
    try {
      const { data } = await api.post('auth/login', params);
      loginSuccess(data);
    } catch (error) {
      action && action.setSubmitting(false);
      failure(error);
    }
  };

  const forgetPasswordSubmit = async (data, action) => {
    try {
      await api.post('auth/forget-password', data);
      showMessage('success', 'Senha redefinida com sucesso, verifique a caixa de entrada do seu email!');
      action.setSubmitting(false);
      changeLoginMode(true);
    } catch (error) {
      action && action.setSubmitting(false);
      failure(error);
    }
  };

  const getGravatarImage = async ({ email }) => {
    let response = await api.get('users/avatar', { params: { email } });
    return _.get(response, 'data.base64');
  };

  const loginSuccess = async ({ token, me, menu }) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    let avatarBase64 = await getGravatarImage(me);
    me.avatar = getAvatar({ ...me, avatarBase64 });
    dispatch({ signed: true, user: me, token: token, menu: menu }, true);
    history.push('/home');
  };

  const logout = () => {
    dispatch({ signed: false, token: null, menu: null, user: null, isLoginMode: true }, true);
    history.push('/');
    // window.location.reload(true);
  };

  const updateAvatar = (user) => {
    const { signed, token, menu, sidebarData } = auth;
    dispatch({ signed, token, menu, sidebarData, user }, true);
  };

  return {
    state: {
      ...auth,
    },
    actions: {
      changeLoginMode,
      logout,
      updateAvatar,
      loginSubmit,
      forgetPasswordSubmit,
    },
  };
}

export default useAuth;
