import React, { memo, useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { SidebarLabel, MenuContent, SidebarText } from '~/components/menu/sub-menu';
import { MdExitToApp } from 'react-icons/md';
import logo from '~/assets/icon.png';
import logoFull from '~/assets/logo.png';
import MenuProfile from '~/components/header/menu-profile';
import { Container, Aside, Profile } from '~/components/menu/styles';
import ReactTooltip from 'react-tooltip';
import SideBar from '~/components/menu/sidebar';
import { IconContext } from 'react-icons/lib';
import useClickAwayListener from '~/hooks/use-click-away-listener';
import { getSideBarData } from '~/helper';
import useAuth from '~/view-data/use-auth';

function Menu() {
  const { state, actions } = useAuth();
  const wrapperRef = useRef(null);
  const user = _.get(state, 'user', {});
  const sidebarData = getSideBarData(_.get(state, 'menu', {}), user);
  const name = _.get(user, 'name', '');

  useClickAwayListener({
    ref: wrapperRef,
    callback: () => showSidebar(false)
  });

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  function handleSignOut() {
    actions.logout(false);
  }

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = show => setSidebar(show);

  return (
    <Container ref={wrapperRef} sidebar={sidebar} onMouseEnter={(e) => showSidebar(true)} onMouseLeave={e => showSidebar(false)}>
      <SideBar logo={logo} logoFull={logoFull} sidebar={sidebar} data={sidebarData} />

      <Aside>
        <Profile sidebar={sidebar}>
          <MenuProfile align="start" direction="right" />
          {sidebar && <span>{name}</span>}
        </Profile>

        <IconContext.Provider value={{ color: '#888', size: 24 }}>
          <div className="menu-bottom">
            <SidebarText onClick={handleSignOut} mini={String(!sidebar)}>
              <MenuContent>
                <MdExitToApp />
                {sidebar && <SidebarLabel>Sair</SidebarLabel>}
              </MenuContent>
            </SidebarText>
          </div>
        </IconContext.Provider>
      </Aside>
    </Container>
  )
}

export default memo(Menu)
