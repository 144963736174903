import React from 'react';
import Fieldset from '~/components/fieldset';
import { Select } from '~/components/form';
import { getMonths, generateYears } from '~/helper';

export default function FormFilter(props) {
  return (
    <div>
      <Fieldset label="Período">
        <Select
          name="month"
          label="Mês"
          options={
            {
              values: getMonths().map(row => ({value: String(row.id), label: row.label}))
            }
          }
        />
        <Select
          name="year"
          label="Ano"
          options={
            {
              values: generateYears(5, 3).map(year => ({value: String(year), label: String(year)}))
            }
          }
        />
      </Fieldset>
    </div>
  );
}
