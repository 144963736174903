import styled, { css } from 'styled-components';
import { black, gray, lightGray, primary, quinary, secondary, tertiary, white } from '~/components/mixins/color';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100%);
    width: ${(props) => (props.showForm ? 'calc(75%)' : 'calc(100%)')};
    padding: 0px;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .right {
    display: ${(props) => (props.showForm ? 'flex' : 'none')};
    flex-direction: column;
    position: relative;
    flex: 0 0 25%;
    background: ${primary.hex()};
    width: 100%;
    padding: 10px 10px 0 10px;
    margin-left: 10px;
    border-radius: 3px;
    justify-content: space-between;
  }

  ${(props) =>
    props.showForm &&
    css`
      @media (max-width: 1024px) {
        .left {
          display: none;
          visibility: hidden;
        }
        .right {
          flex: 1;
          margin-left: 0px;
        }
      }
      @media (min-width: 769px) and (max-width: 1024px) {
        .right {
          flex: 0 0 45%;
        }
      }
    `}
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${primary.hex()};
  height: 100%;
  padding: 10px;
  border-radius: 4px 0px 0px 4px;
  margin-bottom: 10px;

  .title {
    font-size: 20px;
    color: ${lightGray.hex()};
    margin: 20px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const CardContainer = styled.ul`
  display: grid;
  grid-auto-columns: minmax(204px, 1fr);
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-content: flex-start;
  margin-bottom: 10px;

  width: 100%;

  @media (max-width: 1024px) {
    grid-auto-flow: row;
    grid-gap: 10px;
  }
`;

export const Card = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${primary.hex()};
  border-left: 10px solid ${(props) => props.color || white.hex()};
  height: 120px;
  border-radius: 3px 0px 0px 3px;
  padding: 10px;
  box-shadow: 1px 1px 1px ${tertiary.hex()};
  min-width: 260px;

  &:hover {
    background: ${secondary.toString()};
    box-shadow: 1px 1px 1px ${quinary.hex()};
    cursor: pointer;
  }

  .title {
    color: ${(props) => props.color || white.hex()};
    font-size: 20px;
    margin: 10px;
  }
  @media (min-width: 1025px) and (max-width: 1480px) {
    min-width: 100px;

    .title {
      font-size: 16px;
    }
  }

  .row {
    display: flex;
    justify-content: space-between;
    padding: 10px;

    .values {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      label {
        font-size: 12px;
        color: ${lightGray.hex()};
      }

      span {
        font-size: 22px;
        color: ${gray.hex()};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 60%;
        display: inline;
        font-size-adjust: 0.58;
      }

      &:first-child {
        margin-right: 5px;
      }

      @media (min-width: 1025px) and (max-width: 1480px) {
        label {
          font-size: 10px;
        }
        span {
          font-size: 16px;
        }
      }
    }
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  div.row {
    display: grid;
    grid-template-columns: 40px 1fr;

    @media (max-width: 960px) {
      grid-row-gap: 10px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${primary.alpha(0.8).toString()};
      border-radius: 8px 0px 0px 8px;
      color: ${white.hex()};
      font-size: 14px;
      font-weight: bold;
      margin: 0px 0px 2px 0px;
      text-align: center;
      padding: 10px;
    }

    .columns {
      display: grid;
      grid-template-columns: repeat(auto-fill, min(260px, 1fr));
      grid-auto-columns: minmax(260px, 1fr);
      grid-auto-flow: column;
      grid-gap: 0px;
      align-items: center;
      background: ${primary.alpha(0.05).toString()};
      margin-bottom: 2px;

      @media (max-width: 960px) {
        grid-auto-flow: row;
        grid-auto-columns: minmax(180px, 1fr);
      }

      .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0px;
        padding: 10px;

        &:hover {
          background: ${black.alpha(0.02).toString()};
          cursor: pointer;
          border-radius: 2px;
        }

        label {
          margin: 0px 10px 5px 10px;
          font-size: 14px;
          color: #555;
        }

        .value {
          display: flex;
          justify-content: center;
          font-size: 16px;
          margin-left: 10px;
          color: #555;
        }
      }
    }
  }
`;

export const FormToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid #e0e0e0;

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
`;
