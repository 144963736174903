import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { secondary, gray } from '~/components/mixins/color';

const Container = styled.div`
  border: 1px solid ${secondary.hex()};
  color: ${gray.hex()};
  position: absolute;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 20px;
  z-index: 2;
  padding: 5px;
  top: ${(props) => `${props.top}px` || 'unset'};
  right: ${(props) => `${props.right}px` || 'unset'};
  bottom: ${(props) => `${props.bottom}px` || 'unset'};
  left: ${(props) => `${props.left}px` || 'unset'};
  font-size: 10px;
  span {
    margin: 0 !important;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  pointer-events: none;

  .cell-contents {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 15px);
  }
`;

const Square = styled.div`
  background: ${props => props.color};
  width: 10px;
  height: 10px;
  margin-right: 5px;
`;

function SubtitleBox({ top, right, bottom, left, children }) {
  return (
    <Container top={top} right={right} bottom={bottom} left={left}>
      {children}
    </Container>
  );
}

SubtitleBox.propTypes = {
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

function SubtitleItem({ color, children }) {
  return (
    <ItemContainer>
      <Square color={color} />
      <div className="cell-contents">
        {children}
      </div>
    </ItemContainer>
  );
}

SubtitleItem.propTypes = {
  color: PropTypes.string,
}

export { SubtitleBox, SubtitleItem };
