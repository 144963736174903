import { useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  term: null,
  offset: 0,
  data: { rows: [], count: 0, offset: 0 },
};

const useContractModels = () => {
  const { state: { contractModels = initialState }, dispatch } = useGlobalContext('contractModels'),
    { state: headerState, actions: headerActions } = useHeader(),
    history = useHistory();

   useEffect(() => {
    headerActions.hideFilter();
    headerActions.callFilter({});
    dispatch(initialState);
    // eslint-disable-next-line
  }, []);

  const { data } = useFetch({
    url: 'contract-models/list',
    method: 'POST',
    params: {
      ..._.get(headerState, 'filter.data'),
      offset: contractModels.offset || 0
    },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Modelos de Contrato',
      count: _.get(data, 'count', 0),
      loading: _.get(contractModels, 'loading', false),
      useFilter: true
    });
    // eslint-disable-next-line
  }, [data, contractModels]);

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = (data = {}) => {
    history.push(`/contract-models/${data.id || 'new'}`);
  };

  const changeFilter = (data) => {
    dispatch({ offset: 0, model: {}, showForm: false });
    headerActions.callFilter(data);
  };

  return {
    state: {
      ...contractModels,
      data: data || {},
      loading: !data,
    },
    actions: {
      load,
      pageChange,
      changeFilter,
    },
  };
};

export default useContractModels;
