import React, { memo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Menu, MenuItem, MenuDivider, MenuButton, MenuHeader } from '@szhsin/react-menu';
import ImageAuth from '~/components/image';
import { getAvatarDefault } from '~/constants';
import UserModals from '~/views/users/modals';
import useAuth from '~/view-data/use-auth';
import useLogged from '~/view-data/use-logged';

const ProfileMenuButton = styled(MenuButton)`
  border: none;
  background: transparent;
`;

const ProfileMenuHeader = styled(MenuHeader)`
  text-align: left;
`;

const areEquals = (prevProps, nextProps) => {
  const key = 'children.props.avatar';
  return _.get(prevProps, key) === _.get(nextProps, key);
};

const MenuProfile = ({ align, direction }) => {
  const { state: authState, actions: authActions } = useAuth();
  const { state: loggedState, actions: loggedActions } = useLogged();
  const user = _.get(authState, 'user');
  const avatar = _.get(user, 'avatar', '');

  function handleUpdatePassword(data, actions) {
    actions.closeModal = () => loggedActions.passwordFrm(false);
    loggedActions.updatePassword(data, actions);
  };

  function handleUpdateAvatar(data, actions) {
    const { avatar } = data;
    actions.closeModal = () => loggedActions.avatarFrm(false);
    loggedActions.updateAvatar(avatar, actions);
  };

  function handleSignOut() {
    authActions.logout(false);
  };

  return (
    <>
      <Menu
        align={align}
        direction={direction}
        menuButton={
          <ProfileMenuButton>
            <ImageAuth src={avatar} defaultImage={getAvatarDefault()} />
          </ProfileMenuButton>
        }
        >
        <ProfileMenuHeader>
          Perfil do usuário
        </ProfileMenuHeader>
        <MenuItem onClick={() => loggedActions.avatarFrm(true)}>Alterar Foto</MenuItem>
        <MenuItem onClick={() => loggedActions.passwordFrm(true)}>Alterar senha</MenuItem>
        <MenuDivider />
        <MenuItem onClick={handleSignOut}>Sair</MenuItem>
      </Menu>

      <UserModals
        profileData={user}
        passwordFrmIsOpen={loggedState.pwdFrmIsOpen}
        closeModalPassword={() => loggedActions.passwordFrm(false)}
        handleUpdatePassword={handleUpdatePassword}
        avatarData={{ avatar }}
        avatarFrmIsOpen={loggedState.avatarFrmIsOpen}
        closeModalAvatar={() => loggedActions.avatarFrm(false)}
        handleUpdateAvatar={handleUpdateAvatar}
        />

    </>
  );
};

export default memo(MenuProfile, areEquals);
