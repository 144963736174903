import styled from 'styled-components';
import { Form } from 'formik';
import { lightGray, accent, white, primary, secondary, tertiary, quaternary } from '~/components/mixins/color';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: ${props => props.maxHeight};
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  background: ${tertiary.hex()};
  color: ${lightGray.hex()};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${props => (props.visible ? 'grid' : 'none')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: 0.1s;
  z-index: 3;
`;

export const FormToolbar = styled.div`
  display: flex;
  align-items: flex-end;
  min-height: 46px;
  height: 46px;
  border-top: 1px solid ${secondary.hex()};

  .discount {
    flex: 0 0 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 36px;
    background: ${secondary.hex()};
    width: 100%;

    & > span {
      color: ${lightGray.hex()};
      margin: 0;
    }
    & > strong {
      font-size: 14px;
      color: ${lightGray.hex()};
    }
  }
  .buttons {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    button {
      margin-bottom: 0;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 459px);
  flex: 1;

  .list {
    display: flex;
    flex: 1;
  }
`;

export const ViewMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 25px;
  button {
    border-radius: 0px;
    margin: 0;
    margin-left: -1px;
    padding: 0 10px;
    height: 20px;
    font-size: 12px;
    min-width: 50px;
  }
  span {
    margin: 0px;
    padding-right: 10px;
  }
`;

export const Timeline = styled.ul`
  border-left: 4px solid ${accent.hex()};
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: ${white.hex()};
  margin: 10px 0 0 200px;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 30px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: calc(100% - 200px);
  
  h1 {
   letter-spacing: 1.5px;
   font-weight: 100;
   font-size: 18px;
  }

  h2, h3 {
   letter-spacing: 1.5px;
   font-weight: 400;
   font-size: 18px;
  }
  p {
    color: ${lightGray.hex()};
   font-weight: 100;
   font-size: 14px;
  }


  li {
    border-bottom: 1px dashed ${secondary.hex()};
    padding-bottom: 25px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    justify-content: space-between;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before, &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -185px;
      color: ${lightGray.hex()};
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: 14px;
      min-width: 120px;
    }

    &:after {
      box-shadow: 0 0 0 4px ${accent.hex()};
      left: -37px;
      background: ${tertiary.hex()};
      border-radius: 50%;
      height: 11px;
      width: 11px;
      content: "";
      top: 5px;
    }
    .actions {
      width: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

export const LicenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: calc(100vh - 221px);

  .search {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    input {
      width: 20%;
    }
  }
  .list {
    border: thin solid ${secondary.hex()};
    height: 100%;
  }
`;

export const RowExpander = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  min-height: 100px;
  border-top: thin solid ${primary.hex()};
  border-bottom: thin solid ${primary.hex()};
  background: ${primary.hex()};
`;

export const LicenseInfo = styled.p`
  strong {
    margin-left: 10px;
    font-size: 101%;
  }
  strong:first-child {
    margin-left: 0;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  color: ${white.hex()};
  justify-content: space-between;
  background-color: ${quaternary.hex()};

  .modules-table {
    height: calc(100vh - 375px);
    background: red;
    width: 100%;
  }
`;

export const Discount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background: ${secondary.hex()};
  width: 100%;
  flex: 0 0 40px;

  & > span {
    color: ${lightGray.hex()};
    margin: 0;
  }
  & > strong {
    font-size: 14px;
    color: ${lightGray.hex()};
  }
`;
