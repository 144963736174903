import React from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { InputMask, InputGroup } from '~/components/form';
import { MASK_PHONE } from '~/constants';
import { formats } from '~/helper';
import { Row, FlexRow } from '~/components/layout';
import { Formik } from 'formik';
import Fieldset from '~/components/fieldset';
import { Item, TabContainer } from '~/views/partners/styles';
import { IconButton } from '~/components/button';
import _ from 'lodash';

const TabContacts = (props) => {

  function handleAddContact(contact, handleReset) {
    props.handleAddContact(contact);
    handleReset();
  };

  function isAddDisabled(contact) {
    let disabled = false;

    Object.keys(contact).forEach(key => {
      if (_.isEmpty(_.trim(contact[key]))) {
        disabled = true;
        return false;
      }
    });
    return disabled;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ name: '', email: '', phone: '' }}
      >
      {({ errors, isValid, touched, values, handleReset }) => (
      <TabContainer>
        <Fieldset label="Novo contato">
          <Row span={2}>
            <InputGroup
              type='email'
              name='email'
              label='Email'
              maxLength={100}
              />
            <InputMask
              name='phone'
              label='Telefone'
              value={values.phone || ''}
              mask={MASK_PHONE}
              />
          </Row>
          <FlexRow direction="row">
            <InputGroup
              type='text'
              name='name'
              label='Nome'
              maxLength={100}
              />
            <IconButton type='button' title='Adicionar' onClick={() => handleAddContact(values, handleReset)} disabled={isAddDisabled(values)}>
              <MdAdd />
            </IconButton>
          </FlexRow>
        </Fieldset>

        <Fieldset label="Contatos">
          {(_.isEmpty(_.get(props, 'values.contacts'))) &&
            <span className="empty">Nenhum contato.</span>
          }
          {_.map(_.get(props, 'values.contacts') || [], (value, index) => (
            <Item key={index}>
              <div className='column-data'>
                <div className='first'>
                  <strong>{value.name}</strong>
                  <div>{value.email}</div>
                </div>
                {formats.phone(value.phone)}
              </div>
              <IconButton size={36} margin="0">
                <MdDelete onClick={() => props.handleRemoveContact(index)} />
              </IconButton>
            </Item>
          ))}
        </Fieldset>
      </TabContainer>
      )}
    </Formik>
  );
};

export default TabContacts;
