import React from 'react';
import _ from 'lodash';
import { MdAdd, MdDelete } from 'react-icons/md';
import { InputGroup, FileUpload, TextArea, Select } from '~/components/form';
import { TabContainer, UploadContainer } from '~/views/modules/styles';
import { Row, FlexRow, FlexItem } from '~/components/layout';
import Fieldset from '~/components/fieldset';
import confirm from '~/components/confirm';
import { IconButton } from '~/components/button';
import { licenseTypes } from '~/constants';

const ModuleUpload = ({ name, show, previewMode, values, setFieldValue, erpVersions, allErpVersions }) => {
  let files = _.get(values, name),
    versions = _.filter(erpVersions, (r) => !_.find(files, { version: `${r.id}` }));

  const handleAdd = () => {
    let val = _.get(values, 'newModule'),
      baseFiles = _.cloneDeep(files || []);

    baseFiles.push({
      version: _.get(val, 'version'),
      file: _.get(val, 'file[0]')
    })
    setFieldValue('newModule', {});
    setFieldValue(name, baseFiles);
  };

  const handleRemoveFile = async (index) => {
    const result = await confirm.show({
      width: 450,
      title: 'Atenção',
      text: `Deseja realmente remover este arquivo ?`
    });

    if (result) {
      let baseFiles = _.cloneDeep(files || []);
      baseFiles.splice(index, 1);
      setFieldValue(name, baseFiles);
    }
  };

  if (!show) {
    return null;
  }
  return (
    <UploadContainer>
      {(!previewMode && !_.isEmpty(versions)) &&
        <Fieldset label="Incluir arquivo">
          <FlexRow align="center">
            <FlexItem flex="0 0 150px">
              <Select
                name="newModule.version"
                label="Versão ERP"
                noMargin={true}
                options={{
                  values: [
                    { id: '', value: '' },
                    ..._.map(versions, ({ id, name }) => ({ value: `${id}`, label: name }))
                  ]
                }}
               />
            </FlexItem>

            <FlexItem flex="1">
              <FileUpload
                name="newModule.file"
                accept=".zip"
                label="Arquivo"
                height="44px"
                noMargin={true}
                tipText={_.get(values, 'newModule.file') ? 'Escolher outro arquivo' : 'Selecione um arquivo'}
                multiple={false}
                orientation="horizontal"
                />
            </FlexItem>

            <FlexItem flex="0 0 48px">
              <IconButton
                title='Adicionar'
                type="button"
                size={44}
                margin="0"
                disabled={!_.get(values, 'newModule.version') || !_.get(values, 'newModule.file')}
                onClick={() => handleAdd()}>
                <MdAdd />
              </IconButton>
            </FlexItem>
          </FlexRow>
        </Fieldset>
      }

      <Fieldset label="Arquivos" className="files">
        {(_.isEmpty(files)) &&
          <span> Selecione ao menos um arquivo.</span>
        }
        {_.map(files, (file, index) => (
          <FlexRow key={index} align="center" className="file">
            <FlexItem flex="0 0 100px">
              {_.get(allErpVersions, `[${file.version}].name`) || `Versão id: ${file.version}`}
            </FlexItem>

            <FlexItem flex="1">
              {_.get(file, 'filename') || _.get(file, 'file.name')}
            </FlexItem>

            <FlexItem flex="0 0 40px">
              <IconButton type="button" size={36} margin="0">
                <MdDelete onClick={() => handleRemoveFile(index)} />
              </IconButton>
            </FlexItem>
          </FlexRow>
        ))}
      </Fieldset>
    </UploadContainer>
  );
};

const TabGeneral = ({ values, previewMode, errors, touched, setFieldValue, erpVersions, allErpVersions }) => {
  return (
    <TabContainer>
      <InputGroup
        disabled={previewMode}
        type='text'
        name='name'
        label='Nome'
        hasError={errors.name && touched.name}
        />

      <Row span={3}>
        <InputGroup
          disabled={previewMode}
          type='text'
          name='code'
          label='Código'
          hasError={errors.code && touched.code}
          />

        <InputGroup
          disabled={previewMode}
          type='text'
          name='version'
          label='Versão'
          hasError={errors.version && touched.version}
          />

        <Select
          name='licenseType'
          label='Tipo de licença'
          disabled={previewMode}
          options={{
            values: [
              { value: '', label: '' },
              ...licenseTypes
            ],
          }}
          />
      </Row>

      {values.licenseType === 'dll' &&
        <TextArea
          disabled={previewMode}
          name='releaseNotes'
          label='Notas da versão'
          rows={3}
          hasError={errors.releaseNotes && touched.releaseNotes}
          />
      }

      <ModuleUpload
        show={values.licenseType === 'dll'}
        name="moduleFiles"
        values={values}
        erpVersions={erpVersions}
        allErpVersions={allErpVersions}
        previewMode={previewMode}
        setFieldValue={setFieldValue}
        />
    </TabContainer>
  );
};

export default TabGeneral;
