import React from 'react';
import { formats } from '~/helper';
import { Card, CardContainer } from '~/views/dashboard/styles';

function Cards() {
  return (
    <CardContainer>
      <Card color='#00bb00d9' onClick={() => {}}>
        <span className='title'>Novos contratos</span>
        <div className='row'>
          <div className='values'>
            <span>{formats.number(2)}</span>
            <label>Quantidade</label>
          </div>
          <div className='values'>
            <span>{formats.currency('1250.00')}</span>
            <label>Valor</label>
          </div>
        </div>
      </Card>
      <Card color='#ff0202d9' onClick={() => {}}>
        <span className='title'>Recisões</span>
        <div className='row'>
          <div className='values'>
            <span>{formats.number(1)}</span>
            <label>Quantidade</label>
          </div>
          <div className='values'>
            <span>{formats.currency('350.00')}</span>
            <label>Valor</label>
          </div>
        </div>
      </Card>
      <Card color='#fd9006d9' onClick={() => {}}>
        <span className='title'>Parceiros</span>
        <div className='row'>
          <div className='values'>
            <span>{formats.number(3)}</span>
            <label>Novos</label>
          </div>
          <div className='values'>
            <span>{formats.number('17.00')}</span>
            <label>Total</label>
          </div>
        </div>
      </Card>
      <Card color='#d00db7' onClick={() => {}}>
        <span className='title'>Clientes</span>
        <div className='row'>
          <div className='values'>
            <span>{formats.number(15)}</span>
            <label>Novos</label>
          </div>
          <div className='values'>
            <span>{formats.number('2307.00')}</span>
            <label>Total</label>
          </div>
        </div>
      </Card>
    </CardContainer>
  );
}

export default Cards;
