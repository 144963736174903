import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import useAuth from '~/view-data/use-auth';
import MenuProfile from '~/components/header/menu-profile';
import { Container, Content, Profile, Toolbar } from '~/components/header/styles';

function Header({ children }) {
  const { state: authState } = useAuth(),
    user = _.get(authState, 'user'),
    name = _.get(user, 'name'),
    accountName = _.get(user, 'profile.name', '') || _.get(user, 'account.accountType', '');

  return (
    <Container>
      <Content>
        <Toolbar>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {});
          })}
        </Toolbar>

        <Profile>
          <div className="profile-info">
            <strong title={name}>{name}</strong>
            <span>{accountName}</span>
          </div>
          <div className="profile-avatar">
            <MenuProfile align="end" direction="bottom" />
          </div>
        </Profile>
      </Content>
    </Container>
  );
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default Header;
