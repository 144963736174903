import React from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import { Formik } from 'formik';
import { InputGroup } from '~/components/form';
import { FlexRow } from '~/components/layout';
import { Item, TabContainer } from '~/views/products/styles';
import { IconButton } from '~/components/button';
import _ from 'lodash';

const Features = (props) => {
  function addFeature({ feature }, handleReset) {
    if (_.trim(feature)) {
      props.handleAddFeature(feature);
      handleReset();
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ feature: '' }}
      >
      {({ errors, isValid, touched, values, handleReset }) => (
      <TabContainer>
        <FlexRow>
          <InputGroup
            type='text'
            name='feature'
            label='Funcionalidade'
            maxLength={100}
            />
          <IconButton
            title='Adicionar'
            type='button'
            onClick={() => addFeature(values, handleReset)}
            disabled={!values.feature || props.previewMode}
            >
            <MdAdd />
          </IconButton>
        </FlexRow>

        {_.map(_.get(props, 'values.features') || [], (value, index) => (
          <Item key={index}>
            {value}
            <MdDelete onClick={() => props.handleRemoveFeature(index)} />
          </Item>
        ))}
      </TabContainer>
      )}
    </Formik>
  );
};

export default Features;
