import { ResponsiveBar } from '@nivo/bar';
import { line } from 'd3-shape';
import React, { Fragment } from 'react';
import { lightGray } from '~/components/mixins/color';
import { ChartContainer } from '~/views/dashboard/styles';

const barColor = '#695eb8';
const lineColor = '#e6467f';

const data = [
  { x: 'T1', share: 25.26, accumulated: 25.26 },
  { x: 'T10', share: 20.55, accumulated: 45.8 },
  { x: 'W1', share: 19.58, accumulated: 65.39 },
  { x: 'S1', share: 15.25, accumulated: 80.64 },
  { x: 'A1', share: 6.76, accumulated: 87.4 },
  { x: 'Extreme', share: 4.09, accumulated: 91.49 },
  { x: 'Waylab', share: 3.79, accumulated: 95.28 },
  { x: 'eMonitor', share: 2.9, accumulated: 98.17 },
  { x: 'Jugaad', share: 1.83, accumulated: 100.0 },
];

const Line = ({ bars, xScale, yScale }) => {
  const lineGenerator = line()
    .x((bar) => xScale(bar.data.indexValue) + bar.width / 2)
    .y((bar) => yScale(bar.data.data.accumulated));

  return (
    <Fragment>
      <path
        d={lineGenerator(bars)}
        fill='none'
        stroke={lineColor}
        strokeWidth={3}
        style={{ pointerEvents: 'none' }}
      />
      {bars.map((bar) => (
        <circle
          key={bar.key}
          cx={xScale(bar.data.indexValue) + bar.width / 2}
          cy={yScale(bar.data.data.accumulated)}
          r={4}
          fill='white'
          stroke={lineColor}
          style={{ pointerEvents: 'none' }}
        />
      ))}
    </Fragment>
  );
};

const Labels = ({ bars, yScale }) => {
  return bars.map(({ data: { data, indexValue }, x, width }, i) => {
    const labelMargin = parseInt(data.share),
      label = data.accumulated < 65 ? 'A' : data.accumulated < 90 ? 'B' : 'C';

    return (
      <g
        transform={`translate(${x}, ${yScale(data.share) + labelMargin})`}
        key={`${indexValue}-${i}`}
      >
        <text
          x={width / 2}
          y={labelMargin / 2}
          textAnchor='middle'
          alignmentBaseline='central'
          style={{
            fill: '#fff',
          }}
        >
          {label}
        </text>
      </g>
    );
  });
};

const ABCProducts = () => (
  <ChartContainer>
    <h1 className='title'>Curva ABC - Produtos</h1>
    <ResponsiveBar
      height={400}
      data={data}
      keys={['share']}
      maxValue={100}
      padding={0.2}
      margin={{
        top: 10,
        right: 10,
        bottom: 36,
        left: 36,
      }}
      indexBy='x'
      enableLabel={false}
      colors={[barColor]}
      axisLeft={{
        tickValues: 7,
      }}
      theme={{
        axis: {
          fontSize: '14px',
          tickColor: lightGray.hex(),
          ticks: {
            line: {
              stroke: lightGray.hex(),
            },
            text: {
              fill: lightGray.hex(),
            },
            grid: {
              line: {
                stroke: lightGray.hex(),
              },
            },
          },
        },
      }}
      layers={['grid', 'axes', 'bars', Labels, Line, 'markers', 'legends']}
    />
  </ChartContainer>
);

export default ABCProducts;
