import _ from 'lodash';
import { getAvatar } from '~/constants';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useAuth from './use-auth';

const initialState = {
  avatarFrmIsOpen: false,
  pwdFrmIsOpen: false,
};

const useLogged = () => {
  const { state: { logged = initialState }, dispatch } = useGlobalContext('logged');
  const { state: authState, actions: authActions } = useAuth();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, });
    showMessage('error', error);
  };

  const avatarFrm = (value = false) => {
    dispatch({ avatarFrmIsOpen: value });
  };

  const passwordFrm = (value = false) => {
    dispatch({ pwdFrmIsOpen: value });
  };

  const updateAvatar = async (data, actions) => {
    try {
      const formData = new FormData();
      formData.append('file', data);
      const response = await api.post('users/update-avatar', formData),
        res = _.get(response, 'data'),
        user = { ...authState.user, avatar: getAvatar(res) };

      authActions.updateAvatar(user);

      showMessage('success', 'Foto atualizada com sucesso!');
      actions.closeModal();
    } catch (error) {
      failure(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const updatePassword = async (data, actions) => {
    try {
      const { name, ...rest } = data,
        user = Object.assign({ name }, rest.oldPassword ? rest : {});
      await api.put('/users/update-password', user);
      showMessage('success', 'Senha alterada com sucesso');
      actions.closeModal();
    } catch (error) {
      failure(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return {
    state: {
      ...logged,
    },
    actions: {
      avatarFrm,
      passwordFrm,
      updateAvatar,
      updatePassword,
    },
  };
};

export default useLogged;
