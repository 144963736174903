import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as MdIcons from 'react-icons/md';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs';
import * as GiIcons from 'react-icons/gi';
import { accent, gray } from '~/components/mixins/color';
import { css } from 'styled-components';
import _ from 'lodash';

const AllIcons = { ...MdIcons, ...FaIcons, ...RiIcons, ...BsIcons, ...GiIcons };

const activeStyle = css`
  border-right: 2px solid ${accent.hex()};
  background: ${accent.fade(0.96).string()};
  color: ${accent.hex()};
  svg {
    color: ${accent.hex()} !important;
  }
`

export const SidebarText = styled.div`
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
  list-style: none;
  text-decoration: none;

  &:hover {
    background: ${accent.fade(0.75).string()};
    cursor: pointer;
  }

  ${props => props.active && activeStyle}
`

export const SidebarLink = styled(Link)`
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  color: ${gray.string()};
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-left: 30px;
  list-style: none;
  text-decoration: none;

  &:hover {
    background: ${accent.fade(0.90).string()};
    cursor: pointer;
  }

  ${props => String(props.active) === 'true' && activeStyle}
`

export const SidebarLabel = styled.span`
  margin-left: 14px;
`

export const DropdownLink = styled(NavLink)`
  display: flex;
  width: 100%;
  height: 45px;
  min-height: 45px;
  background: ${gray.fade(0.70).string()};
  padding-left: 2rem;
  align-items: center;
  text-decoration: none;
  color: #777;

  &:hover {
    background: ${accent.fade(0.70).string()};
    border-right: 2px solid ${accent.fade(0.65).string()};
    cursor: pointer;
  }

  &.active {
    border-right: 2px solid ${gray.hex()};
    background: ${gray.fade(0.30).string()};
  }
`

export const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SubMenu = ({ item, mini, showing, onShowing, selected, onSelect }) => {
  const Icon = AllIcons[item.icon] || AllIcons['MdList']

  const ItemLink = () => (
    <SidebarLink onClick={() => onSelect(item.group)} to={item.path} active={(selected === item.group).toString()}>
      <MenuContent>
        <Icon />
        {!mini && <SidebarLabel>{item.title}</SidebarLabel>}
      </MenuContent>
    </SidebarLink>
  )

  const ItemText = () => (
    <SidebarText onClick={() => onShowing(item.group)} active={selected === item.group}>
      <MenuContent>
        <Icon />
        {!mini && <SidebarLabel>{item.title}</SidebarLabel>}
      </MenuContent>
      {!mini &&
        <div>{
          (showing === item.group)
            ? <AllIcons.RiArrowUpSFill />
            : item.subNav
              ? <AllIcons.RiArrowDownSFill />
              : null
          }
        </div>
      }
    </SidebarText>
  )

  const ItemDropDown = ({ subItem }) => {
    const SubIcon = AllIcons[subItem.icon] || AllIcons['MdList']
    return (
      <DropdownLink to={subItem.path} onClick={() => onSelect(item.group)}>
        <SubIcon color={gray.string()} />
        <SidebarLabel>{subItem.title}&nbsp;</SidebarLabel>
      </DropdownLink>
    )
  }

  return (
    <>
      {_.size(item.subNav) === 0 ? <ItemLink /> : <ItemText />}

      {
        !mini &&
        (showing === item.group) &&
        _.map(item.subNav, (subItem, index) => <ItemDropDown subItem={subItem} key={index} />)
      }
    </>
  )
}
