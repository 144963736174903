import React from 'react'
import { MdKeyboardBackspace, MdSave, MdAdd } from 'react-icons/md'
import _ from 'lodash'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { formats, parseStringToDecimal } from '~/helper'
import {
  Container,
  FormContainer,
  Overlay,
  FormToolbar,
} from '~/views/partners/contract/styles'
import { IconButton } from '~/components/button'
import Tabs from '~/components/tabs'
import TabGeneral from '~/views/partners/contract/tab-general'
import TabHistory from '~/views/partners/contract/tab-history'
import usePartnerContract from '~/view-data/use-partner-contract'
import confirm from '~/components/confirm'

const ContractSchema = Yup.object().shape({
  startDate: Yup.mixed().required('Informe a data do início do contrato'),
  billingStartDate: Yup.mixed().required(
    'Informe a data do início do faturamento',
  ),
  daysBeforeBlock: Yup.number()
    .min(0)
    .required(
      'Informe a quantidade de dias a partir do vencimento para bloquear os serviços',
    ),
  modelContract: Yup.string().required(
    'Selecione um modelo para o contrato de aditamento',
  ),
  modelAdditive: Yup.string().required('Selecione um modelo para o aditivo'),
  modelCancellation: Yup.string().required(
    'Selecione um modelo para o distrato',
  ),
  groupName: Yup.string().nullable().required('Informe o nome para o grupo'),
  respName: Yup.string().nullable().required('Informe o nome do responsável'),
  respEmail: Yup.string()
    .nullable()
    .email('Informe um email válido')
    .required('Informe o email do responsável'),
  services: Yup.array().min(1, 'Necessário adicionar um cliente com produtos'),
})

const getGeneralSubTotal = (values) => {
  let list = _.flattenDeep(_.map(_.get(values, 'services'), 'modules'))
  return _.sumBy(list, (r) => {
    return isNaN(r.value) ? 0 : r.value * 1
  })
}

const getGeneralDiscountValue = (values) => {
  let list = _.flattenDeep(_.map(_.get(values, 'services'), 'modules'))
  return _.sumBy(list, (r) => {
    let val = isNaN(r.value) ? 0 : r.value * 1,
      discount = parseStringToDecimal(r.discount)
    return val * (discount / 100) || 0
  })
}

const getGeneralTotal = (values) => {
  let value = getGeneralSubTotal(values),
    discount = getGeneralDiscountValue(values)
  return value - discount
}

function PartnerContractForm({ match, history, acls }) {
  const canWrite = acls.includes('W')
  const { state, actions } = usePartnerContract()

  const tabs = [{ id: 'general', label: 'Geral', component: TabGeneral }]

  if (_.get(match, 'params.id') !== 'new') {
    tabs.push({ id: 'history', label: 'Histórico', component: TabHistory })
  }

  const handleAdd = () => {
    if (state.listMode === 'product') {
      actions.showProductForm(true)
      actions.selectProduct({ companies: [] })
    } else {
      actions.showCustomerForm(true)
      actions.selectCustomer({ modules: [] })
    }
  }

  const handleRemoveCustomer = async (values, action) => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja remover o cliente '${formats.cnpj_cpf(action.identity)} - ${
        action.name
      }' do contrato?`,
    })

    if (result) {
      actions.removeCustomer(values, action)
    }
  }

  const handleRemoveProduct = async (values, action) => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja remover o produto '${formats.cnpj_cpf(action.identity)} - ${
        action.name
      }' do contrato?`,
    })

    if (result) {
      actions.removeProduct(values, action)
    }
  }

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={actions.createOrUpdate}
        initialValues={state.model}
        validationSchema={ContractSchema}
      >
        {(formArgs) => (
          <FormContainer>
            <Overlay visible={formArgs.isSubmitting || state.loading} />

            <Tabs
              initialTab="general"
              height="calc(100vh - 200px)"
              tabs={tabs}
              disabled={!canWrite}
              listMode={state.listMode}
              onChangeListMode={actions.changeListMode}
              onSearchIdentify={actions.onSearchIdentify}
              onListPartners={actions.listPartners}
              onSubmitService={actions.changeServices}
              onSelectCustomer={actions.selectCustomer}
              onShowCustomerForm={actions.showCustomerForm}
              onRemoveCustomer={handleRemoveCustomer}
              onSelectProduct={actions.selectProduct}
              onShowProductForm={actions.showProductForm}
              onRemoveProduct={handleRemoveProduct}
              onSignContract={actions.signContract}
              onDownloadContract={actions.downloadContract}
              state={state}
              {...formArgs}
            />

            <FormToolbar>
              <div className="discount">
                <span>
                  Subtotal:{' '}
                  {formats.currency(getGeneralSubTotal(formArgs.values))}
                </span>
                <span>
                  Desconto:{' '}
                  {formats.currency(getGeneralDiscountValue(formArgs.values))}
                </span>
                <strong>
                  Total: {formats.currency(getGeneralTotal(formArgs.values))}
                </strong>
              </div>

              <div className="buttons">
                <IconButton
                  type="button"
                  title="Cancelar"
                  disabled={formArgs.isSubmitting}
                  onClick={() => history.goBack()}
                >
                  <MdKeyboardBackspace />
                </IconButton>

                <IconButton
                  type="submit"
                  title="Salvar"
                  disabled={!formArgs.isValid || formArgs.isSubmitting}
                >
                  <MdSave />
                </IconButton>

                <IconButton
                  type="button"
                  title={`Adicionar ${
                    state.listMode === 'company' ? 'Cliente' : 'Produto'
                  }`}
                  disabled={_.isEmpty(_.get(formArgs, 'values.partner'))}
                  onClick={handleAdd}
                >
                  <MdAdd />
                </IconButton>
              </div>
            </FormToolbar>
          </FormContainer>
        )}
      </Formik>
    </Container>
  )
}

export default PartnerContractForm
