import Color from 'color';

export { Color };

export const primary = Color('#202225');
export const secondary = Color('#36393f');
export const tertiary = Color('#2f3136');
export const quaternary = Color('#292b2f');
export const quinary = Color('#202225');
export const senary = Color('#191819');
export const septenary = Color('#282729');

export const black = Color('#000');
export const white = Color('#fff');
export const lightGray = Color('#ccc');
export const gray = Color('#8a8c90');

export const symbol = Color('#74777a');
export const link = Color('#bbb');

export const accent = Color('#6ad3b2');
export const green = Color('#53d449');
export const red = Color('#e06767');
