import styled from 'styled-components';
import { Form } from 'formik';
import { secondary, tertiary, lightGray } from '~/components/mixins/color';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: ${props => props.maxHeight};
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${tertiary.hex()};
  color: ${lightGray.hex()};
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${props => (props.visible ? "grid" : "none")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  opacity: ${props => (props.visible ? "1" : "0")};
  transition: 0.1s;
  z-index: 3;
`;

export const FormToolbar = styled.div`
  display: flex;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid ${secondary.hex()};

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
`;
