import React from 'react';
import * as Yup from 'yup';
import Tabs from '~/components/tabs';
import Features from '~/views/products/tab-features';
import General from '~/views/products/tab-general';
import Modules from '~/views/products/tab-modules';
import { FormContainer } from '~/views/products/styles';
import _ from 'lodash';

const tabs = [
  { id: 'general', label: 'Geral', component: General },
  { id: 'features', label: 'Funcionalidades', component: Features },
  { id: 'modules', label: 'Módulos', component: Modules }
];

export const ProductForm = ({ errors, permissions, status, touched, handleListModules, values, previewMode, setFieldValue, handleDeleteImage }) => {

  function addFeature(feature) {
    const temp = _.get(values, 'features') || [];
    setFieldValue('features', [...temp, feature]);
  };

  function removeFeature(index) {
    if (index >= 0) {
      let list = _.cloneDeep(_.get(values, 'features') || []);
      list.splice(index, 1);
      setFieldValue('features', list);
    }
  };

  function addModule(module) {
    const temp = _.get(values, 'modules') || [];
    const exists = _.find(temp, (f) => f.code === module.code);
    if (!exists) {
      setFieldValue('modules', [...temp, module]);
    }
  };

  function removeModule(index) {
    if (index >= 0) {
      let list = _.cloneDeep(_.get(values, 'modules') || []);
      list.splice(index, 1);
      setFieldValue('modules', list);
    }
  };

  return (
    <FormContainer>
      <Tabs
        initialTab='general'
        tabs={tabs}
        values={values}
        height='calc(100% - 50px)'
        previewMode={previewMode}
        setFieldValue={setFieldValue}
        handleAddFeature={addFeature}
        handleRemoveFeature={removeFeature}
        handleAddModule={addModule}
        handleRemoveModule={removeModule}
        handleImageDelete={handleDeleteImage}
        handleListModules={handleListModules}
        />
    </FormContainer>
  );
};

export const ProductSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Verifique se o nome está correto').required('Informe o nome do produto'),
  description: Yup.string().required('Informe a descrição'),
});
