import _ from 'lodash';
import React from 'react';
import Fieldset from '~/components/fieldset';
import { InputGroup, InputMask, Select } from '~/components/form';
import { brStatesValues, MASK_CEP } from '~/constants';
import { Row, FlexRow } from '~/components/layout';
import { TabContainer } from '~/views/partners/styles';

const TabAddresses = ({ previewMode, values = {}, errors, touched }) => (
  <TabContainer>
    <Fieldset label='Endereço'>
      <FlexRow>
        <div style={{flex: '1'}}>
          <InputGroup
            type='text'
            name='address.address'
            label='Av/Rua'
            disabled={previewMode}
            hasError={
              _.get(errors, 'address.address') && _.get(touched, 'address.address')
            }
            />
        </div>

        <div style={{flex: '0 0 150px'}}>
          <InputGroup
            type='text'
            name='address.number'
            label='Número'
            disabled={previewMode}
            hasError={
              _.get(errors, 'address.number') && _.get(touched, 'address.number')
            }
            />
        </div>
      </FlexRow>

      <InputGroup
        type='text'
        name='address.complements'
        label='Complemento'
        disabled={previewMode}
        hasError={
          _.get(errors, 'address.complements') &&
          _.get(touched, 'address.complements')
        }
        />

      <Row span={2}>
        <InputGroup
          type='text'
          name='address.block'
          label='Bairro'
          disabled={previewMode}
          hasError={
            _.get(errors, 'address.block') && _.get(touched, 'address.block')
          }
          />

        <InputMask
          name='address.zip_code'
          label='CEP'
          mask={MASK_CEP}
          disabled={previewMode}
          value={_.get(values, 'address.zip_code')}
          hasError={
            _.get(errors, 'address.zip_code') && _.get(touched, 'address.zip_code')
          }
          />
      </Row>

      <Row span={2}>
        <InputGroup
          type='text'
          name='address.city'
          label='Cidade'
          disabled={previewMode}
          hasError={_.get(errors, 'address.city') && _.get(touched, 'address.city')}
          />

        <Select
          name='address.state'
          label='Estado'
          disabled={previewMode}
          value={_.get(values, 'address.state')}
          options={{ values: [ { label: '', value: '' }, ...brStatesValues ] }}
          hasError={_.get(errors, 'address.state') && _.get(touched, 'address.state')}
          />
      </Row>
    </Fieldset>

    <Fieldset label='Endereço de cobrança'>
      <FlexRow>
        <div style={{flex: '1'}}>
          <InputGroup
            type="text"
            name="billingAddress.address"
            label="Av/Rua"
            disabled={previewMode}
            />
        </div>

        <div style={{flex: '0 0 150px'}}>
          <InputGroup
            type="text"
            name="billingAddress.number"
            label="Número"
            disabled={previewMode}
            />
        </div>
      </FlexRow>

      <InputGroup
        type="text"
        name="billingAddress.complements"
        label="Complemento"
        disabled={previewMode}
      />

      <Row span={2}>
        <InputGroup
          type="text"
          name="billingAddress.block"
          label="Bairro"
          disabled={previewMode}
          />

        <InputMask
          name="billingAddress.zip_code"
          label="CEP"
          mask={MASK_CEP}
          disabled={previewMode}
          value={_.get(values, 'billingAddress.zip_code')}
          />
      </Row>

      <Row span={2}>
        <InputGroup
          type="text"
          name="billingAddress.city"
          label="Cidade"
          disabled={previewMode}
          />

        <Select
          name="billingAddress.state"
          label="Estado"
          disabled={previewMode}
          value={_.get(values, 'billingAddress.state')}
          options={{ values: [ { label: '', value: '' }, ...brStatesValues ] }}
          />
      </Row>
    </Fieldset>
  </TabContainer>
);

export default TabAddresses;
