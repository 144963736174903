import React from 'react';
import _ from 'lodash';
import { InputGroup, InputLabel, InputDate, Select } from '~/components/form';
import { TableContainer, ViewMode } from '~/views/partners/contract/styles';
import { Row } from '~/components/layout';
import DataTable from '~/components/datatable';
import { Button } from '~/components/button';
import Fieldset from '~/components/fieldset';
import { formats, parseStringToDecimal } from '~/helper';
import ProductModal from '~/views/partners/contract/modal-product';
import CustomerModal from '~/views/partners/contract/modal-customer';

const getSubTotal = (values, field) => {
  return _.sumBy(_.get(values, field) || [], r => {
    let val = isNaN(r.value) ? 0 : r.value * 1,
      discount = parseStringToDecimal(r.discount);
    return val - (val * (discount / 100) || 0);
  });
};

const listModules = (allModules) => async (term, callback) => {
  try {
    let list = _.map(_.flattenDeep(_.map(allModules, 'modules')), r => ({ ...r, raw: r })),
      regexp = new RegExp(`^${term}`, 'i'),
      filtered = _.filter(list, (r) => regexp.test(r.groupName) || regexp.test(r.moduleName));
    callback(filtered);
  } catch (error) {
    callback([]);
  }
};

const getByProduct = list => {
  let modules = {};
  _.each(list, company => {
    _.each(company.modules, mod => {
      let key = `${mod.groupId}-${mod.moduleId}`,
        item = modules[key] || {
          module: _.pick(
            mod,
            'groupId',
            'groupName',
            'licenseType',
            'moduleId',
            'licenseMode',
            'moduleName',
            'value'
          )
        };
      item.companies = item.companies || [];

      item.companies.push({
        ..._.omit(company, 'modules'),
        ..._.omit(
          mod,
          'groupId',
          'groupName',
          'licenseType',
          'moduleId',
          'licenseMode',
          'moduleName'
        )
      });
      modules[key] = item;
    });
  });

  return _.values(modules);
};

const applyChangesByCompany = (values, data) => {
  let services = _.cloneDeep(values.services) || [],
    keyFn = r => `${r.groupId}-${r.moduleId}`,
    key = keyFn(_.get(data, 'module')),
    byCompany = _.keyBy(data.companies, 'identify');

  _.each(services, service => {
    let notFound = true,
      modData = byCompany[service.identify];
    _.each(service.modules, (mod, idx) => {
      let currKey = keyFn(mod);
      if (currKey === key) {
        notFound = false;
        if (modData) {
          service.modules[idx] = {
            ...mod,
            ..._.pick(modData, 'licenseMode', 'discount', 'value')
          };
        } else {
          service.modules.splice(idx, 1);
        }
      }
    });

    if (notFound && modData) {
      let newModule = {
        ..._.pick(modData, 'licenseMode', 'discount', 'value'),
        ..._.get(data, 'module'),
        raw: _.get(data, 'module')
      };
      service.modules.push(newModule);
    }
  });

  let notIncludeds = _.difference(_.keys(byCompany), _.map(services, 'identify'));

  _.each(notIncludeds, identify => {
    let currData = byCompany[identify];
    services.push({
      isNew: true,
      ..._.omit(currData, 'value', 'discount'),
      // ..._.omit(currData, 'value', 'discount', 'licenseMode'),
      modules: [
        {
          ..._.pick(currData, 'value', 'discount', 'licenseMode'),
          ..._.get(data, 'module'),
          raw: _.get(data, 'module')
        }
      ]
    });
  });
  return services;
};

const companyColumns = [
    {
      name: 'CNPJ/CPF',
      selector: 'identify',
      width: '160px',
      format: row => formats.cnpj_cpf(_.get(row, 'identify'))
    },
    {
      name: 'Nome',
      selector: 'name'
    },
    {
      name: 'Nº de Módulos',
      selector: 'modules',
      hide: 'md',
      width: '120px',
      center: true,
      format: row => _.size(_.get(row, 'modules'))
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      right: true,
      width: '120px',
      format: row => formats.currency(getSubTotal(row, 'modules'))
    }
  ],
  productColumns = [
    {
      name: 'Produto / Módulo',
      selector: 'name',
      format: r =>
        `${_.get(r, 'module.groupName')} / ${_.get(r, 'module.moduleName')}`
    },
    {
      name: 'Nº de Clientes',
      selector: 'companies',
      width: '120px',
      center: true,
      format: r => formats.number(_.size(r.companies))
    },
    {
      name: 'Valor (R$)',
      selector: 'value',
      right: true,
      width: '120px',
      format: r => formats.decimal(getSubTotal(r, 'companies'))
    }
  ];

const TabGeneral = props => {
  const {
    disabled,
    values = {},
    errors,
    touched,
    state,
    onShowCustomerForm,
    listMode,
    onChangeListMode,
    onSearchIdentify,
    onSelectCustomer,
    onSubmitService,
    onRemoveCustomer,
    onShowProductForm,
    onSelectProduct,
    onRemoveProduct
  } = props;

  const handleSelectCustomer = value => {
    onShowCustomerForm(true);
    onSelectCustomer(value);
  };

  const handleCustomerClose = () => {
    onShowCustomerForm(false);
    onSelectCustomer({});
  };

  const handleCustomerSubmit = value => {
    let services = _.cloneDeep(_.get(values, 'services') || []);
    const existsService = _.find(
      services,
      f => (f.id && f.id === value.id) || f.identify === value.identify
    );

    if (!existsService) {
      services.push({ ...value, isNew: true });
    } else {
      existsService.modules = _.get(value, 'modules');
    }
    onSubmitService({ ...values, services });
    handleCustomerClose();
  };

  const handleRemoveCustomer = value => {
    onRemoveCustomer(values, value);
    handleCustomerClose();
  };

  const handleSelectProduct = value => {
    onShowProductForm(true);
    onSelectProduct(value);
  };

  const handleProductClose = () => {
    onShowProductForm(false);
    onSelectProduct({});
  };

  const handleProductSubmit = value => {
    let services = applyChangesByCompany(values, value);
    onSubmitService({ ...values, services });
    handleProductClose();
  };

  const handleRemoveProduct = value => {
    onRemoveProduct(values, value);
    handleProductClose();
  };

  const getContractModelTypes = modelName => {
    let list = _.get(values, `allModels[${modelName}]`) || [];
    return {
      values: [
        { value: '', label: '' },
        ..._.map(list, r => ({ value: `${r.id}`, label: r.name }))
      ]
    };
  };

  return (
    <>
      <Row span={4}>
        <InputLabel
          label="Parceiro (*)"
          disabled={true}
          value={`${formats.cnpj_cpf(
            _.get(values, 'partner.identity')
          )} - ${_.get(values, 'partner.name')}`}
        />

        <InputDate
          name="startDate"
          label="Data do início do contrato (*)"
          disabled={disabled || !!_.get(values, 'id')}
          hasError={errors.startDate && touched.startDate}
        />

        <InputDate
          name="billingStartDate"
          label="Data do início do faturamento (*)"
          disabled={disabled || !!_.get(values, 'id')}
          hasError={errors.billingStartDate && touched.billingStartDate}
        />

        <InputGroup
          type="number"
          name="daysBeforeBlock"
          label="Dias após vencimento para bloqueio (*)"
          disabled={disabled || _.get(values, 'id')}
          hasError={errors.daysBeforeBlock && touched.daysBeforeBlock}
        />
      </Row>

      <Row span={3}>
        <Select
          name="modelContract"
          label="Modelo para contrato de aditamento (*)"
          options={getContractModelTypes('addition')}
          hasError={errors.modelContract && touched.modelContract}
        />
        <Select
          name="modelAdditive"
          label="Modelo para aditivo (*)"
          options={getContractModelTypes('additive')}
          hasError={errors.modelAdditive && touched.modelAdditive}
        />
        <Select
          name="modelCancellation"
          label="Modelo para distrato (*)"
          options={getContractModelTypes('cancellation')}
          hasError={errors.modelCancellation && touched.modelCancellation}
        />
      </Row>

      <Fieldset label="Responsável">
        <Row span={3}>
          <InputGroup
            type="text"
            name="groupName"
            label="Nome do grupo (*)"
            disabled={disabled}
            maxLength={100}
            hasError={errors.groupName && touched.groupName}
          />

          <InputGroup
            type="text"
            name="respName"
            label="Nome do responsável (*)"
            disabled={disabled}
            maxLength={100}
            hasError={errors.respName && touched.respName}
          />

          <InputGroup
            type="email"
            name="respEmail"
            label="E-mail (*)"
            disabled={disabled}
            maxLength={100}
            hasError={errors.respEmail && touched.respEmail}
          />
        </Row>
      </Fieldset>

      <Fieldset label={listMode === 'company' ? 'Clientes' : 'Produtos'}>
        <TableContainer showForm={state.isOpenCustomerForm}>
          <ViewMode>
            <span>Visão da lista por:</span>
            <Button
              type="button"
              primary={listMode === 'company'}
              onClick={() => onChangeListMode('company')}
              >
              Clientes
            </Button>
            <Button
              type="button"
              primary={listMode === 'product'}
              onClick={() => onChangeListMode('product')}
              >
              Produtos
            </Button>
          </ViewMode>

          {listMode === 'company' ? (
            <div className="list">
              <DataTable
                columns={companyColumns}
                data={{ rows: values.services }}
                noPagination={true}
                emptyText="Nenhum Cliente vinculado"
                onRowClicked={handleSelectCustomer}
                keyField="id"
                extraOptions={{
                  selectableRows: false,
                  selectableRowsHighlight: false
                }}
              />
            </div>
          ) : (
            <div className="list">
              <DataTable
                columns={productColumns}
                data={{ rows: getByProduct(values.services) }}
                noPagination={true}
                emptyText="Nenhum produto vinculado"
                onRowClicked={handleSelectProduct}
                keyField="id"
                extraOptions={{
                  selectableRows: false,
                  selectableRowsHighlight: false
                }}
              />
            </div>
          )}

          <CustomerModal
            isOpen={state.isOpenCustomerForm}
            data={state.selectedCustomer || {}}
            loading={state.modalLoading || false}
            handleOnSubmit={handleCustomerSubmit}
            closeModal={handleCustomerClose}
            onListModules={listModules(values.allProducts)}
            onRemoveCustomer={handleRemoveCustomer}
            onSearchIdentify={onSearchIdentify}
            title={
              <span>
                {!_.get(state, 'selectedCustomer.id')
                  ? `Novo Cliente`
                  : `Cliente (#${state.selectedCustomer.id}) - ${state.selectedCustomer.name} `}
              </span>
            }
          />
          <ProductModal
            isOpen={state.isOpenProductForm}
            data={state.selectedProduct || {}}
            loading={state.modalLoading || false}
            handleOnSubmit={handleProductSubmit}
            closeModal={handleProductClose}
            onListModules={listModules(values.allProducts)}
            onRemoveProduct={handleRemoveProduct}
            onSearchIdentify={onSearchIdentify}
            title={
              <span>
                {!_.get(state, 'selectedProduct.id')
                  ? `Novo Produto/Módulo`
                  : `Produto/Módulo - ${state.selectedProduct.name} `}
              </span>
            }
          />
        </TableContainer>
      </Fieldset>
    </>
  );
};

export default TabGeneral;
