import React from 'react';
import { formats } from '~/helper';
import { Item, TabContainer } from '~/views/partners/styles';

const TabUnlocks = (props) => {
  return (
    <TabContainer>
      {props.values &&
        props.values.unlocks &&
        props.values.unlocks.map((value, index) => (
          <Item key={index}>
            <div className='column-data'>
              <div className='first'>
                <strong>{value.who}</strong>
              </div>
              {formats.dateTimeZone(value.createdAt)}
            </div>
          </Item>
        ))}
    </TabContainer>
  );
};

export default TabUnlocks;
