import _ from 'lodash';
import useGlobalContext from '~/view-data/use-global-context';

const initialState = {
  filter: {
    use: false,
    visible: false,
    data: {},
  },
};

const useHeader = () => {
  const { state: { header = initialState }, dispatch } = useGlobalContext('header');

  const configure = (options) => {
    const {
      filter = { use: !!options.useFilter, visible: false, data: {} },
      onSearch = null,
      count = 'none',
      ...rest
    } = options;

    dispatch({ ...rest, onSearch, count, filter }, false, true);
  };

  const showFilter = () => {
    dispatch({ filter: { visible: true } }, false, true);
  };

  const hideFilter = () => {
    dispatch({ filter: { visible: false } }, false, true);
  };

  const clearFilter = (data) => {
    dispatch({ filter: { data, visible: true } });
  };

  const callFilter = (data = {}, actionFilter = _.noop) => {
    dispatch({ filter: { data, visible: true } });
    actionFilter(data);
  };

  return {
    state: {
      ...header,
    },
    actions: {
      configure,
      showFilter,
      hideFilter,
      callFilter,
      clearFilter,
    },
  };
};

export default useHeader;
