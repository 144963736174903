import styled, { css } from 'styled-components';
import { white, accent, red, green, lightGray, primary } from '~/components/mixins/color';
import PropTypes from 'prop-types';

const getColor = (props) => {
  if (props.primary) {
    return {
      text: white.hex(),
      background: accent.hex(),
      hover: accent.darken(0.10).hex()
    };
  } else if (props.error) {
    return {
      text: white.hex(),
      background: red.darken(0.15).hex(),
      hover: red.darken(0.20).hex()
    };
  } else  if (props.success) {
    return {
      text: white.hex(),
      background: green.darken(0.05).hex(),
      hover: green.darken(0.10).hex()
    };
  } else if (props.accent) {
    return {
      text: white.hex(),
      background: lightGray.hex(),
      hover: lightGray.hex()
    };
  } else if (props.unselected) {
    return {
      text: '#eee',
      background: '#666',
      hover: '#555'
    };
  }
  return {
    text: primary.lighten(0.30).string(),
    background: white.hex(),
    hover:  white.darken(0.10).string()
  };
};

export const Button = styled.button`
  color: ${(props) => getColor(props).text};
  background: ${(props) => getColor(props).background};
  border-radius: 3px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin || '5px 0px 5px 5px'};
  padding: ${(props) => props.padding || '0px 14px'};
  height: ${(props) => props.height || '40px'};
  font-weight: bold;
  font-size: ${(props) => props.fontSize || '15px'};
  min-width: ${(props) => props.minWidth || '100px'};
  transition: background 0.2s;

  &:hover:enabled, &:focus:enabled {
    background: ${(props) => getColor(props).hover};
  }

  ${(props) =>
    css`svg { margin-${(typeof props.children[0] === 'string') ? 'left' : 'right'}: 5px; } `
  }
  ${(props) => props.disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
    font-weight: normal;
  `}
`

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}


export const IconButton = styled.button`
  width: ${props => props.size || '36'}px;
  height: ${props => props.size || '36'}px;
  margin: ${(props) => props.noMargin ? '0' : (props.margin || '5px 0px 5px 5px')};
  color: ${(props) => getColor(props).text};
  background: ${(props) => getColor(props).background};
  border: none;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 20px;

  & svg path {
    stroke: ${accent.hex()};
  }
  &:hover:enabled, &:focus:enabled {
    background: ${(props) => getColor(props).hover} !important;
    & svg path {
      stroke: ${white.hex()};
    }
  }
  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
      user-select: none;
      font-weight: normal;
    `}
`;
