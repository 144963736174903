import React from 'react';
import { GoAlert } from 'react-icons/go';
import { MdCheck } from 'react-icons/md';
import { formats } from '~/helper';
import { Item, TabContainer } from '~/views/partners/styles';

const TabBillings = (props) => {
  return (
    <TabContainer>
      {props.values &&
        props.values.billings &&
        props.values.billings.map((value, index) => (
          <Item key={index}>
            <div className='column-data'>
              <div className='first'>
                <strong>{value.description}</strong>
                <div>{formats.dateTimeZone(value.dueDate)}</div>
              </div>
              {formats.currency(value.value)}
            </div>
            {value.paid ? (
              <MdCheck className={value.paid ? 'paid' : 'late'} />
            ) : (
              <GoAlert className={value.paid ? 'paid' : 'late'} />
            )}
          </Item>
        ))}
    </TabContainer>
  );
};

export default TabBillings;
