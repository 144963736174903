import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { format } from 'date-fns';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  term: null,
  offset: 0,
  model: null,
  formTab: 'general',
  contractModels: [],
  data: { rows: [], count: 0, offset: 0 }
};

const usePartners = () => {
  const { state: { partners = initialState }, dispatch } = useGlobalContext('partners'),
    { actions: headerActions } = useHeader(),
    history = useHistory();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: 'partners/list',
    params: { term: partners.term, offset: partners.offset }
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Parceiros',
      count: _.get(data, 'count', 0),
      loading: _.get(partners, 'loading', false),
      onSearch: text => list(0, text)
    });
    // eslint-disable-next-line
  }, [data, partners]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true, formTab: 'general' });
      let response = await api.get('partners/load', { params: { id: data.id } });
      let model = _.get(response, 'data.data'),
        contractModels = _.get(response, 'data.contractModels');

      dispatch({ model, contractModels, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`partners/${path}`, model);

      mutate(data);
      showMessage('success', `Parceiro ${editing ? 'atualizado' : 'criado'} com sucesso`);
      actions.toogleForm && actions.toogleForm();
    } catch (error) {
      failure(error);
    }
  };

  const removeContract = async (params) => {
    try {
      dispatch({ formLoading: true });
      await api.delete(`partners/contract/remove`, { params });
      load({ id: params.partnerId });
      dispatch({ formLoading: false });
      showMessage('success', `Contrato de aditamento (${params.name}) removido com sucesso`);
    } catch (error) {
      failure(error);
    }
  };

  const searchCnpj = async (cnpj, model) => {
    try {
      dispatch({ formLoading: true });
      let response = await api.get(`partners/cnpj-search`, { params: { cnpj } }),
        result = _.pick(response.data, 'name', 'tradeName', 'phone', 'email', 'address');

      dispatch({ formLoading: false, model: { ...model, ...result } });
    } catch (error) {
      failure(error);
    }
  };

  const downloadContractPdf = async ({ id }) => {
    dispatch({ loading: true });
    let date = new Date();
    try {
      let response = await api.get(`partners/contract/download`, { responseType: 'blob', params: { id } }),
        filename = `contrato-${_.padStart(id, 5, '0')}-${format(date, 'dd-MM-yyyy')}.pdf`;

      api.download(response, filename);
      dispatch({ loading: false });
    } catch (error) {
      failure(error, dispatch);
    }
  };

  const changeFormtab = formTab => {
    dispatch({ formTab });
  };

  const newContract = parentId => {
    history.push(`/partners/addition-contract/new?parent=${parentId}`);
  };

  const goToContract = id => {
    history.push(`/partners/addition-contract/${id}`);
  };

  return {
    state: {
      ...partners,
      data: data || {},
      loading: !data
    },
    actions: {
      pageChange,
      load,
      list,
      createOrUpdate,
      removeContract,
      searchCnpj,
      downloadContractPdf,
      changeFormtab,
      newContract,
      goToContract
    }
  };
};

export default usePartners;
