import React from 'react';
import _ from 'lodash';
import { ErrorMessage, useField } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowWhite from '~/assets/arrow-white.svg';
import { accent, gray, primary, tertiary, white } from '~/components/mixins/color';

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  &:focus-within > select {
    border-color: ${accent.hex()};
  }

  &:focus-within > svg {
    color: ${accent.hex()};
  }

  select {
    height: 46px;
    width: 100%;
    padding: ${(props) => props.padding || '19px 0 5px 10px'};
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: ${white.hex()};
    background: ${primary.hex()} url(${arrowWhite});
    background-repeat: no-repeat, repeat;
    background-position: right 1.5em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    border: 2px solid ${tertiary.hex()};
    border-radius: 3px;

    &:disabled {
      background-color: ${primary.lighten(0.8).hex()};
      color: ${gray.hex()};
    }

    &:focus + label,
    &:not([value=""]) + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
    }
    &:not([value=""]) + label {
      color: ${gray.hex()};
    }
    &:focus + label {
      color: ${white.hex()};
    }
    & + label {
      font-size: 70%;
      top: 13px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.7;
    }
  }
`;

const Select = ({ name, label, hasError, disabled, noMargin, padding, hideLabel, className, options, ...rest }) => {
  const [field] = useField({ name });

  const OptionDefault = ({ defaults }) => {
    const { value, label } = defaults;
    if (!value && !label) return null;
    return (
      <option value={value} key={value}>
        {label}
      </option>
    );
  };

  const Options = ({ values }) => {
    return _.map(values, (opt, index) => {
      return (
        <option value={opt.value} key={opt.value}>
          {opt.label}
        </option>
      );
    });
  };

  return (
    <Group className={className} padding={padding} noMargin={noMargin}>
      <select
        {...field}
        {...rest}
        className={!!hasError ? 'error' : ''}
        placeholder={label}
        disabled={disabled}
        value={(_.isUndefined(field.value) || _.isNull(field.value)) ? '' : field.value}
        >
        {options.defaults && <OptionDefault defaults={options.defaults} />}
        <Options values={options.values} />
      </select>
      {!hideLabel && <label htmlFor={name}>{label}</label>}
      <ErrorMessage name={name} className="error" component="div" />
    </Group>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  options: PropTypes.shape({
    defaults: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    values: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }),
};

export default Select;

/*
  Exemplo de uso

  <Select
    name="profile"
    hasError={errors.profile && touched.profile}
    label="Perfil"
    options={
      {
        defaults: {value: "", label: "Selecione um perfil"},
        values: Object.keys(PROFILES).map(key => ({"value": key, "label": PROFILES[key]}))
      }
    }
    />
*/
