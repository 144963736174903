import React, { useMemo } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { MdRemoveRedEye } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import Fieldset from '~/components/fieldset';
import DataTable from '~/components/datatable';
import { formats } from '~/helper';
import { FlexRow } from '~/components/layout';
import { FormBody, ProductsContainer, ActionsCell, ExpandContainer } from '~/views/licenses/styles';
import { InputLabel, Autocomplete, Select } from '~/components/form';
import { IconButton } from '~/components/button';

const ModulesRow = ({ data, moduleColumns }) => {
  return (
    <ExpandContainer>
      <DataTable
        columns={moduleColumns}
        data={{ rows: _.get(data, 'modules') || [] }}
        noPagination={true}
        keyField="id"
        emptyText="Nenhum módulo vinculado"
        extraOptions={{
          ignoreRowClicked: true,
          highlightOnHover: false,
          selectableRows: false,
          selectableRowsHighlight: false
        }}
        />
    </ExpandContainer>
    );
};

const getCustomers = (values) => {
  const result = { values: [{ value: '', label: '' }] };

  _.each(_.get(values, 'partner.contracts', []), (row) => {
    result.values.push({
        value: `${_.get(row, 'id')}`,
        label: `${_.get(row, 'groupName')}`,
      });
  });
  return result;
};

const getProducts = (values) => {
  if (!values.contractId) {
    return [];
  }
  let contract = _.find(_.get(values, 'partner.contracts'), (r) => `${_.get(r, 'id')}` === values.contractId) || {},
    modules = {};

  if (_.isEmpty(contract.services)) {
    contract.services = _.get(values, 'products');
  }
  _.each(contract.services, (company) => {
    _.each(company.modules, (mod) => {
      let key = `${mod.groupId}-${mod.moduleId}`,
        item = modules[key] || { ..._.pick(mod, 'groupId', 'groupName', 'licenseType', 'moduleId', 'moduleName', 'value') };
      item.companies = item.companies || [];

      item.companies.push({ ..._.omit(company, 'modules'), ..._.omit(mod, 'groupId', 'groupName', 'licenseType', 'moduleId', 'moduleName') });
      modules[key] = item;
    });
  });
  let services = _.values(modules);

  let byProduct = _.groupBy(services, 'groupName'),
    products = [];

  for (let product in byProduct) {
    let modules = _.orderBy(byProduct[product] || [], ['moduleName'], 'ASC'),
      hasDll = !!_.find(modules, { licenseType: 'dll' });
    products.push({ ..._.omit(_.get(modules, '[0]'), 'licenseType'), modules, hasDll });
  }
  return products;
};

const columns = [
    {
      name: 'Produto',
      selector: 'groupName'
    },
  ];

export const LicenseForm = ({ history, values, disabled, errors, touched, onListPartners, itemSelected, changeItemSelected, openCompaniesModal, onBlock }) => {

  const moduleColumns = useMemo(() => [
    {
      name: 'Módulo',
      selector: 'moduleName',
      format: (row) => _.get(row, 'moduleName')
    },
    {
      name: 'Nº de Clientes',
      selector: 'companies',
      width: '120px',
      center: true,
      format: (row) => formats.number(_.size(_.get(row, 'companies'))),
    },
    {
      name: 'Ação',
      selector: '_',
      width: '95px',
      center: true,
      format: (row) => {
        return (
          <ActionsCell>
            <IconButton size={32} title="Ver clientes" onClick={() => openCompaniesModal(row)}>
              <MdRemoveRedEye />
            </IconButton>
          </ActionsCell>
        );
      },
    },
  ], [openCompaniesModal]);

  const isExpanded = (row) => {
    return _.get(row, 'groupName') === itemSelected;
  };

  const changeExpanded = (state, row) => {
    changeItemSelected((state) ? _.get(row, 'groupName') : null)
  };

  return (
    <FormBody>
      <Autocomplete
        label='Parceiro'
        name='partner'
        preload={true}
        keyApp={`${_.get(values, 'contractId')}-`}
        keyField='id'
        value={values.partner}
        disabled={disabled || !values.isNew}
        valueFormat={(row) => `${formats.cnpj_cpf(_.get(row, 'identity'))} - ${_.get(row, 'name')}`}
        loadData={onListPartners}
        emptyText={'Pesquise um parceiro'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
      />

      {values.isNew && (
        <Select
          name='contractId'
          label='Grupo de cliente'
          disabled={disabled || !_.get(values, 'partner')}
          options={getCustomers(values)}
          hasError={errors.contractId && touched.contractId}
        />
      )}

      {!values.isNew && (
        <FlexRow>
          <InputLabel
            label='Grupo de cliente'
            value={`${_.get(values, 'contract.groupName')}`}
          />
          <IconButton
            size={40}
            margin={'0'}
            title="Ir para o contrato"
            onClick={() => history.push(`/partners/addition-contract/${_.get(values, 'contractId')}`)}>
            <FaRegEdit />
          </IconButton>
        </FlexRow>
      )}

      <Fieldset label='Produtos'>
        <ProductsContainer>
          <DataTable
            emptyText='Nenhum produto vinculado'
            noPagination={true}
            data={{ rows: getProducts(values) }}
            columns={columns}
            extraOptions={{
              compact: true,
              ignoreRowClicked: true,
              highlightOnHover: true,
              expandOnRowClicked: true,
              selectableRows: false,
              expandableRows: true,
              selectableRowsHighlight: false,
              expandableRowExpanded: isExpanded,
              onRowExpandToggled: changeExpanded,
              expandableRowsComponent: <ModulesRow moduleColumns={moduleColumns} />
            }}
          />
        </ProductsContainer>
      </Fieldset>
    </FormBody>
  );
};

export const LicenseSchema = Yup.object().shape({
  partner: Yup.mixed().required('Selecione o contrato'),
  contractId: Yup.string().required('Selecione o grupo de clientes'),
});
