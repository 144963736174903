import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconButton } from '~/components/crud/styles';
import styled from 'styled-components';
import { primary, quaternary, secondary, white } from '~/components/mixins/color';

const TabBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 40px;
  margin-bottom: 10px;
  border-bottom: 2px solid ${secondary.hex()};
  gap: 3px;
`;

const TabNav = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  padding: 0 20px;
  font-size: 14px;
  height: 42px;

  background: ${quaternary.hex()};
  color: ${white.hex()};
  border: 1px solid ${secondary.hex()};

  &.active {
    background: ${primary.hex()};
    border: 1px solid ${secondary.hex()};
  }
  &:first-child {
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
  }
`;

const Show = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: ${(props) => props.height || '100%'};
`;

const TabActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${secondary.hex()};
  flex: 0 0 56px;
  margin-top: 10px;
  padding: 10px;
`;

function Tabs({ tabs, currentTab, changeTab, initialTab, height, disabled, actions, ...rest }) {
  const [tab, setTab] = useState(initialTab);
  const activeTab = currentTab || tab;
  const changeActiveTab = changeTab || setTab;

  function isActive({ id }) {
    return activeTab === id;
  }

  return (
    <>
      <TabBar>
        {_.map(tabs, (row, index) => (
          <TabNav
            key={index}
            type="button"
            disabled={disabled}
            onClick={() => changeActiveTab(row.id)}
            className={`${isActive(row) ? 'active' : ''}`}
            >
            {row.label}
          </TabNav>
        ))}
      </TabBar>

      <TabContainer height={height}>
        {_.map(tabs, (row, index) => (
          <Show show={row.id === activeTab} key={index}>
            <row.component disabled={disabled} {...rest} />
          </Show>
        ))}
      </TabContainer>

      {!_.isEmpty(actions) &&
        <TabActions>
          {_.map(actions, (row, index) => (
            <IconButton
              key={index}
              type={row.isSubmit ? 'submit' : 'button'}
              title={row.label}
              disabled={row.disabled}
              onClick={row.action || _.noop}
              >
              <row.icon />
            </IconButton>
          ))}
        </TabActions>
      }
    </>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    })
  ).isRequired,
  actions: PropTypes.array.isRequired,
  initialTab: PropTypes.string.isRequired,
  canWrite: PropTypes.bool.isRequired,
};

Tabs.defaultProps = {
  canWrite: true,
  actions: [],
};

export default Tabs;
