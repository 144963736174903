import React from 'react';
import styled from 'styled-components';
import { gray } from '~/components/mixins/color';
import Spinner from '~/components/spinner';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: ${(props) => props.size ? (props.size * 1/3) + 'px' : '16px'};
  color: ${gray.hex()};
  align-items: center;
  justify-content: center;
`;

export default function Loading({ size = 30, label = 'Carregando...' }) {
  return (
    <Container size={size}>
      <Spinner visible={true} size={size} />
      {label}
    </Container>
  );
}
