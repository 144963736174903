import React from 'react';
import _ from 'lodash';
import { MdDelete } from 'react-icons/md';
import { formats } from '~/helper';
import { TabContainer } from '~/views/partners/styles';
import DataTable from '~/components/datatable';
import { IconButton } from '~/components/button';

const TabContracts = ({ values, goToContract, handleRemoveContract }) => {

  const columns = [
    {
      name: 'Id',
      selector: 'id',
      width: '70px'
    },
    {
      name: 'Grupo',
      selector: 'groupName',
    },
    {
      name: 'Inicia em',
      selector: 'startDate',
      width: '110px',
      format: (row) => formats.dateTimeZone(_.get(row, 'startDate'), 'dd/MM/yyyy'),
    },
    {
      name: 'Nº Clientes',
      selector: 'services',
      right: true,
      width: '110px',
      format: (row) => formats.number(_.size(row.services))
    },
    {
      name: 'Ações',
      selector: 'services',
      right: true,
      width: '110px',
      cell: (row) => <IconButton
        size={32}
        type="button"
        title="Remover"
        onClick={() => handleRemoveContract(row)}
        >
        <MdDelete />
      </IconButton>
    }
  ];

  return (
    <TabContainer>
      <DataTable
        columns={columns}
        data={{ rows: values.contracts || [] }}
        noPagination={true}
        emptyText="Nenhum contrato de aditamento encontrado"
        keyField="id"
        onRowClicked={(data) => goToContract(data.id)}
        extraOptions={{
          ignoreRowClicked: false,
          selectableRowsHighlight: false,
          selectableRows: false
        }}
        />
    </TabContainer>
  );
};

export default TabContracts;
