import React from 'react';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import Modal from '~/components/modal';
import { MdAdd, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { FlexRow } from '~/components/layout';
import { Autocomplete, InputLabel, InputGroup, InputMask, Select, CurrencyField } from '~/components/form';
import DataTable from '~/components/datatable';
import { Discount, ProductsContainer, ModalContainer } from '~/views/partners/contract/styles';
import { IconButton } from '~/components/button';
import Fieldset from '~/components/fieldset';
import { MASK_CNPJ, MASK_CPF, licenseModesMap } from '~/constants';
import confirm from '~/components/confirm';
import { onlyNumbers, formats, parseStringToDecimal } from '~/helper';

const ProductSchema = Yup.object().shape({
  module: Yup.mixed().nullable().required('Informe o Produto/Módulo'),
  licenseMode: Yup.mixed().nullable().required('Informe o tipo de licença'),
});

const columns = (arrayHelpers, form) => [
  {
    name: 'CNPJ/CPF',
    selector: 'identify',
    width: '160px',
    format: r => formats.cnpj_cpf(_.get(r, 'identify'))
  },
  {
    name: 'Nome',
    selector: 'name'
  },
  {
    name: 'Tipo de licença',
    selector: 'licenseMode',
    width: '330px',
    center: true,
    format: (r) => licenseModesMap[r.licenseMode] || r.licenseMode
  },
  {
    name: 'Valor (R$)',
    selector: 'value',
    right: true,
    width: '120px',
    format: r => formats.decimal(isNaN(r.value) ? 0 : r.value * 1)
  },
  {
    name: 'Desconto (%)',
    selector: 'discount',
    right: true,
    width: '120px',
    format: r => {
      let val = parseStringToDecimal(r.discount);
      return formats.decimal(isNaN(val) ? 0 : val * 1);
    }
  },
  {
    name: 'Total (R$)',
    selector: 'value',
    right: true,
    width: '120px',
    format: r => {
      let discount = parseStringToDecimal(r.discount) || 0,
        value = isNaN(r.value) ? 0 : r.value * 1;
      return formats.decimal(value - value * (discount / 100));
    }
  },
  {
    name: 'Ações',
    selector: '',
    hide: 'md',
    width: '80px',
    center: true,
    cell: (row, index) => {
      return (
        <>
          <IconButton
            size={32}
            type="button"
            title="Editar"
            onClick={() => editCompany(form, row, index)}
            >
            <MdEdit />
          </IconButton>
          <IconButton
            size={32}
            type="button"
            title="Remover"
            onClick={() => handleRemoveCompany(arrayHelpers, row, index)}
            >
            <MdDelete />
          </IconButton>
        </>
      );
    }
  }
];

const createOrUpdateCompanies = (arrayHelpers, form, values, row) => {
  let baseValue = {
    ..._.omit(row, 'module', 'index'),
    ...(_.get(row, 'module.raw') || {})
  };

  if (!_.isNull(row.index) && !_.isUndefined(row.index)) {
    form.setFieldValue(`companies[${row.index}]`, baseValue);
  } else {
    arrayHelpers.insert(0, baseValue);
  }
  form.setFieldValue('addForm', { value: _.get(values, 'module.value') || 0 });
};

const isValidModule = (value = {}) => {
  return !!value.licenseMode;
};

const editCompany = (form, row, index) => {
  form.setFieldValue('addForm', { ...row, index });
};

const handleRemoveCompany = async (arrayHelpers, row, index) => {
  const result = await confirm.show({
    title: 'Atenção',
    text: `Deseja remover o cliente '${formats.cnpj_cpf(row.identify)} - ${row.name}' ?`
  });

  if (result) {
    arrayHelpers.remove(index);
  }
};

const getSubTotal = values => {
  return _.sumBy(_.get(values, 'companies') || [], r => {
    return isNaN(r.value) ? 0 : r.value * 1;
  });
};

const getDiscountValue = values => {
  return _.sumBy(_.get(values, 'companies') || [], r => {
    let val = isNaN(r.value) ? 0 : r.value * 1,
      discount = parseStringToDecimal(r.discount);
    return val * (discount / 100) || 0;
  });
};

const getTotal = values => {
  let value = getSubTotal(values),
    discount = getDiscountValue(values);
  return value - discount;
};

function ProductModal({ data, isOpen, loading, title, closeModal, onListModules,  handleOnSubmit, onRemoveCustomer, onSearchCnpjCPF, disabled }) {
  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={ProductSchema}
      initialValues={{ ...data, addForm: data.addForm || { value: _.get(data, 'module.value') || 0 } }}
      initialTouched={{ module: true }}
      >
      {({ values, errors, touched, isValid, handleReset, setFieldValue, setFieldTouched, ...rest }) => {
        return (
          <Modal
            width="84%"
            height="96%"
            hideClose={true}
            open={isOpen}
            hide={closeModal}
            title={title}
            actions={[
              {
                label: 'Cancelar',
                action: () => {
                  handleReset();
                  closeModal();
                },
                disabled: loading
              },
              {
                label: 'Salvar',
                action: () => handleOnSubmit(values, { ...rest }),
                primary: true,
                disabled: loading || !values.module || !_.isEmpty(values.companies && _.find(values.companies, f => !f.licenseMode))
              }
            ]}
          >
            <ModalContainer>
              <section>
                <FlexRow>
                  <Autocomplete
                    name="module"
                    keyField="id"
                    preload={false}
                    label="Produto/Módulo"
                    value={_.get(values, 'module')}
                    valueFormat={row => `${row.groupName} - ${row.moduleName}`}
                    loadData={onListModules}
                    emptyText={'Pesquise um produto ou módulo'}
                    disabled={values.id}
                    tipText={'Digite... '}
                    loadingText={'Carregando...'}
                    notFoundText={'Não encontrado'}
                  />
                </FlexRow>
              </section>

              <Fieldset label="Clientes">
                <FieldArray
                  id="client_modules"
                  name="companies"
                  render={({ form, ...arrayHelpers }) => (
                    <ProductsContainer>
                      <FlexRow span={9}>
                        <div style={{ flex: '0 0 85px' }}>
                          <Select
                            name="addForm.identifyType"
                            label="Tipo"
                            hasError={_.get(errors, 'addForm.identifyType') && _.get(touched, 'addForm.identifyType')}
                            disabled={!values.module}
                            options={{
                              values: [
                                { value: '', label: '' },
                                { value: 'company', label: 'CNPJ' },
                                { value: 'person', label: 'CPF' }
                              ]
                            }}
                           />
                        </div>

                        <div style={{ flex: '0 0 180px' }}>
                          {_.get(values, 'addForm.identifyType') !==
                            'person' && (
                            <InputMask
                              disabled={!values.module || _.get(values, 'addForm.identifyType') !== 'company'}
                              mask={MASK_CNPJ}
                              name="addForm.identify"
                              label={'CNPJ'}
                              hasError={_.get(errors, 'addForm.identify') && _.get(touched, 'addForm.identify')}
                              onBlur={(e) => {
                                const value = onlyNumbers(e.target.value) || '';
                                setFieldTouched('identify', true);

                                if (_.size(value) === 14 && onSearchCnpjCPF && !values.name && !values.tradeName) {
                                  onSearchCnpjCPF(value, true, values.module, (data) => {
                                    setFieldValue('addForm', { ...values.addForm, ...data });
                                  });
                                }
                              }}
                            />
                          )}

                          {_.get(values, 'addForm.identifyType') ===
                            'person' && (
                            <InputMask
                              disabled={!values.module}
                              mask={MASK_CPF}
                              name="addForm.identify"
                              label={'CPF'}
                              hasError={_.get(errors, 'addForm.identify') && _.get(touched, 'addForm.identify')}
                              onBlur={(e) => {
                                const value = onlyNumbers(e.target.value) || '';
                                setFieldTouched('identify', true);
                                if (_.size(value) === 14 && onSearchCnpjCPF && !values.name && !values.tradeName) {
                                  onSearchCnpjCPF(value, true, values.module, (data) => {
                                      setFieldValue('addForm', { ...values.addForm, ...data });
                                  });
                                }
                              }}
                            />
                          )}
                        </div>

                        <InputGroup
                          type="text"
                          name="addForm.name"
                          label={_.get(values, 'addForm.identifyType') === 'person' ? 'Nome (*)' : 'Razão social (*)'}
                          disabled={!values.module || !_.get(values, 'addForm.identifyType')}
                          hasError={_.get(errors, 'addForm.name') && _.get(touched, 'addForm.name')}
                        />

                        <div style={{ flex: '0 0 425px' }}>
                          <Select
                            name="addForm.licenseMode"
                            label="Tipo de licença"
                            hasError={_.get(errors, 'addForm.licenseMode') && _.get(touched, 'addForm.licenseMode')}
                            disabled={!values.module}
                            onChange={evt => {
                              setFieldValue('addForm.identifyType', evt.target.value);
                              setFieldValue('addForm.identify', '');
                            }}
                            options={{
                              values: [
                                { value: '', label: 'Selecione o tipo de licença' },
                                ...(_.map(licenseModesMap, (label, value) => ({ value, label })))
                              ]
                            }}
                           />
                        </div>

                        <div style={{ flex: '0 0 120px' }}>
                          <InputLabel
                            label="Valor"
                            value={formats.currency(
                              _.get(values, 'addForm.value') || 0
                            )}
                          />
                        </div>

                        <div style={{ flex: '0 0 120px' }}>
                          <CurrencyField
                            type="discount"
                            name="addForm.discount"
                            label="Desconto (%)"
                            value={_.get(values, 'addForm.discount') || 0}
                            disabled={!values.module}
                          />
                        </div>

                        <div style={{ flex: '0 0 46px' }}>
                          <IconButton
                            type="button"
                            title="Adicionar"
                            disabled={!values.module || !isValidModule(values.addForm)}
                            onClick={() =>
                              createOrUpdateCompanies(arrayHelpers, form, values, values.addForm)
                            }
                            >
                            {isNaN(_.get(values, 'addForm.index')) ? (<MdAdd />) : (<MdSave /> )}
                          </IconButton>
                        </div>
                      </FlexRow>

                      <div className="modules-table">
                        <DataTable
                          emptyText="Nenhum cliente vinculado"
                          noPagination={true}
                          data={{ rows: values.companies || [] }}
                          columns={columns(arrayHelpers, form)}
                          extraOptions={{
                            ignoreRowClicked: true,
                            selectableRows: false,
                            selectableRowsHighlight: false
                          }}
                        />
                      </div>
                    </ProductsContainer>
                  )}
                />
              </Fieldset>

              <Discount>
                <span>Subtotal: {formats.currency(getSubTotal(values))}</span>
                <span>
                  Desconto: {formats.currency(getDiscountValue(values))}
                </span>
                <strong>Total: {formats.currency(getTotal(values))}</strong>
              </Discount>
            </ModalContainer>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default ProductModal;
