import React from "react";
import PropTypes from "prop-types";
import { FieldArray, ErrorMessage } from "formik";
import styled from 'styled-components';
import Divider from '~/components/divider';

const Container = styled.div `
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
      display: block;
      padding-left: 15px;
      text-indent: -15px;
      color: #555;
    }
    input[type=checkbox] {
      width: 13px;
      height: 13px;
      padding: 0;
      margin: 0 5px 0 0;
      vertical-align: bottom;
      position: relative;
      top: -1px;
      overflow: hidden;
    }
`;

const Box = styled.div `
    overflow-y: auto;
    height: ${props => props.height};
    padding: 0px 10px 10px;
    margin-bottom: 10px;
    border-left: 1px solid rgba(0,0,0,0.05);
    border-right: 1px solid rgba(0,0,0,0.05);
    border-bottom: 1px solid rgba(0,0,0,0.05);

`;

const GroupCheckbox = (props) => {
  const { label, name, values, disabled, allowedValues, maxHeight = "100%" } = props;

  return (
    <Container>
      <Divider label={label} />
      <Box height={maxHeight}>
        <FieldArray
          id={name}
          name={name}>
          {({push, remove, form}) => (
            <div>
              { Object.keys(allowedValues).map(key =>
                <div key={key}>
                  <label>
                    <input
                      type="checkbox"
                      name={name}
                      disabled={disabled}
                      checked={values.includes(key)}
                      onChange={async (event) => {
                        if (event.target.checked) {
                          await push(key);
                        } else {
                          const idx = values.indexOf(key);
                          await remove(idx);
                        }
                        form.validateForm();
                      }}
                    />
                    {allowedValues[key]}
                  </label>
                </div>
              )}
            </div>
          )}
        </FieldArray>
      </Box>
      <ErrorMessage name={props.name} className={"error"} component="div" />
    </Container>
  )
}

GroupCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  values: PropTypes.array.isRequired,
  allowedValues: PropTypes.object.isRequired,
}

GroupCheckbox.defaultProps = {
  hasError: false,
  values: [],
  allowedValues: {}
}

export default GroupCheckbox;