import React from 'react';
import { Switch } from 'react-router-dom';
import Route from '~/routes/routes';
import Billings from '~/views/billings';
import BillingWizard from '~/views/billings/wizard';
import ContractModels from '~/views/contract-models';
import ContractModel from '~/views/contract-models/form';
import PartnerContract from '~/views/partners/contract';
import Dashboard from '~/views/dashboard';
import Forbidden from '~/views/forbidden';
import Home from '~/views/home';
import Login from '~/views/login';
import Partners from '~/views/partners';
import Products from '~/views/products';
import Modules from '~/views/modules';
import Profiles from '~/views/profiles';
import Users from '~/views/users';
import ErpVersions from '~/views/erp-versions';
import Licenses from '~/views/licenses';

export default function Routes() {
  return (
    <Switch>
      <Route path='/' exact component={Login} />
      <Route path='/forbidden' component={Forbidden} />

      <Route path='/home' component={Home} isPrivate />
      <Route path='/dashboard' component={Dashboard} isPrivate />

      <Route path='/users' component={Users} isPrivate />
      <Route path='/profiles' component={Profiles} isPrivate />
      
      <Route path='/partners/addition-contract/:id' component={PartnerContract} isPrivate />
      <Route path='/partners' component={Partners} isPrivate />
      <Route path='/products' component={Products} isPrivate />
      <Route path='/modules' component={Modules} isPrivate />

      <Route path='/contract-models/:id' component={ContractModel} isPrivate />
      <Route path='/contract-models' component={ContractModels} isPrivate />

      <Route path='/billings/wizard' component={BillingWizard} isPrivate />
      <Route path='/billings' component={Billings} isPrivate />

      <Route path='/licenses' component={Licenses} isPrivate />
      <Route path='/erp-versions' component={ErpVersions} isPrivate />

      <Route component={Forbidden} />
    </Switch>
  );
}
