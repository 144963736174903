import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage } from '~/helper';
import api from '~/services/api';
import { useHistory, useParams } from 'react-router-dom';
import useGlobalContext from '~/view-data/use-global-context';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  model: null,
};

const useContractModel = () => {
  const { state: { contractModel = initialState }, dispatch } = useGlobalContext('contractModel'),
    { actions: headerActions } = useHeader(),
    history = useHistory(),
    { id } = useParams();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, modalLoading: false });
    showMessage('error', error);
  };

  const load = async (id) => {
    try {
      dispatch({ formLoading: true });
      let model = { active: true, contractType: 'contract', contents: '' };

      if (id) {
        const response = await api.get('contract-models/load', {
          params: { id: id },
        });
        model = response.data;
      }

      dispatch({ model, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  useEffect(() => {
    load(id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    headerActions.configure({
      title: id === 'new' ? `Novo Modelo` : `Modelo (#${id})`,
      count: 'none',
      loading: _.get(contractModel, 'loading', false),
      onSearch: null,
    });
    // eslint-disable-next-line
  }, [contractModel]);

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`contract-models/${path}`, model);

      showMessage(
        'success',
        `Modelo de Contrato ${editing ? 'atualizado' : 'criado'} com sucesso`
      );
      history.push(`/contract-models`);
      actions.toogleForm && actions.toogleForm();
      dispatch({ formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...contractModel,
    },
    actions: {
      createOrUpdate,
    },
  };
};

export default useContractModel;
