import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from '~/context';
import Routes from '~/routes';
import history from '~/services/history';
import GlobalStyle from '~/styles/global';

const envNames = {
  production: '',
  stage: '-homolog',
  test: '-dev',
  development: '-local',
};

function App() {
  useEffect(() => {
   document.title = `Adept @ v${process.env.REACT_APP_VERSION}${envNames[process.env.REACT_APP_ENV || 'development']}`;
  }, []);

  return (
    <Router history={history}>
      <AppProvider name='adept'>
        <Routes />
      </AppProvider>
      <GlobalStyle />
      <ToastContainer autoClose={1800} />
    </Router>
  );
};

export default App;
