import { useEffect } from 'react';
import { showMessage } from '~/helper';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';
import useGlobalContext from '~/view-data/use-global-context';
import _ from 'lodash';
import api from '~/services/api';

const initialState = {
  loading: false,
  formLoading: false,
  modalLoading: false,
  modalData: null,
  term: null,
  offset: 0,
  model: null,
  itemSelected: null,
  selectedEnvironment: null,
  showEnvironmentSetupModal: false,
  showCompaniesModal: false,
  data: { rows: [], count: 0, offset: 0 },
};

const useLicense = () => {
  const { state: { licenses = initialState }, dispatch } = useGlobalContext('licenses');
  const { actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, modalLoading: false });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: '/licenses/list',
    params: { term: licenses.term, offset: licenses.offset },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Licenças',
      count: _.get(data, 'count', 0),
      loading: _.get(licenses, 'loading', false),
      onSearch: (text) => list(0, text),
    });
    // eslint-disable-next-line
  }, [data, licenses]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true });
      let model = { active: true, products: [], isNew: true };

      if (!_.isEmpty(data)) {
        let params = {
            contractId: _.get(data, 'contract.id'),
          };
        const response = await api.get('licenses/load', { params });
        model = response.data;
      }
      dispatch({ model, formLoading: false, itemSelected: null });
    } catch (error) {
      failure(error);
    }
  };

  const listPartners = async (term, callback) => {
    try {
      const response = await api.get(`licenses/list-partners`, { params: { term } });
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`licenses/${path}`, model);

      mutate(data);
      actions.toogleForm && actions.toogleForm();
      showMessage('success', `Licença ${editing ? 'atualizada' : 'criada'} com sucesso`);

    } catch (error) {
      failure(error);
    } finally {
      dispatch({ formLoading: false });
    }
  };

  const block = async (license, product) => {
    try {
      dispatch({ formLoading: true });
      const contractId = _.get(license, 'contract.id');
      await api.post(`licenses/block`, { contractId, product });

      const products = _.map(licenses.model.products, (p) => {
        const newProduct = _.cloneDeep(p);

        if (newProduct.groupId === product.groupId && newProduct.moduleId === product.moduleId) {
          newProduct.blocked = newProduct.blocked ? !p.blocked : true;
        }

        return newProduct;
      });

      dispatch({ model: { ...licenses.model, products }, formLoading: false });
    } catch (error) {
      failure(error);
    } finally {
      dispatch({ formLoading: false });
    }
  };

  const changeItemSelected = (itemSelected) => {
    dispatch({ itemSelected });
  };

  const changeSelectedEnvironment = (selectedEnvironment) => {
    dispatch({ selectedEnvironment });
  };

  const openEnvironmentSetupModal = async (data = {}) => {
    let environments = [];
    try {
      let identities = _.uniq(_.map(_.flatten(_.map(_.filter(_.get(data, 'modules'), { licenseType: 'smartbank' }), 'companies')), 'identify')),
        response = await api.post(`licenses/list-environments`, { identities });
      environments =  _.get(response, 'data') || [];
    } catch (error) {
    }
    let selectedEnvironment = _.get(_.find(environments, { production: true }), 'id');
    dispatch({ modalData: data, environments, showEnvironmentSetupModal: true, selectedEnvironment });
  };

  const closeEnvironmentSetupModal = () => {
    dispatch({ modalData: {}, showEnvironmentSetupModal: false });
  };

  const submitEnvironmentSetupModal = async (args) => {
    try {
      dispatch({ modalLoading: true });
      let params = { environment: _.get(args, 'environment'), all: _.get(args, 'all') };
      await api.post(`licenses/update-environment`, params);

      showMessage('success', `Ambiente de produção alterado com sucesso`);
      dispatch({ modalLoading: false, modalData: {}, showEnvironmentSetupModal: false });
    } catch (error) {
      failure(error);
    }
  };

  const openCompaniesModal = async (data) => {
    dispatch({ modalData: data, showCompaniesModal: true });
  };

  const closeCompaniesModal = () => {
    dispatch({ modalData: {}, showCompaniesModal: false });
  };

  const searchCnpj = async (cnpj, model, callback) => {
    try {
      let response = await api.get(`licenses/cnpj-search`, { params: { cnpj } }),
        result = response.data || {},
        changed = {};

      _.set(changed, 'name', _.get(result, 'name') || _.get(result, 'tradeName'));
      _.set(changed, 'tradeName', _.get(result, 'tradeName') || _.get(result, 'name'));
      _.set(changed, 'businessArea', _.get(result, 'businessAreas[0]') || '');
      _.set(changed, 'phone', _.get(result, 'phone'));
      _.set(changed, 'email', _.get(result, 'email'));
      _.set(changed, 'address', _.get(result, 'address'));

      callback({ ...model, ...changed });
    } catch (error) {
      failure(error);
    }
  };

  const listBanks = async (term, callback) => {
    try {
      const response = await api.get(`licenses/list-banks`, { params: { term } });
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  const forceRefresh = async (params) => {
    try {
      dispatch({ formLoading: true });
      await api.post(`licenses/refresh`, params);
      showMessage('success', `Reprocessamento da licença executado com sucesso.`);
      dispatch({ formLoading: false });
      load({ contract: { id: params.contractId } })
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...licenses,
      data: data || {},
      loading: !data,
    },
    actions: {
      pageChange,
      load,
      list,
      block,
      createOrUpdate,
      listPartners,
      changeItemSelected,
      openEnvironmentSetupModal,
      closeEnvironmentSetupModal,
      submitEnvironmentSetupModal,
      changeSelectedEnvironment,
      openCompaniesModal,
      closeCompaniesModal,
      listBanks,
      searchCnpj,
      forceRefresh,
    },
  };
};

export default useLicense;
