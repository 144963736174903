import styled from 'styled-components';
import { Form } from 'formik';
import { lightGray, secondary, tertiary, quaternary, accent, red } from '~/components/mixins/color';

export const Container = styled.div`
  height: 100%;
  max-height: ${(props) => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  background: ${tertiary.hex()};
  color: ${lightGray.hex()};
  position: relative;
  height: 100%;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  transition: 0.1s;
  z-index: 3;
`;

export const FormToolbar = styled.div`
  display: flex;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid ${secondary.hex()};

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 145px);
`;

export const ActionsCell = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  button {
    margin: 0;
    &:hover {
      background: ${quaternary.hex()};
    }
  }
`;

export const BlockButton = styled.button`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 3px;
  font-size: 18px;
  background: transparent;
  border: none;
  color: ${(props) => (props.blocked === false ? accent.hex() : red.hex())};

  &:hover:enabled,
  &:focus:enabled {
    background: ${(props) => (props.active ? props.color : secondary.hex())};
    color: ${(props) => (props.active ? secondary.hex() : props.color)};
    opacity: 0.85;
  }
`;

export const ExpandContainer = styled.div`
  width: 100%;
  height: calc(100vh - 569px);
  border: 2px solid ${secondary.hex()};
  border-top: none;
  min-height: 120px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const EnviromentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
`;

export const CompaniesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
`;

export const EstablishmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
`;

export const TipContainer = styled.div`
  margin-bottom: 10px;
  padding: 20px 10px;
  font-size: 90%;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;

  .input-info {
    padding: 5px;
  }
`;

export const EstablishmentFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: calc(100vh - 315px);
  overflow-y: auto;
`;
