import React from 'react';
import * as Yup from 'yup';
import Tabs from '~/components/tabs';
import TabGeneral from '~/views/modules/tab-general';
import TabDependencies from '~/views/modules/tab-dependencies';
import { FormContainer } from '~/views/modules/styles';
import Spinner from '~/components/spinner';
import confirm from '~/components/confirm';
import _ from 'lodash';

const tabs = [
  { id: 'general', label: 'Geral', component: TabGeneral },
  { id: 'dependencies', label: 'Dependências', component: TabDependencies },
];

export const ModuleForm = (props) => {
  function addModule(module) {
    const temp = _.get(props.values, 'dependencies') || [];
    const exists = _.find(temp, (f) => f.code === module.code);
    if (!exists) {
      props.setFieldValue('dependencies', [...temp, module]);
    }
  };

  async function removeModule(index) {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover esta dependência ?`
      });

      if (result) {
        let list = _.cloneDeep(_.get(props.values, 'dependencies') || []);
        list.splice(index, 1);
        props.setFieldValue('dependencies', list);
      }
    }
  };

  return (
    <FormContainer>
      <Spinner visible={props.loading} />

      {!props.loading &&
        <Tabs
          initialTab='general'
          tabs={tabs}
          height='calc(100% - 50px)'
          {...props}
          handleAddModule={addModule}
          handleRemoveModule={removeModule}
          handleListModules={props.handleListModules}
          />
      }
    </FormContainer>
  );
};

const defaultSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  code: Yup.string().required('Informe o código'),
  version: Yup.string().required('Informe a versão'),
  licenseType: Yup.string().required('Informe o Tipo de Licença')
});

const dllSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  code: Yup.string().required('Informe o código'),
  version: Yup.string().required('Informe a versão'),
  releaseNotes: Yup.string().required('Informe a Release Note'),
  licenseType: Yup.string().required('Informe o Tipo de Licença'),
  moduleFiles: Yup.mixed().test('match', '', function(value) {
    const { path, parent, createError } = this;
    if (_.isEmpty(_.get(parent, 'moduleFiles'))) {
      return createError({ path, message: `Informe ao menos um arquivo para uma das versões do ERP` });
    }
    return true;
  })
});

export const ModuleSchema = () => Yup.lazy((values) => _.get(values, 'licenseType') !== 'dll' ? defaultSchema : dllSchema);
