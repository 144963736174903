import React from 'react';
import _ from 'lodash';
import { MdFileDownload } from 'react-icons/md';
import { RiCheckDoubleLine, RiCheckLine } from 'react-icons/ri';
import EmptyState from '~/components/empyt-state';

import { IconButton } from '~/components/button';
import { Timeline } from '~/views/partners/contract/styles';
import { formats } from '~/helper';

const contractTypeMap = {
  contract: 'Contrato mãe',
  addition: 'Contrato de Aditamento',
  additive: 'Aditivo',
  cancellation: 'Distrato',
};

function TabHistory({
  disabled,
  values = {},
  errors,
  touched,
  onSignContract = _.noop,
  onDownloadContract = _.noop,
}) {
  if (_.isEmpty(values.historyEntries)) {
    return (
      <EmptyState
        text={'Contrato sem histórico'}
        visible={true}
        size={'200px'}
      />
    );
  }

  return (
    <Timeline>
      {_.map(values.historyEntries, (entry, index) => (
        <li
          key={index}
          data-date={formats.dateTimeZone(entry.createdAt, 'dd/MM/yyyy HH:mm')}
        >
          <div>
            <h3>{contractTypeMap[entry.contractType]}</h3>
            <p>
              Mais detalhes sobre a entrada de histórico vinculada ao contrato.
            </p>
          </div>
          <div className='actions'>
            <IconButton
              type='button'
              title={
                entry.signed
                  ? `Assinado em ${formats.dateTimeZone(entry.signedAt)}`
                  : 'Não Assinado'
              }
              onClick={() =>
                onSignContract({ ...entry, contractId: values.id })
              }
            >
              {entry.signed ? <RiCheckDoubleLine /> : <RiCheckLine />}
            </IconButton>
            <IconButton
              type='button'
              title='Baixar'
              onClick={() => onDownloadContract({ entry: entry.id })}
              >
              <MdFileDownload />
            </IconButton>
          </div>
        </li>
      ))}
    </Timeline>
  );
}

export default TabHistory;
