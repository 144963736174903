import React from 'react';
import * as Yup from 'yup';
import { Checkbox, InputGroup } from '~/components/form';
import { FormContainer } from '~/views/erp-versions/styles';
import { Row } from '~/components/layout';
import Fieldset from '~/components/fieldset';

export const ErpVersionForm = ({ errors, permissions, status, touched, values = {}, previewMode }) => {

  return (
    <FormContainer>
      <Checkbox name='active' label='Ativa' />

      <InputGroup
        type='text'
        name='name'
        label='Nome'
        maxLength={50}
        disabled={previewMode}
        hasError={errors.name && touched.name}
        />

      <Fieldset label="Versão">
        <Row span={2}>
          <InputGroup
            type='text'
            name='from'
            label='De'
            maxLength={10}
            disabled={previewMode}
            hasError={errors.from && touched.from}
            />

          <InputGroup
            type='text'
            name='to'
            label='Até'
            maxLength={10}
            disabled={previewMode}
            hasError={errors.to && touched.to}
            />
        </Row>
      </Fieldset>

      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  );
};

let numErr = 'Informe um número válido',
  numSigErr = 'Informe um número positivo',
  numFloatErr = 'Informe um número inteiro';

export const ErpVersionSchema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  from: Yup.number(numErr).positive(numSigErr).integer(numFloatErr).required('Informe a versão de'),
  to: Yup.number(numErr).positive(numSigErr).integer(numFloatErr).required('Informe a versão até'),
});
