import React from 'react';
import _ from 'lodash';
import { MdCheck, MdClear } from 'react-icons/md';
import { formats } from '~/helper';
import useBillings from '~/view-data/use-billing';
import useHeader from '~/view-data/use-header';
import { Container } from '~/views/billings/styles';
import Crud from '~/components/crud';
import CellStatus from '~/components/datatable/cell-status';
import FormFilter from '~/views/billings/filter';
import Form from '~/views/billings/detail';

const statusColors = {
  'open': '#90d1ff',
  'paid': '#a3d865',
  'delayed': '#e06767'
};

const columns = [
  {
    name: '# Nome',
    selector: 'name',
    cell: (row) => {
      let text = `${formats.cnpj_cpf(row.partner.identity)} - ${row.partner.name}`;
      return (
        <CellStatus title={text} color={statusColors[row.stats || 'open']}>
          <strong>{text}</strong>
        </CellStatus>
      );
    },
  },
  {
    name: 'Data de referência',
    selector: 'dueDate',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Valor',
    selector: 'value',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.currency(_.get(row, 'value')),
  },
  {
    name: 'Quantidade clientes',
    selector: 'counters.customers',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.number(_.get(row, 'counters.customers')),
  },
  {
    name: 'Quantidade produtos',
    selector: 'counters.products',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.number(_.get(row, 'counters.products')),
  },
  /*
  {
    name: 'Plataforma web',
    selector: 'platforms.web',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.number(_.get(row, 'platforms.web')),
  },
  {
    name: 'Quantidade produtos',
    selector: 'platforms.sql',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.number(_.get(row, 'platforms.sql')),
  },
  {
    name: 'Quantidade produtos',
    selector: 'platforms.hana',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) =>
      formats.number(_.get(row, 'platforms.hana')),
  },
  */
];

export default function Billings({ acls }) {
  const { state, actions } = useBillings();
  const  { state: headerState, actions: headerActions } = useHeader();
  const canWrite = acls.includes('W');

  const showFilter = _.get(headerState, 'filter.visible');

  return (
    <Container>
      <Crud
        openForm={state.openForm || showFilter}
        useOpenForm={true}
        data={state.data}
        columns={columns}
        onRowClicked={actions.load}
        onChangePage={actions.pageChange}
        onCloseFilter={actions.hideFilter}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        rightPadding="0"
        emptyText="Nenhuma fatura encontrada"
        formOptions={{
          onSubmit: actions.callFilter,
          initialValues: showFilter ? headerState.filter.data : state.model,
        }}
        renderForm={(args) => (
          showFilter ?  <FormFilter onListPartners={actions.listPartners} {...args} /> : 
            <Form actions={actions} previewMode={!canWrite} {...args} />
        )}
        actions={!showFilter ? [] :
          [
            {
              label: 'Limpar Filtro',
              icon: MdClear,
              action: () => headerActions.clearFilter({ year: 2021, month: 0, status: 'open', partner: null })
            },
            {
              label: 'Aplicar Filtro',
              icon: MdCheck,
              action: (data) => actions.list()
            }
          ]
        }
      />
    </Container>
  );
}
