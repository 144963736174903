import _ from 'lodash';
import { format, parse, parseISO, startOfMonth, lastDayOfMonth, isValid } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { ptBR } from 'date-fns/locale';

export const formats = {
  capitalize: (str) => {
    return !str ? '' : _.toLower(_.trim(str)).replace(/\w\S*/g, (w) => (w.replace(/^\w/, _.toUpper)));
  },
  phone: (str) => {
    let cleaned = onlyNumbers(str) || '';
    if (_.size(cleaned) === 10) {
      return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    }
    return _.size(cleaned) === 11 ? '' : cleaned.replace(/^(\d{2})(\d{4})(\d{5})$/, '($1) $2-$3');
  },
  cep: (str) => {
    let cleaned = onlyNumbers(str);
    return _.size(cleaned) !== 8 ? '' : cleaned.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2-$3');
  },
  cnpj: (str) => {
    let cleaned = onlyNumbers(str);
    return _.size(cleaned) !== 14 ? '' : cleaned.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  },
  cpf: (str) => {
    let cleaned = onlyNumbers(str);
    return _.size(cleaned) !== 11 ? '' : cleaned.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  },
  cnpj_cpf: (str) => {
    let cleaned = onlyNumbers(str);
    return _.size(cleaned) === 11 ? formats.cpf(cleaned) : formats.cnpj(cleaned);
  },
  dateTimeZone: (date, mask = 'dd/MM/yyyy') => {
    let value = formats.getDate(date);
    value = isValid(value) ? value : '';
    return (!value) ? '' : format(zonedTimeToUtc(value, 'America/Sao_Paulo'), mask, { locale: ptBR });
  },
  date: (date, mask = 'dd/MM/yyyy') => {
    let value = formats.getDate(date);
    value = isValid(value) ? value : '';

    return (!value) ? '' : format(value, mask, { locale: ptBR });
  },
  getDate: (value) => {
    if (_.isDate(value) && !_.isString(value)) {
      return value;
    }
    return value && _.isString(value) ? parseISO(value) : null;
  },
  fill: (value = '', size = 1, fillString = '') => {
    return String(value).padStart(size, fillString);
  },
  fileSize: (bytes) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  currency: (value = 0, locale = 'pt-BR', currencyCode = 'BRL') => {
    return parseFloat(value).toLocaleString(locale, { style: 'currency', currency: currencyCode });
  },
  percent: (value = 0, locale = 'pt-BR') => {
    return parseFloat(value).toLocaleString(locale, { style: 'percent' });
  },
  decimal: (value = 0, locale = 'pt-BR', places = 2) => {
    return parseFloat(value).toLocaleString(locale, { minimumFractionDigits: places, maximumFractionDigits: places });
  },
  number: (value = 0, locale = 'pt-BR', currencyLocale = 'BRL') => {
    return parseFloat(value).toLocaleString(locale);
  },
};

export const getMonths = function() {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push({
      id: i,
      label: ptBR.localize.month(i),
    });
  }
  return months;
};

export const generateYears = function(left, rigth) {
  const now = new Date().getFullYear(),
    min = now - left,
    max = now + rigth,
    years = [];

  for (let i = min; i <= max; i++) {
    years.push(i);
  }

  return years;
};

export const parseStringToDecimal = (value = '') => {
  let temp = String(value);
  temp = temp
    .replace(/[.]/g, '')
    .replace(/[,]/g, '.')
    .replace(/[\s]/g, '');
  return parseFloat(temp);
};

export const getLastDayOfMonthFromString = (value) => {
  try {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    return formats.date(lastDayOfMonth(date), 'dd/MM/yyyy');
  } catch {
    return '';
  }
};

export const getFirstDayOfMonthFromString = (value) => {
  try {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    return formats.date(startOfMonth(date), 'dd/MM/yyyy');
  } catch {
    return '';
  }
};

export const tryParseStringToDate = (value) => {
  const values = _.isArray(value) ? value : [value];
  const result = _.map(values, (it) => {
    const tmp = parse(it, 'dd/MM/yyyy', new Date());
    return isValid(tmp) ? tmp : null;
  });
  return _.isArray(value) ? result : _.first(result);
};

export const onlyNumbers = (value) => {
  if (!value) {
    return null;
  }
  return value.replace(/[^0-9]/g, '');
};

export const showMessage = (type, payload) => {
  let message = type === 'error' ? getError(payload) : payload;
  toast[type](message);
};

export const getError = (payload) => {
  let message = _.get(payload, 'response.data.message') || _.get(payload, 'error.message') || payload.message;

  if (!message) {
    console.error(payload);
  }
  return message || 'Falha na comunicação com o servidor. Tente novamente!';
};

export const objectToFormData = (data, formData, parentKey) => {
  if (data === null || data === undefined) return null;

  formData = formData || new FormData();

  if (
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) =>
      objectToFormData(
        data[key],
        formData,
        !parentKey
          ? key
          : data[key] instanceof File
          ? parentKey
          : `${parentKey}[${key}]`
      )
    );
  } else {
    formData.append(parentKey, data);
  }

  return formData;
};

const prepareData = (values) => {
  return _.map((values), (r) => ({
      title: _.get(r, 'label'),
      ..._.pick(r, 'path', 'icon', 'id', 'code', 'perms', 'permissions'),
      group: r.group || _.kebabCase(r.path)
    }));
};

export const getSideBarData = (menu, user) => {
  let data = [];

  _.each(_.groupBy(menu, 'group'), (values, title) => {
    if (title === 'undefined') {
      data = data.concat(prepareData(values));
    } else {
      data.push({
        title,
        group: _.kebabCase(title),
        icon: _.get(values, '[0].groupIcon'),
        subNav: prepareData(values)
      });
    }
  });

  if (user) {
    data.unshift({ title: 'Página inicial', icon: 'MdHome', group: 'home', path: '/home' });
    // data.push({ title: 'Ajuda', icon: 'MdHelp', group: 'help', path: '/help' });
  }
  return data;
};
