import styled from 'styled-components';
import { Form } from 'formik';
import { accent, primary, secondary, symbol, tertiary, white } from '~/components/mixins/color';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 5px 10px;
  background: ${primary.hex()};
  border: 2px solid ${tertiary.hex()};
  border-radius: 3px;
  color: ${white.hex()};
  user-select: none;

  &:hover {
    background-color: ${secondary.hex()};
  }

  & > svg {
    color: ${symbol.hex()};
    transition: color 0.2s ease 0s;

    &:hover {
      color: ${accent.hex()};
      cursor: pointer;
    }
  }

  & .column-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 30px;

    .first {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  fieldset {
    width: 100%;
  }
  .files {
    width: 100%;
    display: flex;
    gap: 2px;
    display: flex;
    flex-direction: column;
  }
  .file {
    padding: 5px;
    background: ${secondary.hex()}
  }
`;
