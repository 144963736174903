import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { gray } from '~/components/mixins/color';

const Container = styled.label`
  display: flex;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};

  input[type='checkbox'] {
    margin-right: 5px;
    width: 15px;
    height: 15px;
    display: inline-block;
  }
  input[type='checkbox']:disabled,
  span.disabled {
    color: ${gray.hex()};
  }
`;

const Checkbox = (props) => {
  const [field] = useField(_.pick(props, 'id', 'name'));

  return (
    <Container halfHeight={props.halfHeight} noMargin={props.noMargin}>
      <input
        type="checkbox"
        {...field}
        {..._.pick(props, 'disabled', 'onChange')}
        value={(_.isUndefined(field.value) || _.isNull(field.value)) ? '' : field.value}
        checked={(_.isUndefined(field.value) || _.isNull(field.value)) ? false : field.value}
      />
      {props.label && <span className="disabled">{props.label}</span>}
    </Container>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hasError: PropTypes.bool,
}

export default Checkbox;
