import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { MdCheck, MdKeyboardBackspace } from 'react-icons/md';
import Spinner from '~/components/spinner';
import useDashboard from '~/view-data/use-dashboard';
import useHeader from '~/view-data/use-header';
import ABCBanks from '~/views/dashboard/abc-banks';
import ABCProducts from '~/views/dashboard/abc-products';
import Cards from '~/views/dashboard/cards';
import Evolution from '~/views/dashboard/evolution';
import FormFilter from '~/views/dashboard/filter';
import { Container, FormToolbar } from '~/views/dashboard/styles';
import { IconButton } from '~/components/button';

function Dashboard() {
  const { state, actions } = useDashboard(),
    { state: headerState, actions: headerActions } = useHeader();

  function Filter() {
    return (
      <div className='right'>
        <Formik
          onSubmit={actions.load}
          initialValues={_.get(headerState, 'filter.data') || {}}
          enableReinitialize={true}
          validateOnMount={true}
          >
          {(args) => {
            return (
              <>
                <FormFilter {...args} />
                <FormToolbar>
                  <Spinner visible={state.loading} />
                  <div className='buttons'>
                    <IconButton
                      title='Cancelar'
                      disabled={state.loading}
                      onClick={headerActions.hideFilter}
                      >
                      <MdKeyboardBackspace />
                    </IconButton>
                    <IconButton
                      title='Aplicar Filtro'
                      disabled={state.loading}
                      onClick={args.handleSubmit}
                      >
                      <MdCheck />
                    </IconButton>
                  </div>
                </FormToolbar>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }

  return (
    <Container showForm={_.get(headerState, 'filter.visible')}>
      <div className='left'>
        <Cards />
        <Evolution />
        <ABCBanks />
        <ABCProducts />
      </div>

      <Filter />
    </Container>
  );
}

export default Dashboard;
