import React from 'react';
import _ from 'lodash';
import { MdSave } from 'react-icons/md';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import { ProductForm, ProductSchema } from '~/views/products/form';
import { Container } from '~/views/products/styles';
import CellStatus from '~/components/datatable/cell-status';
import useProducts from '~/view-data/use-products';
import confirm from '~/components/confirm';
import { SubtitleItem, SubtitleBox } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';

const columns = [
  {
    name: '# Nome',
    selector: 'name',
    cell: (row) => {
      return (
        <CellStatus title={row.name} color={row.active ? green.hex() : red.hex()}>
          <strong>{row.name}</strong>
        </CellStatus>
      );
    },
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    hide: 'md',
    width: '140px',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  }
];

function Products({ acls }) {
  const canWrite = acls.includes('W');
  const { state, actions } = useProducts();

  const handleDeleteImage = async (data, filename) => {
    const productId = _.get(data, 'id');
    const productName = _.get(data, 'name') ;

    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja remover a imagem do produto '${productId} - ${productName}' ?`,
    });

    if (result) {
      actions.removeImage(data, filename);
    }
  };

  return (
    <Container>
      <SubtitleBox bottom={35} left={100}>
        <span>Legenda:</span>
        <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
        <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
      </SubtitleBox>

      <Crud
        columns={columns}
        formTitle={(data) => (_.get(data, 'id')) ? `Produto #${data.id}` : `Novo produto`}
        emptyText='Nenhum produto encontrado'
        data={state.data}
        hideAdd={!canWrite}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={actions.pageChange}
        onRowClicked={actions.load}
        formOptions={{
          validationSchema: ProductSchema,
          initialValues: state.model,
        }}
        renderForm={(args) => (
          <ProductForm
            {...args}
            previewMode={!canWrite}
            handleDeleteImage={handleDeleteImage}
            handleListModules={actions.listModules}
          />
        )}
        actions={[
          {
            label: 'Salvar',
            icon: MdSave,
            isSubmit: true,
            isDisabled: ({ isValid }) => !isValid || !canWrite,
            action: actions.createOrUpdate,
          },
        ]}
      />
    </Container>
  );
}

export default Products;
