import styled from 'styled-components';
import { primary, white } from '~/components/mixins/color';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 15px 15px 15px 0;
  background: ${primary.hex()};

  .main {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    height: 100%;
    margin-left: 80px;
    background: ${white.fade(0.88).string()};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 60px);
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  & > div {
    margin-left: 10px;
  }
`;
