import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage } from '~/helper';
import api from '~/services/api';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useGlobalContext from '~/view-data/use-global-context';
import useHeader from '~/view-data/use-header';
import { formats } from '~/helper';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const initialState = {
  loading: false,
  listMode: 'company',
  model: { active: true, partner: {}, services: [], historyEntries: [] },
  isOpenCustomerForm: false,
  selectedCustomer: { identity: '', email: '', name: '', modules: [] },
  isOpenProductForm: false,
  selectedProduct: { identity: '', email: '', name: '', modules: [] }
};

const usePartnerContract = () => {
  const { state: { partnersContract = initialState }, dispatch } = useGlobalContext('partnersContract'),
    { actions: headerActions } = useHeader(),
    history = useHistory(),
    { id } = useParams(),
    query = useQuery(),
    parent = query.get('parent');

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, });
    showMessage('error', error);
  };

  useEffect(() => {
    load(id, parent);

    return () => {
      dispatch({ model: {} });
    };
    // eslint-disable-next-line
  }, [id, parent]);

  const load = async (id, parent) => {
    try {
      dispatch({ formLoading: true });
      let response = await api.get('partners/contract/load', {
          params: { id, parent }
        }),
        model = response.data;

      dispatch({
        model,
        formLoading: false,
        isOpenCustomerForm: false,
        isOpenProductForm: false,
        listMode: 'company'
      });
    } catch (error) {
      failure(error);
    }
  };

  useEffect(() => {
    headerActions.configure({
      title: (id === 'new') ? `Novo Contrato de aditamento` : `Contrato de aditamento (#${id})`,
      count: 'none',
      loading: _.get(partnersContract, 'loading', false),
      onSearch: null
    });
    // eslint-disable-next-line
  }, [partnersContract]);

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      const params = _.omit(model, 'historyEntries', 'allProducts');
      await api[method](`partners/contract/${path}`, params);

      showMessage('success', `Contrato ${editing ? 'atualizado' : 'criado'} com sucesso`);
      history.push(`/partners`);
      actions.toogleForm && actions.toogleForm();
    } catch (error) {
      failure(error);
    } finally {
      dispatch({ formLoading: false });
    }
  };

  const listPartners = async (term, callback) => {
    try {
      const response = await api.get(`partners/contract/list-partners`, {
        params: { term }
      });
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  const onSearchIdentify = async (identify, check = false, moduleData, callback) => {
    try {
      dispatch({ formLoading: true });
      const params = { cnpj: identify, check };
      const { data } = await api.get(`partners/cnpj-search`, { params });
      const exists = _.find(_.get(partnersContract, 'model.services'), (f) => {
          return f.identify === identify && (!moduleData ||_.find(f.modules, _.pick(moduleData, 'moduleId', 'groupId')));
        });

      if (exists) {
        selectCustomer({});
        throw new Error('Esse CNPJ já está sendo utilizado nesse contrato');
      }
      if (!callback) {
        selectCustomer({ ...data, identify });
      } else {
        callback(data);
      }
    } catch (error) {
      selectCustomer({
        identifyType: _.size(identify) === 14 ? 'company' : 'person',
        identify: null
      });
      failure(error);
    } finally {
      dispatch({ formLoading: false });
    }
  };

  const showCustomerForm = (status = false) => {
    dispatch({ isOpenCustomerForm: status });
  };

  const selectCustomer = (val) => {
    dispatch({
      selectedCustomer: {
        identify: '',
        email: '',
        name: '',
        modules: [],
        ...val
      }
    });
  };

  const changeServices = modelForm => {
    dispatch({ model: { ...partnersContract.model, ...modelForm } });
  };

  const removeCustomer = (modelForm, data) => {
    const services = _.filter(
      partnersContract.model.services,
      f => f.identity !== data.identity
    );
    dispatch({ model: { ...partnersContract.model, ...modelForm, services } });
  };

  const showProductForm = (status = false) => {
    dispatch({ isOpenProductForm: status });
  };

  const changeListMode = listMode => {
    dispatch({ listMode });
  };

  const selectProduct = val => {
    dispatch({
      selectedProduct: {
        identity: '',
        email: '',
        name: '',
        modules: [],
        ...val
      }
    });
  };

  const removeProduct = (modelForm, data) => {
    const services = _.filter(
      partnersContract.model.services,
      f => f.identity !== data.identity
    );
    dispatch({ model: { ...partnersContract.model, ...modelForm, services } });
  };

  const signContract = async data => {
    try {
      dispatch({ formLoading: true });
      const { id, signed, contractId } = data;
      await api.post(`contracts/sign`, { id, signed: !signed });
      dispatch({ formLoading: false });
      await load(contractId);
    } catch (error) {
      failure(error);
    }
  };

  const downloadContract = async data => {
    try {
      dispatch({ formLoading: true });
      const response = await api.get(`partners/contract/download`, {
        params: data,
        responseType: 'blob'
      });
      const filename = `contract-${formats.date(new Date())}.pdf`;
      api.download(response, filename);
      dispatch({ formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...partnersContract
    },
    actions: {
      createOrUpdate,
      listPartners,
      onSearchIdentify,
      showCustomerForm,
      changeServices,
      selectCustomer,
      signContract,
      downloadContract,
      removeCustomer,
      showProductForm,
      selectProduct,
      removeProduct,
      changeListMode
    }
  };
};

export default usePartnerContract;
