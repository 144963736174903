import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-gap: ${(props) => props.gap || '10px'};
  grid-template-columns: repeat(${(props) => props.span || 1}, 1fr);
`;

export const FlexRow = styled.div`
  display: flex;
  gap: ${(props) => props.gap || '10px'};
  width: 100%;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;

export const FlexItem = styled.div`
  display: flex;
  flex: ${(props) => props.flex || '1'};
  align-items: ${(props) => props.align || 'flex-start'};
  justify-content: ${(props) => props.justify || 'flex-start'};
`;
