import styled from 'styled-components';
import { Form } from 'formik';
import { lightGray, secondary, tertiary } from '~/components/mixins/color';

export const Container = styled.div`
  height: 100%;
  max-height: ${props => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;

  @media (max-width: 425px) {
    max-height: 100%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: 100%;
  background: ${tertiary.hex()};
  color: ${lightGray.hex()};
  position: relative;
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  height: 56px;
  padding: 0 15px;
  color: ${lightGray.hex()};
  border-bottom: 1px solid ${secondary.hex()};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: ${props => (props.visible ? 'grid' : 'none')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: 0.1s;
  z-index: 3;
`;

export const FormToolbar = styled.div`
  display: flex;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid ${secondary.hex()};

  .buttons {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const BilletsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 145px);
`;
