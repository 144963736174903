import React from 'react';
import _ from 'lodash';
import Modal from '~/components/modal';
import DataTable from '~/components/datatable';
import { formats } from '~/helper';
import { licenseModesMap } from '~/constants';
import { ModalContainer, CompaniesContainer } from '~/views/licenses/styles';

const columns = [
  {
    name: 'CNPJ/CPF',
    selector: 'identify',
    width: '160px',
    format: (row) => formats.cnpj_cpf(_.get(row, 'identify'))
  },
  {
    name: 'Nome',
    selector: 'name',
    format: (row) => _.get(row, 'name')
  },
  {
    name: 'Data de Início',
    selector: 'startDate',
    width: '120px',
    format: (row) => formats.dateTimeZone(_.get(row, 'startDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Data de Término',
    selector: 'endDate',
    width: '120px',
    format: (row) => formats.dateTimeZone(_.get(row, 'endDate'), 'dd/MM/yyyy'),
  },
  {
    name: 'Tipo de licença',
    selector: 'licenseMode',
    width: '120px',
    center: true,
    format: (row) => licenseModesMap[row.licenseMode] || row.licenseMode
  },
];

function CompaniesModal({ data, isOpen, title, closeModal }) {

  let companies = _.get(data, 'companies') || [];

  return (
    <Modal
      width="70%"
      height="80%"
      hideClose={true}
      open={isOpen}
      hide={closeModal}
      title={title}
      actions={[
        {
          label: 'Fechar',
          action: () => { closeModal() }
        }
      ]}
      >
      <ModalContainer>
        <CompaniesContainer>
          <DataTable
            columns={columns}
            data={{ rows: companies }}
            noPagination={true}
            emptyText="Nenhum cliente encontrado"
            keyField="identify"
            extraOptions={{
              ignoreRowClicked: true,
              selectableRowsHighlight: false,
              selectableRows: false
            }}
            />
        </CompaniesContainer>
      </ModalContainer>
    </Modal>
  );
}

export default CompaniesModal;
