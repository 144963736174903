import React from 'react';
import _ from 'lodash';
import Modal from '~/components/modal';
import DataTable from '~/components/datatable';
import { ModalContainer, EnviromentsContainer, TipContainer } from '~/views/licenses/styles';

const columns = [
  {
    name: 'Server name',
    selector: 'data',
    format: (row) => _.get(row, 'data.serverName')
  },
  {
    name: 'Company DB',
    selector: 'data',
    format: (row) => _.get(row, 'data.companyDB')
  },
  {
    name: 'Company name',
    selector: 'data',
    format: (row) => _.get(row, 'data.companyName')
  },
  {
    name: 'System number',
    selector: 'data',
    width: '200px',
    format: (row) => _.get(row, 'systemNumber')
  },
  {
    name: 'Instalation number',
    selector: 'instalationNumber',
    width: '150px',
    format: (row) => _.get(row, 'instalationNumber')
  },
  {
    name: 'Is hosted',
    selector: 'isHosted',
    width: '100px',
    center: true,
    format: (row) => _.get(row, 'isHosted') ? 'Sim' : 'Não'
  }
];

function EnvironmentSetupModal({ environments, selectedEnvironment, changeSelectedEnvironment, data, isOpen, loading, title, closeModal, handleOnSubmit }) {

  return (
    <Modal
      width="70%"
      height="80%"
      hideClose={true}
      open={isOpen}
      hide={closeModal}
      title={title}
      actions={[
        {
          label: 'Cancelar',
          action: () => { closeModal() },
          disabled: loading
        },
        {
          label: 'Salvar',
          action: () => handleOnSubmit({ contractId: data.contractId, environment: selectedEnvironment, all: _.map(environments, 'id') }),
          primary: true,
          disabled: loading
        }
      ]}
      >
      <ModalContainer>
        <TipContainer>
          Para o ambiente aparecer na listagem abaixo é necessário que o addon esteja instalado e tenha sido executado.
        </TipContainer>

        <EnviromentsContainer>
          <DataTable
            columns={columns}
            data={{ rows: environments }}
            noPagination={true}
            emptyText="Nenhum ambiente encontrado"
            keyField="id"
            extraOptions={{
              ignoreRowClicked: false,
              selectableRowsHighlight: false,
              selectableRows: true,
              selectableRowsNoSelectAll: true,
              onRowClicked: (row) => changeSelectedEnvironment(_.get(row, 'id')),
              selectableRowSelected: (row) => {
                return (selectedEnvironment && selectedEnvironment === _.get(row, 'id'));
              },
              onSelectedRowsChange: ({ selectedRows }) => changeSelectedEnvironment(_.get(selectedRows, '[0].id'))
            }}
            />
        </EnviromentsContainer>
      </ModalContainer>
    </Modal>
  );
}

export default EnvironmentSetupModal;
