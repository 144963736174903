import styled, { css } from 'styled-components';
import { accent, quaternary, secondary, symbol, white } from '~/components/mixins/color';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  .left {
    display: flex;
    flex: 1;
    width: calc(100% - ${(props) => props.rightWidth || '35%'} - 40px);
  }
  .right {
    display: flex;
    position: relative;
    flex: 0 0 ${(props) => props.rightWidth || '35%'};
    flex-direction: column;
    justify-content: space-between;
    background-color: ${quaternary.hex()};
    width: 100%;
    margin-left: 10px;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 1s ease-out;

    .form-contents {
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%;
      height: calc(100% - 100px);
      flex-direction: column;
      justify-content: space-between;
    }
  }
  ${(props) =>
    props.showForm &&
    css`
      .right {
        opacity: 1;
      }
      @media (max-width: 1024px) {
        .left {
          display: none;
          visibility: hidden;
        }
        .right {
          flex: 1;
          margin-left: 0px;
        }
      }
    `}
`;

export const FormToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  height: 56px;
  border-top: 1px solid ${secondary.hex()};
  padding: 10px;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  height: 50px;
  padding: 0 10px;
  color: ${white.hex()};
  border-bottom: 1px solid ${secondary.hex()};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const IconButton = styled.button`
  width: ${(props) => props.size || '36'}px;
  height: ${(props) => props.size || '36'}px;
  display: flex;
  margin: ${(props) => props.margin || '5px 0px 5px 5px'};
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding || '5px'};
  border-radius: 3px;
  font-size: ${(props) => props.fontSize || '20px'};

  background: transparent;
  border: 1px solid ${symbol.hex()};
  color: ${(props) => props.color || white.hex()};

  & svg path {
    stroke: ${white.hex()};
  }
  &:hover:enabled,
  &:focus:enabled {
    color: ${(props) => props.color || white.hex()};
    background: ${(props) => props.color ? white.hex() : accent.hex()};
    & svg path {
      stroke: ${(props) => props.color || white.hex()};
    }
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
      user-select: none;
      font-weight: normal;
    `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100%);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`;
