import React from 'react';
import { MdKeyboardBackspace, MdSave } from 'react-icons/md';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Row } from '~/components/layout';
import { Container, FormContainer, Overlay, FormToolbar } from '~/views/contract-models/styles';
import { IconButton } from '~/components/button';
import { RichEditor, InputGroup, Select } from '~/components/form';
import useContractModel from '~/view-data/use-contract-model';

const variables = [
  { id: '', label: 'Variaveis' },
  { id: 'currentDate', label: 'Data atual' },
  { id: 'contractNum', label: 'Número do contrato' },
  { id: 'startDate', label: 'Data de início do contrato' },
  { id: 'value', label: 'Valor do contrato' },
  { id: 'valueText', label: 'Valor do contrato por extenso' },
  { id: 'partner.identity', label: 'CNPJ do Parceiro' },
  { id: 'partner.name', label: 'Razão Social do Parceiro' },
  { id: 'partner.tradeName', label: 'Nome Fantasia do Parceiro', },
  { id: 'partner.address', label: 'Endereço do Parceiro' },
  { id: 'modules', label: 'Lista de Módulos' },
  { id: 'productDetails', label: 'Detalhes dos produtos' },
  { id: 'billingStartDate', label: 'Data de início do faturamento' },
  { id: 'nextBillingDate', label: 'Data do próximo faturamento' },
  { id: 'skillSignature', label: 'Assinaturas da Skill' },
  { id: 'partnerSignature', label: 'Assinaturas do Parceiro' },
  { id: 'witnessSignature', label: 'Assinaturas das Testemunhas' },
];

const ContractModelSchema = Yup.object().shape({
  name: Yup.string().required('É necessário informar o nome do modelo'),
  contents: Yup.string().required('É necessário informar o conteúdo do modelo'),
});

function ContractModelForm({ match, history, acls }) {
  const canWrite = acls.includes('W');
  const { state, actions } = useContractModel();

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={actions.createOrUpdate}
        initialValues={state.model || {}}
        validationSchema={ContractModelSchema}
      >
        {({ errors, isValid, touched, isSubmitting, values }) => (
          <FormContainer>
            <Overlay visible={isSubmitting || state.loading} />

            <Row span={3}>
              <InputGroup
                type='text'
                name='name'
                label='Nome'
                disabled={!canWrite}
                hasError={errors.name && touched.name}
              />

              <InputGroup
                type='text'
                name='title'
                label='Título'
                disabled={!canWrite}
                hasError={errors.title && touched.title}
              />

              <Select
                name='contractType'
                label='Tipo'
                options={{
                  values: [
                    { value: ', label: ' },
                    { value: 'contract', label: 'Contrato mãe' },
                    { value: 'addition', label: 'Contrato de aditamento' },
                    { value: 'additive', label: 'Aditivo' },
                    { value: 'cancellation', label: 'Distrato' },
                  ],
                }}
              />
            </Row>

            <RichEditor
              name='contents'
              placeholder='Conteúdo'
              disabled={!canWrite}
              height={window.innerHeight - 285}
              variables={variables}
            />

            <FormToolbar>
              <div className='buttons'>
                <IconButton
                  title='Cancelar'
                  disabled={isSubmitting}
                  onClick={() => history.push('/contract-models')}
                >
                  <MdKeyboardBackspace />
                </IconButton>
                <IconButton
                  type='submit'
                  title='Salvar'
                  disabled={!canWrite || !isValid || isSubmitting}
                >
                  <MdSave />
                </IconButton>
              </div>
            </FormToolbar>
          </FormContainer>
        )}
      </Formik>
    </Container>
  );
}

export default ContractModelForm;
