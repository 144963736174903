import React, { useState } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import { FaSearch } from 'react-icons/fa';
import { components } from 'react-select';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { accent, primary, symbol, tertiary, white } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  flex-direction: column;
  user-select: none;

  > div {
    width: 100%;
  }
`;

const Group = styled.div`
  display: grid;
  position: relative;
  width: 100%;

  & > label {
    font-size: 70%;
    transform: translate3d(0, -100%, 0);
    color: ${white.hex()};
    position: absolute;
    top: 15px;
    padding-left: 10px;
    transition: all 200ms;
    opacity: 0.75;
    font-weight: ${(props) => props.required ? '800' : '400'};
  }
  #react-select-2-input {
    color: ${white.hex()} !important;
  }
`;

const parseValue = (props, row) => {
  if (!row) {
    return {};
  }
  return {
    ...row,
    value: _.get(row, props.keyField),
    label: props.valueFormat(row),
  };
};

const handleOnChange = ((props, form, field) => (value) => {
  if (props.onChange) {
    props.onChange(value);
  } else {
    form.setFieldValue(field.name, value);
  }
});

const customStyles = ({ hasError }) => ({
  control: (base, { isFocused, isSelected, isDisabled }) => ({
    ...base,
    transition: 'border-color 0.2s ease-in 0s',
    border: `2px solid ${(hasError) ? 'red' : ((isFocused || isSelected) && !isDisabled ? accent.hex() : tertiary.hex())}`,
    boxShadow: 'none',
    height: '46px',
    background: !isDisabled ? primary.hex() : primary.lighten(0.8).hex(),
    color: white.hex(),
    opacity: isDisabled ? 0.75 : 1,
    borderRadius: '3px',
    '&:focus': {
      borderColor: accent.hex(),
      color: white.hex(),
    },
    '&:hover': {
      borderColor: accent.hex(),
    },
  }),
  input: (base) => ({
    ...base,
    color: white.hex()
  }),
  singleValue: (base) => ({
    ...base,
    color: white.hex(),
    paddingTop: '8px',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: '0 !important',
    width: '2px',
    backgroundColor: primary.hex(),
  }),
  clearIndicator: (base, { isFocused, isSelected }) => ({
    ...base,
    color: isFocused || isSelected ? tertiary.hex() : white.hex(),
  }),
  menuPortal: (base, state) => ({
    ...base,
    zIndex: '5'
  }),
  menu: (base, state) => ({
    ...base,
    color: white.hex(),
    borderRadius: '3px',
    marginTop: '3px',
    borderColor: symbol.hex(),
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    color: white.hex(),
    backgroundColor: primary.hex(),
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isFocused || isSelected ? primary.lighten(0.5).hex() : 'transparent',
    color: isFocused || isSelected ? white.hex() : symbol.hex(),
    '&:hover': {
      background: primary.fade(0.25).string()
    }
  }),
});

const noOptionsMessage = (props) => (elem) => {
  let length = _.size(_.get(elem, 'inputValue'));
  return (length === 0) ? props.tipText : props.notFoundText;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch style={{ color: props.isFocused ? accent.hex() : white.hex() }} />
    </components.DropdownIndicator>
  );
};

export default function LazyLoadAsyncSelect(props) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const componentsDef = { DropdownIndicator };

  const _onChange = ({ form, field }) => (value, { action }) => {
    /*
      action:
      select-option
      clear
      deselect-option
      remove-value
      pop-value
      set-value
      create-option
    */
    if (/^(select-option|clear)$/.test(action)) {
      handleOnChange(props, form, field)(value || null);
    }
  };

  const _onInputChange = ({ form, field }) => (value, { action }) => {
    /*
      action: input-change | input-blur | set-value | menu-close
    */
    if (action === 'input-change') {
      getOptionsAsyncFn(null, value);
    }
  };

  function getOptionsAsync(event, term) {
    // this is where you would hit your API async instead! I've added a timeout for demo purposes and some sample data.
    setIsLoading(true);

    new Promise((resolve) => {
        props.loadData(term || '', (data) => {
          resolve(data || []);
        });
      })
      .then((response) => {
        setOptions(response.map((row) => parseValue(props, row)));
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const getOptionsAsyncFn = _.debounce(getOptionsAsync, 500);
  let baseValue = props.value;

  if (!props.multiple) {
    if (_.isArray(props.value)) {
      baseValue = _.map(props.value, (r) => parseValue(props, r));
    } else {
      baseValue = parseValue(props, props.value);
    }
  }

  if (_.isEmpty(props.value)) {
    baseValue = undefined;
  }

  if (props.optionsFormat) {
    componentsDef.Option = (opts) => {
      return (
        <components.Option {...opts}>
          {props.optionsFormat(_.find(opts.options, { [props.keyField]: opts.value }))}
        </components.Option>
      );
    };
  }

  return (
    <Container noMargin={props.noMargin}>
      <Field id={props.id} name={props.name}>
        {({ field, form }) => (
          <Group required={props.required}>
            <ReactSelect
              name={props.name}
              value={baseValue || ''}
              options={options}
              onFocus={getOptionsAsyncFn}
              onChange={_onChange({ form, field })}
              onInputChange={_onInputChange({ form, field })}
              isLoading={isLoading}
              isMulti={props.multiple}
              menuPortalTarget={document.body}
              isDisabled={props.disabled}
              isClearable={props.clearable}
              noOptionsMessage={noOptionsMessage(props)}
              placeholder={props.required ? `${props.label} (*)` : props.emptyText}
              loadingMessage={() => props.loadingText}
              styles={customStyles(props)}
              components={componentsDef}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary50: primary.fade(0.5).string(),
                },
              })}
            />
            {baseValue && <label htmlFor={props.name}>{props.label}{props.required ? ' (*)' : ''}</label>}
          </Group>
        )}
      </Field>
    </Container>
  )
}
