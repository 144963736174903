import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  term: null,
  offset: 0,
  model: null,
  data: { rows: [], count: 0, offset: 0 },
};

const useUsers = () => {
  const { state: { users = initialState }, dispatch } = useGlobalContext('users'),
    { actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: 'users/list',
    params: { term: users.term, offset: users.offset },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Usuários',
      count: _.get(data, 'count', 0),
      loading: _.get(users, 'loading', false),
      onSearch: (text) => list(0, text),
    });
    // eslint-disable-next-line
  }, [data, users]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true });
      let model = { active: true };

      if (data.id) {
        const response = await api.get('users/load', { params: { id: data.id } });
        model = response.data;
      }
      dispatch({ model, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`users/${path}`, model);
      mutate(data);
      showMessage('success', `Usuário ${editing ? 'atualizado' : 'criado'} com sucesso`);
      actions.toogleForm && actions.toogleForm();
      dispatch({ formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const resetPassword = async (data, actions) => {
    try {
      await api.put(`users/reset-password`, data);
      showMessage('success', `Senha redefinida com sucesso`);
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      failure(error);
    }
  };

  const listProfiles = async (term, callback) => {
    try {
      const response = await api.get(`users/list-profiles`, { params: { term } });
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...users,
      data: data || {},
      loading: !data,
    },
    actions: {
      pageChange,
      load,
      list,
      createOrUpdate,
      resetPassword,
      listProfiles,
    },
  };
};

export default useUsers;
