import React from 'react';
import { MdKeyboardBackspace, MdSave } from 'react-icons/md';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { Container, FormContainer, Overlay, FormToolbar } from '~/views/billings/styles';
import { IconButton } from '~/components/button';
import Tabs from '~/components/tabs';
import TabBillings from '~/views/billings/tab-billings';
import TabContracts from '~/views/billings/tab-contracts';
import TabSummary from '~/views/billings/tab-summary';
import useBillingWizard from '~/view-data/use-billing-wizard';

const BillingSchema = Yup.object().shape({
  name: Yup.string().required('É necessário informar o nome'),
  contents: Yup.string().required('É necessário informar o conteúdo')
});

function BillingWizard({ match, history, acls }) {
  const canWrite = acls.includes('W');
  const { state, actions } = useBillingWizard();

  const tabs = [
    { id: 'history', label: 'Contratos', component: TabContracts },
    { id: 'general', label: 'Faturas', component: TabBillings },
    { id: 'history', label: 'Resumo', component: TabSummary },
  ];

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={actions.createOrUpdate}
        initialValues={state.model}
        validationSchema={BillingSchema}
        >
        {(formArgs) => (
          <FormContainer>
            <pre>{JSON.stringify(state, null, 4)}</pre>
            <pre>{JSON.stringify(formArgs.isSubmitting, null, 4)}</pre>

            <Overlay visible={formArgs.isSubmitting || state.loading} />

            <Tabs
              initialTab='general'
              height="calc(100vh - 209px)"
              tabs={tabs}
              disabled={!canWrite}
              onListPartners={actions.listPartners}
              {...formArgs}
              />

            <FormToolbar>
              <div className='buttons'>
                <IconButton title="Cancelar" disabled={formArgs.isSubmitting} onClick={() => history.push('/billings')}>
                  <MdKeyboardBackspace />
                </IconButton>
                <IconButton
                  type="submit"
                  title="Salvar"
                  disabled={!formArgs.isValid || formArgs.isSubmitting}>
                  <MdSave />
                </IconButton>
              </div>
            </FormToolbar>
          </FormContainer>
        )}
      </Formik>
    </Container>
  );
}

export default BillingWizard;

