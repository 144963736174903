import React from 'react';
import Fieldset from '~/components/fieldset';
import { InputGroup, InputMask } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { tryParseStringToDate , getLastDayOfMonthFromString , getFirstDayOfMonthFromString } from '~/helper';
import { MASK_DATE } from '~/constants';

export default function FormFilter(props) {

  function validateDate(otherField, isEnd) {
    return (event) => {
      const fn = isEnd ? getFirstDayOfMonthFromString : getLastDayOfMonthFromString,
        value = tryParseStringToDate(event.target.value) ? event.target.value : '';

      props.setFieldValue(event.target.name, value);

      if (!props.values[otherField] || props.values[otherField].length === 0) {
        props.setFieldValue(otherField, fn(value));
      }
    }
  };

  return (
    <FilterContainer>
      <InputGroup
        type="text"
        name="name"
        label="Nome"
        />

      <Fieldset label="Criação">
        <InputMask
          mask={MASK_DATE}
          name="startDate"
          label="Data inicial"
          onBlur={validateDate('endDate')}
          />

        <InputMask
          mask={MASK_DATE}
          name="endDate"
          label="Data final"
          onBlur={validateDate('startDate', true)}
        />
    </Fieldset>
    </FilterContainer>
  );
}
