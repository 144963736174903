import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { link, primary } from '~/components/mixins/color';
import styled from 'styled-components';
import Cropper from 'react-avatar-edit';

const FileContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  border: 3px dashed rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  background: ${primary.hex()};
  position: relative;

  & > div {
    height: 100%;
    width: 100%;
  }
  & > div > div {
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      color: ${link.hex()} !important;
      font-size: 16px;
      height: 100%;
      width: 100%;
    }
  }
`;

const fileFromBase64 = async (imageB64) => {
  const resp = await fetch(imageB64);
  const blob = await resp.blob();
  return new File([blob], `image.jpg`);
};

export const FileImageCrop = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const onCrop = async (data) => {
    setFieldValue(field.name, await fileFromBase64(data));
  };

  return (
    <FileContainer size={props.size}>
      <Cropper
        label={props.label}
        width={props.size}
        height={props.size}
        imageWidth={props.size}
        cropRadius={props.size / 4}
        exportSize={props.size}
        exportMimeType="image/jpg"
        exportQuality={1}
        onCrop={onCrop}
        exportAsSquare={true}
        />
    </FileContainer>
  );
};

FileImageCrop.propTypes = {
  size: PropTypes.number.isRequired
};

FileImageCrop.defaultProps = {
  height: 200
};

export default FileImageCrop;
