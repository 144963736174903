import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Autocomplete, CurrencyField } from '~/components/form';
import { Item, TabContainer } from '~/views/products/styles';
import { Row } from '~/components/layout';
import { IconButton } from '~/components/button';
import _ from 'lodash';

const Modules = ({ previewMode, handleListModules, handleRemoveModule, handleAddModule, setFieldValue, values }) => {
  function onAddModule(value) {
    handleAddModule(value);
    setFieldValue('module', null);
  };

  return (
    <TabContainer>
      <Row>
        <Autocomplete
          name='module'
          label='Módulo'
          keyField='id'
          disabled={previewMode}
          preload={true}
          keyApp={`${_.get(values, 'code')}-`}
          value={values.module}
          valueFormat={(row) => `${row.name} (${row.code})`}
          loadData={handleListModules}
          emptyText={'Pesquise um módulo'}
          tipText={'Digite... '}
          loadingText={'Carregando...'}
          notFoundText={'Não encontrado'}
          onChange={(val) => val && onAddModule({ ...val, value: 0, isNew: true }) }
          />
      </Row>

      {_.map(_.get(values, 'modules') || [], (row, index) => (
        <Item key={index}>
          <div className='text'>
            <span>{row.name}</span>
            <strong>{row.code || index}</strong>
          </div>

          <div className='value'>
            <CurrencyField
              noMargin={true}
              name={`modules[${index}].value`}
              label="Valor (R$)"
              />
          </div>

          {row.isNew && (
            <div className='action'>
              <IconButton size={42} onClick={() => handleRemoveModule(index)}>
                <MdDelete />
              </IconButton>
            </div>
          )}
        </Item>
      ))}
    </TabContainer>
  );
};

export default Modules;
