import { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { showMessage } from '~/helper';
import api from '~/services/api';
import { useHistory } from 'react-router-dom';
import useGlobalContext from '~/view-data/use-global-context';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  model: {},
};

const useBillingWizard = () => {
  const { state: { billingWizard = initialState }, dispatch } = useGlobalContext('billingWizard'),
    { actions: headerActions } = useHeader(),
    history = useHistory();

  useEffect(() => {
    headerActions.configure({
      title: `Assistente para criação de Faturas`,
      count: 'none',
      loading: _.get(billingWizard, 'loading', false),
    });
    // eslint-disable-next-line
  }, [billingWizard]);

  // useEffect(() => {
  //   load();
  // }, []);

  const failure = (error) => {
    dispatch({ loading: false });
    showMessage('error', error);
  };

  const load = useCallback(async () => {
    const response = await api.get('billings/wizard'),
      model = response.data;

    dispatch({ model });
  }, [dispatch]);

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ loading: true });
      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';
      await api[method](`billings/${path}`, model);
      showMessage('success', `Contrato ${editing ? 'atualizado' : 'criado'} com sucesso`);
      history.push(`/billings`);
      actions.toogleForm && actions.toogleForm();
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...billingWizard,
    },
    actions: {
      load,
      createOrUpdate,
    },
  };
};

export default useBillingWizard;
