import React from 'react';
import Fieldset from '~/components/fieldset';
import { Autocomplete, InputGroup, Select } from '~/components/form';
import { getMonths, generateYears, formats } from '~/helper';
import _ from 'lodash';

export default function FormFilter(props) {

  return (
    <div>
      <InputGroup
        type="text"
        name="id"
        label="Número da fatura"
        />

      <Autocomplete
        name='partner'
        keyField='id'
        label='Parceiro'
        preload={true}
        value={_.get(props, 'values.partner')}
        valueFormat={(row) => `${formats.cnpj_cpf(row.identity)} - ${row.name}`}
        loadData={props.onListPartners}
        emptyText={'Pesquise um parceiro'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        />

      <Select
        name="status"
        label="Situação"
        options={
          {
            values: [
              { value: 'open', label: 'Aberto' },
              { value: 'paid', label: 'Pago' },
              { value: 'delayed', label: 'Atrasado' },
            ]
          }
        }
      />
      <Fieldset label="Período">
        <Select
          name="month"
          label="Mês"
          options={
            {
              values: getMonths().map(row => ({value: String(row.id), label: row.label}))
            }
          }
        />
        <Select
          name="year"
          label="Ano"
          options={
            {
              values: generateYears(5, 3).map(year => ({value: String(year), label: String(year)}))
            }
          }
        />
      </Fieldset>
    </div>
  );
}
