import React from 'react';
import { InputGroup, Select } from '~/components/form';
import { FilterContainer } from '~/components/crud/styles';
import { Row } from '~/components/layout';
import { licenseTypes, modulePlatforms } from '~/constants';

export default function FormFilter(props) {

  return (
    <FilterContainer>
      <Row span={1}>
        <Select
          name="active"
          label="Ativo"
          options={{
            values: [
              { value: '', label: 'Todos' },
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' }
            ],
          }}
          />
      </Row>

      <InputGroup
        type="text"
        name="name"
        label="Nome"
        />

      <Row span={2}>
        <InputGroup
          type="text"
          name="code"
          label="Código"
          />

        <InputGroup
          type="text"
          name="version"
          label="Versão"
          />
      </Row>

      <Row span={2}>
        <Select
          name='licenseType'
          label='Tipo de licença'
          options={{
            values: [
              { value: '', label: 'Todos' },
              ...licenseTypes
            ],
          }}
          />

        <Select
          name='platform'
          label='Plataforma'
          options={{
            values: [
              { value: '', label: 'Todas' },
              ...modulePlatforms
            ],
          }}
          />
      </Row>
    </FilterContainer>
  );
}
