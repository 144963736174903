import _ from 'lodash';
import React from 'react';
import { MdSave, MdClear, MdCheck } from 'react-icons/md';
import Crud from '~/components/crud';
import { formats } from '~/helper';
import useModules from '~/view-data/use-modules';
import useHeader from '~/view-data/use-header';
import FormFilter from '~/views/modules/filter';
import { ModuleForm, ModuleSchema } from '~/views/modules/form';
import { Container } from '~/views/modules/styles';
import CellStatus from '~/components/datatable/cell-status';
import { SubtitleItem, SubtitleBox } from '~/components/datatable/subtitle';
import { red, green } from '~/components/mixins/color';
import { licenseTypes } from '~/constants';

const columns = [
  {
    name: 'Nome',
    selector: 'name',
    cell: row => {
      return (
        <CellStatus
          title={row.name}
          color={row.active ? green.hex() : red.hex()}
        >
          <strong>{row.name}</strong>
        </CellStatus>
      );
    }
  },
  {
    name: 'Código',
    selector: 'code',
    width: '200px',
    format: (row) => _.get(row, 'code')
  },
  {
    name: 'Versão',
    selector: 'version',
    width: '120px',
    center: true,
    format: (row) => _.get(row, 'version')
  },
  {
    name: 'Licença',
    selector: 'licenseType',
    center: true,
    width: '100px',
     hide: 'md',
    format: (row) => _.get(_.find(licenseTypes, { value: _.get(row, 'licenseType') }), 'label')
  },
  {
    name: 'Autor',
    selector: 'user',
     hide: 'md',
    format: (row) => _.get(row, 'user.name')
  },
  {
    name: 'Cadastrado em',
    selector: 'createdAt',
    width: '140px',
    hide: 'md',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm')
  }
];

const includeDefaultValues = val => {
  let values = val || {};
  return {
    ...values,
    licenseType: values.licenseType || '',
    platform: values.platform || ''
  };
};

function Module({ acls }) {
  const { actions, state } = useModules();
  const { state: headerState } = useHeader();
  const canWrite = acls.includes('W');
  const previewMode = !canWrite;
  const showFilter = _.get(headerState, 'filter.visible');
  const rightOpen = state.showForm || showFilter;

  let formActions = [
      {
        label: 'Salvar',
        icon: MdSave,
        isSubmit: true,
        isDisabled: ({ isValid }) => !isValid || !canWrite,
        action: actions.createOrUpdate
      }
    ],
    filterActions = [
      {
        label: 'Limpar Filtro',
        icon: MdClear,
        action: () => {
          actions.changeFilter({ active: true });
        }
      },
      { label: 'Aplicar Filtro', icon: MdCheck, action: actions.changeFilter }
    ];

  return (
    <Container>
      <SubtitleBox bottom={35} left={100}>
        <span>Legenda:</span>
        <SubtitleItem color={green.hex()}>Ativo</SubtitleItem>
        <SubtitleItem color={red.hex()}>Inativo</SubtitleItem>
      </SubtitleBox>

      <Crud
        openForm={rightOpen}
        useOpenForm={true}
        columns={columns}
        formTitle={data => {
          if (showFilter) {
            return 'Filtro';
          }
          return _.get(data, 'id') ? `Módulo #${data.id} - ${data.version}` : `Novo módulo`;
        }}
        emptyText="Nenhum módulo encontrado"
        data={state.data}
        hideAdd={!canWrite}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onCloseFilter={actions.hideForm}
        onChangePage={actions.pageChange}
        rightWidth={showFilter ? '500px' : '800px'}
        onRowClicked={actions.load}
        formOptions={{
          validationSchema: showFilter ? undefined : ModuleSchema,
          initialTouched: showFilter ? {} : { name: true },
          initialValues: (showFilter ? _.get(headerState, 'filter.data') : includeDefaultValues(state.model)) || {}
        }}
        renderForm={args =>
          showFilter ?
            <FormFilter {...args} />
            :
            <ModuleForm
              previewMode={previewMode}
              loading={state.formLoading}
              erpVersions={state.erpVersions}
              allErpVersions={state.allErpVersions}
              handleListModules={actions.listModules(_.get(args, 'values.code'))}
              {...args}
            />        }
        actions={!showFilter ? (previewMode ? [] : formActions) : filterActions}
      />
    </Container>
  );
}

export default Module;
