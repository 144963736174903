import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { gray, primary, tertiary, white } from '~/components/mixins/color';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Group = styled.div`
  display: grid;
  position: relative;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};
  width: 100%;

  input {
    background: ${primary.hex()};
    border: 2px solid ${tertiary.hex()};
    border-radius: 3px;
    border-radius: 3px;
    height: 46px;
    padding: 19px 40px 6px 10px;
    color: ${gray.hex()};
    position: relative;
    transition: border-color 0.2s ease-in 0s;
    min-width: 50px;

    ${(props) => props.disabled && css`
      background-color: ${primary.lighten(0.8).hex()} !important;
      color: ${gray.hex()} !important;
    `}

    & + label {
      font-size: 70%;
      transform: translate3d(0, -100%, 0);
      opacity: 1;
      top: 20px;
      color: ${white.hex()};
      position: absolute;
      top: 15px;
      padding-left: 10px;
      transition: all 200ms;
      color: ${gray.hex()};
      opacity: 0.7;
    }
  }
`;

const InputLabel = ({ label, value, disabled, noMargin }) => (
  <Container>
    <Group disabled={disabled} noMargin={noMargin}>
      <input
        type="text"
        placeholder={label}
        value={value || ''}
        disabled={true}
      />
      <label htmlFor={label}>{label}</label>
    </Group>
  </Container>
);

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InputLabel;
