import React from 'react';
import { InputGroup, Checkbox } from '~/components/form';
import { TabContainer } from '~/views/products/styles';

const General = ({ previewMode }) => (
  <TabContainer>
    <Checkbox name='active' label='Ativo' disabled={previewMode} />

    <InputGroup
      type='text'
      name='name'
      label='Nome'
      maxLength={100}
      disabled={previewMode}
      />

    <InputGroup
      type='textarea'
      name='description'
      label='Descrição'
      maxLength={1000}
      disabled={previewMode}
      />
  </TabContainer>
);

export default General;
