import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { lightGray, white } from '~/components/mixins/color';
import { ChartContainer } from './styles';

function Evolution({ payload = [] }) {
  const keys = ['Novos Clientes', 'Faturamento', 'Inadimplência'];
  const data = [
    {
      Faturamento: 100000,
      Inadimplência: 52000,
      'Novos Clientes': 1940,
      month: 'Jan',
    },
    {
      Faturamento: 100020,
      Inadimplência: 7600,
      'Novos Clientes': 1750,
      month: 'Fev',
    },
    {
      Faturamento: 100040,
      Inadimplência: 43000,
      'Novos Clientes': 1550,
      month: 'Mar',
    },
  ];
  return (
    <ChartContainer>
      <h1 className='title'>Evolução</h1>
      <ResponsiveBar
        height={400}
        margin={{ top: 10, right: 80, bottom: 60, left: 80 }}
        data={data}
        indexBy='month'
        keys={keys}
        padding={0.4}
        labelTextColor={white.hex()}
        groupMode='grouped'
        enableGridX={true}
        labelSkipWidth={16}
        labelSkipHeight={16}
        colors={{ scheme: 'set1' }}
        borderRadius={2}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -50,
          legendPosition: 'middle',
        }}
        theme={{
          axis: {
            fontSize: '14px',
            tickColor: lightGray.hex(),
            ticks: {
              line: {
                stroke: lightGray.hex(),
              },
              text: {
                fill: lightGray.hex(),
              },
              grid: {
                line: {
                  stroke: lightGray.hex(),
                },
              },
            },
          },
        }}
      />
    </ChartContainer>
  );
}

export default Evolution;
