import { Formik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { MdAdd, MdKeyboardBackspace } from 'react-icons/md';
import { Container, FormToolbar, FormHeader, IconButton } from '~/components/crud/styles';
import DataTable from '~/components/datatable';
import FloatingButton from '~/components/floatbutton';
import Spinner from '~/components/spinner';

function Crud({ data, hideAdd, openForm = false, useOpenForm, columns, extraOptions, dataTableOptions, rightWidth, emptyText, keyField, keyFieldFn, onCloseFilter, onRowClicked, onChangePage, tableLoading, formLoading, actions, renderForm, formOptions, formTitle }) {
  const [showForm, setShowForm] = useState(openForm);
  const toogleForm = () => setShowForm(!showForm);
  const closeForm = () => {
    setShowForm(false);
    onCloseFilter && onCloseFilter();
  };

  _.each(actions, (r) => {
    r.actionFn = _.throttle(r.action, 500);
  });

  let flag = showForm;
  if (useOpenForm) {
    flag = openForm;
  }
  const useColumns = (flag) ? _.filter(columns, (r) => !r.hide) : columns;
  const handleOnSelect = useCallback(
    (value) => {
      setShowForm(true);
      onRowClicked(value);
    },
    [setShowForm, onRowClicked]
  );

  return (
    <Container showForm={flag} rightWidth={rightWidth}>
      <div className='left'>
        <DataTable
          columns={useColumns}
          data={data}
          emptyText={emptyText}
          loading={tableLoading}
          onChangePage={onChangePage}
          onRowClicked={handleOnSelect}
          keyField={keyField}
          keyFieldFn={keyFieldFn}
          extraOptions={extraOptions || {}}
          {...(dataTableOptions || {})}
          />

        {!flag && !hideAdd && (
          <FloatingButton icon={MdAdd} onClick={(e) => handleOnSelect({})} />
        )}
      </div>

      {flag && (
        <div className='right'>
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            {...formOptions}
            >
            {(args) => {
              return (
                <>
                  <FormHeader>
                    {formTitle(args.values)}
                    <Spinner visible={formLoading} />
                  </FormHeader>

                  <fieldset className="form-contents" disabled={formLoading}>
                    {renderForm(args)}
                  </fieldset>

                  <FormToolbar>
                    <IconButton
                      title='Cancelar'
                      type="button"
                      disabled={formLoading}
                      onClick={closeForm}>
                      <MdKeyboardBackspace />
                    </IconButton>

                    {_.map(actions, (row, index) => (
                      <IconButton
                        key={index}
                        type={row.isSubmit ? 'submit' : 'button'}
                        title={row.label}
                        color={row.color || ''}
                        disabled={formLoading || row.disabled || (row.isDisabled && row.isDisabled(args))}
                        onClick={() =>
                          row.actionFn(args.values, { toogleForm, ...args })
                        }
                        >
                        <row.icon />
                      </IconButton>
                    ))}
                  </FormToolbar>
                </>
              );
            }}
          </Formik>
        </div>
      )}
    </Container>
  );
}

Crud.propTypes = {
  data: PropTypes.object,
  columns: PropTypes.array.isRequired,
  tableLoading: PropTypes.bool.isRequired,
  formLoading: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
  onRowClicked: PropTypes.func,
  formTitle: PropTypes.func,
  rightWidth: PropTypes.string,
  dataTableOptions: PropTypes.object,
  onChangePage: PropTypes.func,
};

Crud.defaultProps = {
  formTitle: () => '',
  onRowClicked: _.noop,
  formLoading: false,
  tableLoading: false,
  dataTableOptions: {},
  data: {}
};

export default Crud;
