import React, { useState } from 'react';
import _ from 'lodash';
import DataTable from '~/components/datatable';
import { formats } from 'helper';

const columns = [
    {
      name: 'Vencimento',
      width: '120px',
      selector: 'dueDate',
      format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
    },
    {
      name: 'Valor',
      selector: 'value',
      width: '120px',
      right: true,
      format: (row) => formats.currency(_.get(row, 'value'))
    },
    {
      name: 'Ocorrência',
      width: '62px',
      selector: 'responseOccurrences',
      center: true,
      format: (row) => _.get(row, 'responseOccurrences.occurrence')
    },
    {
      name: 'Data Pagamento',
      width: '140px',
      selector: 'responseOccurrences',
      right: true,
      format: (row) => formats.dateTimeZone(_.get(row, 'responseOccurrences.payDate'), 'dd/MM/yyyy')
    },
    {
      name: 'Valor Pago',
      selector: 'responseOccurrences',
      right: true,
      format: (row) => formats.currency(_.get(row, 'responseOccurrences.payAmount'))
    },
    {
      name: 'Multa',
      selector: 'responseOccurrences',
      right: true,
      format: (row) => formats.currency(_.get(row, 'responseOccurrences.fine'))
    },
    {
      name: 'Juros',
      selector: 'responseOccurrences',
      right: true,
      format: (row) => formats.currency(_.get(row, 'responseOccurrences.interest'))
    },
    {
      name: 'Desconto',
      selector: 'responseOccurrences',
      right: true,
      format: (row) => formats.currency(_.get(row, 'responseOccurrences.discount'))
    }
  ];

const getRows = (array = [], offset, pageSize) => {
  return array.slice(offset, offset + pageSize);
};

export const TabBillings = ({ billets, openBillet }) => {
  const count = _.size(billets);
  const pageSize = 50;
  const [ offset, setOffset ] = useState(0);

  const onChangePage = (offset) => {
    setOffset(offset);
  };

  return (
    <DataTable
      emptyText="Nenhum boleto vinculado"
      pageSize={pageSize}
      onChangePage={onChangePage}
      data={{ rows: getRows(billets, offset, pageSize), offset, count, limit: pageSize }}
      onRowClicked={openBillet}
      columns={columns}
    />
  )
};

export default TabBillings;