import React from "react";
import _ from "lodash";
import { Field, ErrorMessage } from "formik";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled, { css } from "styled-components";
import { Select } from "~/components/form";
import { gray, primary, tertiary, white } from "~/components/mixins/color";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${(props) => props.noMargin ? '0' : '0 0 10px'};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      user-select: none;
      pointer-events: none;
    `}
`;

const Group = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.height || 180) + 42}px;
  position: relative;

  .quill {
    width: 100%;
  }
  .ql-snow .ql-stroke {
    stroke: ${gray.hex()};
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: ${white.hex()};
    color: ${white.hex()};
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: ${white.hex()};
  }
  .ql-snow .ql-picker,
  .ql-editor.ql-blank::before,
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill,
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    color: ${gray.hex()};
    fill: ${gray.hex()};
  }
  .ql-container,
  .ql-toolbar.ql-snow {
    font-family: inherit !important;
  }
  .ql-container.ql-snow {
    border: none;
  }
  .ql-snow .ql-picker-options {
    background: ${tertiary.hex()};
    color: ${gray.hex()};
  }
  .ql-editor {
    background: ${primary.hex()};
  }
  .ql-toolbar.ql-snow {
    border: 1px solid ${primary.hex()};
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label,
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: ${primary.hex()};
  }
  .ql-toolbar.ql-snow {
    height: 42px;
  }
  .variable-box {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 250px;
    min-width: 250px;
    border: none;
    padding: 0;
    margin: 0;
    select {
      height: 40px;
    }
  }
`;

const defaultModules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["clean"],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
  },
  defaultFormats = [
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "link",
    "image",
  ];

const RichEditor = ({ name, height, modules, formats, valueFormat = "html", variables, disabled, placeholder, noMargin, onChange, }) => {
  const editorRef = React.createRef();

  const setFieldIntoHTML = (value) => {
    if (!value) {
      return;
    }
    const editor = _.get(editorRef, "current.editor");

    if (editor) {
      const found = _.find(variables, (f) => f.id === value);

      if (found) {
        const varText = _.isEmpty(found.template) ? `<%=${value}%>` : found.template;
        let cursorPosition = _.get(editor.getSelection(), "index") || 0;
        editor.insertText(cursorPosition, varText);
        editor.setSelection(cursorPosition + _.size(varText));
      }
    }
  };

  const changeValue = (form, value) => {
    if (onChange) {
      onChange({ target: { value, name } });
    } else {
      form.setFieldValue(name, value);
    }
  };

  return (
    <Container disabled={disabled} noMargin={noMargin}>
      <Field type="text" id={name} name={name}>
        {({ field, form }) => (
          <Group height={height}>
            <ReactQuill
              ref={editorRef}
              theme={"snow"}
              modules={modules || defaultModules}
              formats={formats || defaultFormats}
              readOnly={disabled}
              placeholder={placeholder}
              style={{ textTransform: "none", height: height || "180px" }}
              value={field.value || ""}
              onChange={(content) => changeValue(form, content)}
            />
            <Select
              className="variable-box"
              label="Variáveis"
              name="variables"
              hideLabel={true}
              disabled={disabled}
              padding="5px"
              onChange={({ target: { value } }) => {
                setFieldIntoHTML(value);
              }}
              options={{
                values: _.map(variables, (r) => ({
                  value: r.id,
                  label: r.label,
                  template: r.template,
                })),
              }}
            />
          </Group>
        )}
      </Field>
      <ErrorMessage name={name} className="error" component="div" />
    </Container>
  );
};

export default RichEditor;

/*
  Exemplo de uso

  import { RichEditor } from '~/components/form';

  <RichEditor
    height={144}
    name="statement"
    placeholder="Demonstrativo"
    modules={{ toolbar: [] }}
    variables={billetVariables}
    />
*/
