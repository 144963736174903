import { useEffect } from 'react';
import _ from 'lodash';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  showForm: false,
  term: null,
  offset: 0,
  model: null,
  erpVersions: [],
  allErpVersions: {},
  data: { rows: [], count: 0, offset: 0 }
};

const initialFilterState = {
  active: true
};

const useModules = () => {
  const { state: { modules = initialState, modulesFilter = initialFilterState }, dispatch, globalDispatch } = useGlobalContext('modules'),
    { state: headerState, actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false });
    showMessage('error', error);
  };

  useEffect(() => {
    headerActions.hideFilter();
    headerActions.callFilter(modulesFilter);
    dispatch(initialState);
    // eslint-disable-next-line
  }, []);

  const { data, mutate } = useFetch({
    url: 'modules/list',
    method: 'POST',
    params: {
      ..._.get(headerState, 'filter.data'),
      offset: modules.offset || 0
    }
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Módulos',
      useFilter: true,
      count: _.get(data, 'count', 0),
      loading: _.get(modules, 'loading', false),
    });
    // eslint-disable-next-line
  }, [data, modules]);

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true, showForm: true });
      let id = data.id || 'new';
      const response = await api.get('modules/load', { params: { id }}),
        erpVersions = _.get(response, 'data.erpVersions') || [],
        allErpVersions = _.get(response, 'data.allErpVersions') || {},
        model = _.get(response, 'data.module') || { active: true };

      dispatch({ model, erpVersions, allErpVersions, formLoading: false, showForm: true });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      let formData = new FormData();
      let params = _.omit(model, 'moduleFiles'),
        moduleFiles = _.get(model, 'moduleFiles');

      params.moduleFiles = _.map(moduleFiles, (r) => ({
        chunks: r.chunks || 0,
        arch: r.arch || '',
        version: r.version,
        filename: _.get(r, 'filename') || _.get(r, 'file.name')
      }));
      formData.append('data', JSON.stringify(params));

      if (!_.isEmpty(moduleFiles)) {
        _.each(_.filter(moduleFiles, (r) => r.file), (f) => {
          formData.append(`files[${f.version}]`, f.file);
        });
      }
      await api[method](`modules/${path}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
      mutate(data);
      showMessage('success', `Módulo ${editing ? 'atualizado' : 'criado'} com sucesso`);
      actions.toogleForm && actions.toogleForm();
      dispatch({ formLoading: false, showForm: false });
    } catch (error) {
      failure(error);
    }
  };

  const hideForm = async () => {
    dispatch({ model: {}, showForm: false });
    headerActions.hideFilter();
  };

  const changeFilter = (data) => {
    dispatch({ offset: 0, model: {}, showForm: false });
    globalDispatch({ type: 'modulesFilter', persistent: true, payload: data });
    headerActions.callFilter(data);
  };

  const listModules = code => async (term, callback) => {
    try {
      const response = await api.get(`modules/list-modules`, { params: { term } });
      callback(_.filter(response.data, r => r.code !== code));
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...modules,
      data: data || {},
      loading: !data
    },
    actions: {
      load,
      pageChange,
      createOrUpdate,
      changeFilter,
      hideForm,
      listModules
    }
  };
};

export default useModules;
