import React from 'react';
import AvatarForm from '~/views/users/update-avatar';
import UpdatePassword from '~/views/users/update-password';

function ProfileModals({
  profileData,
  passwordFrmIsOpen,
  closeModalPassword,
  handleUpdatePassword,
  avatarData,
  avatarFrmIsOpen,
  closeModalAvatar,
  handleUpdateAvatar,
}) {
  return (
    <>
      {avatarFrmIsOpen && 
        <AvatarForm
          title={<>Mude a sua foto</>}
          data={{ avatar: avatarData }}
          handleOnSubmit={handleUpdateAvatar}
          isOpen={avatarFrmIsOpen}
          closeModal={closeModalAvatar}
          />
      }
      {passwordFrmIsOpen && 
        <UpdatePassword
          title={<>Modifique sua senha</>}
          data={profileData}
          handleOnSubmit={handleUpdatePassword}
          isOpen={passwordFrmIsOpen}
          closeModal={closeModalPassword}
          />
      }
    </>
  );
}

export default ProfileModals;
