import { getMonth, getYear, lastDayOfMonth } from 'date-fns';
import _ from 'lodash';
import api from '~/services/api';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { formats, showMessage } from '~/helper';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  openForm: false,
  data: {},
};

const useBilling = () => {
  const { state: { billings = initialState }, dispatch } = useGlobalContext('billings'),
    { state: headerState, actions: headerActions } = useHeader(),
    history = useHistory();

  useEffect(() => {
    let now = new Date();
    headerActions.callFilter({ month: getMonth(now), year: getYear(now) });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    headerActions.configure({
      useFilter: true,
      title: prepareTitle(headerState.filter.data),
      count: 'none',
      loading: billings.loading,
    });
    // eslint-disable-next-line
  }, [billings.loading, headerState.filter.data]);

  const { data } = useFetch({
    url: '/billings/list',
    params: _.get(headerState, 'filter.data'),
  });

  const failure = (error) => {
    dispatch({ signed: false, isLoginMode: true });
    showMessage('error', error);
  };

  function prepareTitle(data) {
    const month = _.get(data, 'month'),
      year = _.get(data, 'year');

    if ((!month && month !== 0) || !year) {
      return '';
    }
    const startDate = new Date(year, month),
      endDate = lastDayOfMonth(startDate);

    return `Faturas - Período: ${formats.date(startDate)} até ${formats.date(
      endDate
    )}`;
  }

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    if (!data.id) {
      return history.push(`/billings/wizard`);
    }
    try {
      dispatch({ model: {}, openForm: true, formLoading: true });

      const response = await api.get('billings/load', {
          params: { id: data.id },
        }),
        model = response.data;

      dispatch({ model, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const listPartners = async (term, callback) => {
    try {
      const response = await api.get(`billings/list-partners`, {
        params: { term },
      });
      callback(response.data);
    } catch (error) {
      failure(error);
    }
  };

  const hideFilter = (error) => {
    dispatch({ openForm: false });
    headerActions.hideFilter();
  };

  return {
    state: {
      ...billings,
      data: data || {},
      loading: !data,
    },
    actions: {
      load,
      pageChange,
      hideFilter,
      listPartners,
    },
  };
};

export default useBilling;
