import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Background, Container, Title, Content, Footer } from '~/components/confirm/styles';
import { Button } from '~/components/button';

class ConfirmMulti extends React.Component {
  state = {
    isOpen: false,
    confirmProps: {}
  };

  resolve = null;

  static create(props = {}) {
    const el = document.createElement('div');
    document.body.appendChild(el);
    return ReactDOM.render(<ConfirmMulti confirmProps={props} />, el);
  }

  handleValue = (value) => {
    this.setState({ isOpen: false });
    this.resolve(value);
  };

  show = (props = {}) => {
    if ((props.height && isNaN(props.height)) || (props.width && isNaN(props.width))) {
      throw new Error('width and height must be numeric!')
    }
    const confirmProps = { ...this.props.confirmProps, ...props };
    this.setState({ isOpen: true, confirmProps });
    return new Promise(res => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, confirmProps } = this.state,
      { title, text, width = 450, height = 120, options } = confirmProps

    if (!isOpen) {
      return null;
    }
    return (
      <Background isOpen={isOpen}>
        <Container height={height > window.innerHeight ? '90%' : `${height}px`} width={width > window.innerWidth ? '90%' : `${width}px`}>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{__html: text}} />
          <Footer>
            {_.map(options, (option, index) => (
              <Button key={index} primary={option.primary} onClick={() => this.handleValue(option.value)}>
                {option.label}
              </Button>
            ))}
          </Footer>
        </Container>
      </Background>
    );
  }
}

export default ConfirmMulti;
