import { Form } from 'formik';
import styled from 'styled-components';
import { accent, primary, secondary, symbol, tertiary, white, black } from '~/components/mixins/color';

export const Container = styled.div`
  height: 100%;
  max-height: ${(props) => props.maxHeight};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 0px;
  overflow: auto;
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 184px);
`;

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: ${primary.hex()};
  border: 2px solid ${tertiary.hex()};
  border-radius: 3px;
  color: ${white.hex()};
  height: 66px;

  &:hover {
    background-color: ${secondary.hex()};
  }

  & > svg {
    color: ${symbol.hex()};
    transition: color 0.2s ease 0s;

    &:hover {
      color: ${accent.hex()};
      cursor: pointer;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
  .action {
    display: flex;
    flex: 0 0 46px;
    align-items: center;
    justify-content: center;
  }
  .value {
    display: flex;
    flex: 0 0 120px;
    align-items: center;
    justify-content: center;
  }
  .text {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-size: 110%;
      margin: 0 0 5px 0;
    }
    strong {
      font-size: 80%;
    }
  }
`;

export const ImagesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(180px, 1fr));
  grid-gap: 5px;

  > div {
    position: relative;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 200px;
    background: ${black.hex()};
  }

  span {
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    background: ${black.hex()};
    color: ${white.hex()};
    position: absolute;

    > svg {
      cursor: pointer;

      &:hover {
        color: ${accent.hex()};
      }
    }
  }
`;
