import React from 'react';
import * as Yup from 'yup';
import Tabs from '~/components/tabs';
import TabAggrements from '~/views/partners/tab-aggrements';
import TabBillings from '~/views/partners/tab-billings';
import TabAddresses from '~/views/partners/tab-addresses';
import TabContacts from '~/views/partners/tab-contacts';
import TabContracts from '~/views/partners/tab-contracts';
import TabGeneral from '~/views/partners/tab-general';
import TabUnlocks from '~/views/partners/tab-unlocks';
import { FormContainer } from '~/views/partners/styles';
import confirm from '~/components/confirm';
import _ from 'lodash';

export const PartnerForm = ({ errors, changeFormtab1, changeFormtab, formTab, permissions, status, contractModels, touched, values, previewMode, setFieldValue, setFieldTouched, onSearchCnpj, goToContract, handleRemoveContract  }) => {
  const tabs = [
    { id: 'general', label: 'Geral', component: TabGeneral },
    { id: 'addresses', label: 'Endereços', component: TabAddresses },
    { id: 'contacts', label: 'Contatos', component: TabContacts },
    { id: 'contracts', label: 'Aditamentos', component: TabContracts }
  ];

  if (values.id) {
    if (values.billings) {
      tabs.push({ id: 'bilings', label: 'Faturas', component: TabBillings });
    }
    if (values.aggrements) {
      tabs.push({
        id: 'aggrements',
        label: 'Contratos',
        component: TabAggrements
      });
    }
    if (values.unlocks) {
      tabs.push({
        id: 'unlocks',
        label: 'Desbloqueios',
        component: TabUnlocks
      });
    }
  }

  function addContact(contact) {
    setFieldValue('contacts', [...values.contacts, contact]);
  }

  async function removeContact(index) {
    if (index >= 0) {
      const result = await confirm.show({
        width: 450,
        title: 'Atenção',
        text: `Deseja realmente remover este contato ?`
      });

      if (result) {
        let list = _.cloneDeep(values.contacts || []);
        list.splice(index, 1);
        setFieldValue('contacts', list);
      }
    }
  }

  return (
    <FormContainer>
      <Tabs
        initialTab="general"
        currentTab={formTab}
        changeTab={changeFormtab}
        height='calc(100% - 50px)'
        tabs={tabs}
        disabled={previewMode}
        values={values}
        contractModels={contractModels}
        goToContract={goToContract}
        handleRemoveContract={handleRemoveContract}
        handleAddContact={addContact}
        handleRemoveContact={removeContact}
        handleSearchCnpj={onSearchCnpj}
        setFieldTouched={setFieldTouched}
      />
    </FormContainer>
  );
};

export const PartnerSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Verifique se a razão social está correto')
    .required('Informe a razão social'),
  tradeName: Yup.string()
    .min(4, 'Verifique se a nome fantasia está correto')
    .required('Informe a nome fantasia'),
  identity: Yup.string().required('Informe o CNPJ')
});
