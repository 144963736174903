import React from 'react';
import { MdDelete } from 'react-icons/md';
import { Formik } from 'formik';
import { Autocomplete } from '~/components/form';
import { Row } from '~/components/layout';
import { Item, TabContainer } from '~/views/modules/styles';
import { IconButton } from '~/components/button';
import _ from 'lodash';

const TabDependencies = ({ values: rootValues, previewMode, handleAddModule, handleListModules, handleRemoveModule }) => {
  function onAddModule(value, handleReset) {
    handleAddModule(value);
    handleReset();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ name: '', code: '' }}
      >
      {({ errors, isValid, touched, values, handleReset }) => (
      <TabContainer>
        <Row>
          <Autocomplete
            name='module'
            label='Módulo'
            keyField='id'
            preload={true}
            keyApp={`${_.get(values, 'code')}-`}
            disabled={previewMode}
            value={values.module}
            valueFormat={(row) => `${row.name} (${row.code})`}
            loadData={handleListModules}
            emptyText={'Pesquise um módulo'}
            tipText={'Digite... '}
            loadingText={'Carregando...'}
            notFoundText={'Não encontrado'}
            onChange={(val) => val && onAddModule({ ...val, isNew: true }, handleReset) }
            />
        </Row>

        {_.map(_.get(rootValues, 'dependencies') || [], (value, index) => (
          <Item key={index}>
            <div className='column-data'>
              <div className='first'>
                <strong>{value.code || index}</strong>
                <div>{value.name}</div>
              </div>
            </div>
            {(value.isNew && !previewMode) && (
              <IconButton size={36} margin="0">
                <MdDelete onClick={() => handleRemoveModule(index)} />
              </IconButton>
            )}
          </Item>
        ))}
      </TabContainer>
      )}
    </Formik>
  );
};

export default TabDependencies;
