import Confirm from './confirm';

const defaultOptions = [
  { label: 'Não', value: false },
  { label: 'Sim', value: true, primary: true }
];

export default Confirm.create({ options: defaultOptions });

//  exemplo de uso
//  import confirm from '~/components/confirm'
//
//  const result = await confirm.show({
//     title: 'Inativação',
//     text: 'Deseja realmente inativar esse usuário?'
//  });
