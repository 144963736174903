import React from 'react';
import _ from 'lodash';
import Fieldset from '~/components/fieldset';
import DataTable from '~/components/datatable';
import { InputLabel } from '~/components/form';
import { Row } from '~/components/layout';
import { formats } from '~/helper';
import CellStatus from '~/components/datatable/cell-status';
import { FormHeader, FormBody, BilletsContainer } from '~/views/billings/styles';

let statusMap = {
  'open': 'Aberto',
  'paid': 'Pago',
  'delayed': 'Atrasado',
};

const statusColors = {
  'open': '#90d1ff',
  'paid': '#a3d865',
  'delayed': '#e06767'
};

const columns = [
  {
    name: '# Nosso número',
    selector: 'ourNumber',
    cell: (row) => {
      return (
        <CellStatus title={row.ourNumber} color={statusColors[_.get(row, 'status') || 'open']}>
          <strong>{row.ourNumber}</strong>
        </CellStatus>
      );
    },
  },
  {
    name: 'Vencimento',
    selector: 'dueDate',
    format: (row) => formats.dateTimeZone(_.get(row, 'dueDate'), 'dd/MM/yyyy')
  },
  {
    name: 'Valor',
    selector: 'value',
    right: true,
    format: (row) => formats.currency(_.get(row, 'value'))
  },
  {
    name: 'Situação',
    selector: 'status',
    center: true,
    format: (row) => statusMap[_.get(row, 'status') || 'open']
  }
];

export default function FormDetail({ values, disabled, errors, touched }) {

  return (
    <>
      <FormHeader>
        {`Fatura #${values.id} - Parceiro: ${formats.cnpj_cpf(_.get(values, 'partner.identity'))} `}
      </FormHeader>

      <FormBody>
        <Row span={2}>
          <InputLabel
            label="Situação"
            value={statusMap[_.get(values, 'status') || 'open']}
            />

          <InputLabel
            label="Valor"
            value={formats.currency(_.get(values, 'value'))}
            />
        </Row>

        <Row span={1}>
          <InputLabel
            label="Parceiro"
            value={`${formats.cnpj_cpf(_.get(values, 'partner.identity'))} - ${_.get(values, 'partner.name')}`}
            />
        </Row>

        <Fieldset label="Boletos">
          <BilletsContainer>
            <DataTable
              keyField="ourNumber"
              emptyText="Nenhum boleto vinculado"
              noPagination={true}
              data={{ rows: values.billets }}
              columns={columns}
              extraOptions={{
                selectableRows: false,
                selectableRowsHighlight: false
              }}
              />
          </BilletsContainer>
        </Fieldset>
      </FormBody>
    </>
  );
}
