import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import search from '~/assets/search.svg';
import useEventListener from '~/hooks/use-event-listener';
import { gray, primary, quaternary } from '../mixins/color';

const Container = styled.div`
  display: flex;

  input {
    width: ${(props) => (props.term ? '100%' : '36px')};
    padding: 5px 10px;
    height: 36px;
    border-radius: 3px;
    font-size: 14px;
    color: ${gray.hex()};
    background: ${primary.hex()} url(${search}) no-repeat ${(props) => (props.term ? '92%' : '10px')} center;
    border: 2px solid ${quaternary.hex()};

    -webkit-transition: background 0.45s ease;
    -moz-transition: background 0.45s ease;
    -ms-transition: background 0.45s ease;
    -o-transition: background 0.45s ease;
    transition: background 0.45s ease;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
      width: 100%;
      background: ${quaternary.hex()} url(${search}) no-repeat 92% center;
      cursor: text;

      &::-webkit-input-placeholder {
        color: ${gray.hex()};
      }
      &:-moz-placeholder {
        color: ${gray.hex()};
      }
      &::-moz-placeholder {
        color: ${gray.hex()};
      }
      &:-ms-input-placeholder {
        color: ${gray.hex()};
      }
    }

    &::-webkit-input-placeholder {
      color: transparent;
    }
    &:-moz-placeholder {
      color: transparent;
    }
    &::-moz-placeholder {
      color: transparent;
    }
    &:-ms-input-placeholder {
      color: transparent;
    }

    img {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin: 0 15px;
    }
  }
`;

function InputSearch({ onSearch, incrementalSearch, ...rest }) {
  const [term, setTerm] = useState('');

  useEventListener({ name :'keydown', handler: onKeyDown, elementId: 'adept-search-box'});

  useEffect(() => {
    if (incrementalSearch && term && term.length > 0) {
      onSearch(term);
    }
  }, [term, incrementalSearch, onSearch]);

  function handleChange(e) {
    setTerm(e.target.value);
  }

  function onKeyDown(event) {
    const charCode = event.which || event.keyCode;

    if (charCode === 27) {
      setTerm('');
      onSearch('');
      event.target.blur();
    }

    if (charCode === 13) {
      onSearch(event.target.value);
      event.target.focus();
    }
  }

  return (
    <Container term={term}>
      <input id="adept-search-box" type='text' value={term} onChange={handleChange} {...rest} />
    </Container>
  );
}

InputSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

InputSearch.defaultProps = {
  placeholder: 'Pesquisa...',
};

export default memo(InputSearch);
