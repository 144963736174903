import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  term: null,
  offset: 0,
  model: null,
  permissions: [],
  data: { rows: [], count: 0, offset: 0 },
};

const useProfiles = () => {
  const { state: { profiles = initialState }, dispatch } = useGlobalContext('profiles'),
    { actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: 'profiles/list',
    params: { term: profiles.term, offset: profiles.offset },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Perfis',
      count: _.get(data, 'count', 0),
      loading: _.get(profiles, 'loading', false),
      onSearch: (text) => list(0, text),
    });
    // eslint-disable-next-line
  }, [data, profiles]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      dispatch({ model: {}, formLoading: true });

      const response = await api.get('profiles/load', { params: { id: data.id || 'new' } });
      const model = _.get(response.data, 'profile'),
        permissions = _.get(response.data, 'permissions');

      dispatch({ model, permissions, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`profiles/${path}`, model);
      mutate(data);
      showMessage('success', `Perfil ${editing ? 'atualizado' : 'criado'} com sucesso`);
      actions.toogleForm && actions.toogleForm();
      dispatch({ formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...profiles,
      data: data || {},
      loading: !data,
    },
    actions: {
      pageChange,
      load,
      list,
      createOrUpdate,
    },
  };
};

export default useProfiles;
