import { useContext } from 'react';
import { globalContext } from '~/context';

const useGlobalContext = (type) => {
  const [state, dispatch] = useContext(globalContext);

  const doDispatch = (payload, persistent, merge) => {
    dispatch({ type, payload, persistent, merge });
  };

  return {
    state,
    globalDispatch: dispatch,
    dispatch: (type) ? doDispatch : dispatch
  };
};

export default useGlobalContext;
