import React from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import { Autocomplete, Checkbox, InputGroup } from '~/components/form';
import { FormContainer } from '~/views/users/styles';

export const UserForm = ({ errors, status, touched, values, isValid, previewMode, handleListProfiles }) => {

  return (
    <FormContainer>
      <Checkbox name='active' label='Ativo' disabled={previewMode} />

      <Autocomplete
        name='profile'
        label='Perfil'
        keyField='id'
        preload={true}
        keyApp={`${_.get(values, 'name')}-`}
        value={values.profile}
        disabled={previewMode}
        valueFormat={(row) => `${row.name}`}
        loadData={handleListProfiles}
        emptyText={'Pesquise um perfil'}
        tipText={'Digite... '}
        loadingText={'Carregando...'}
        notFoundText={'Não encontrado'}
        />

      <InputGroup
        disabled={previewMode}
        type='text'
        name='name'
        label='Nome'
        maxLength={100}
        hasError={errors.name && touched.name}
        />

      <InputGroup
        disabled={previewMode}
        type='email'
        name='email'
        label='Email'
        maxLength={100}
        hasError={errors.email && touched.email}
        />

      <InputGroup
        disabled={values.id || previewMode}
        type='text'
        name='username'
        label='Usuário (Login)'
        maxLength={100}
        hasError={errors.username && touched.username}
        />

      {status && status.msg && <div>{status.msg}</div>}
    </FormContainer>
  );
};

export const UserSchema = Yup.object().shape({
  username: Yup.string().required('É necessário informar um Usuário (login)'),
  name: Yup.string().min(4, 'Verifique se o nome está correto').required('Informar o nome'),
  email: Yup.string().required('Informe o email').email('Informe um email válido'),
  profile: Yup.mixed().test('', 'Informe o Perfil', function(val) { return !_.isEmpty(val); })
});
