import React from 'react';
import { Container } from '~/views/licenses/styles';
import CellStatus from '~/components/datatable/cell-status';
import Crud from '~/components/crud';
import _ from 'lodash';
import { formats } from '~/helper';
import useLicenses from '~/view-data/use-licenses';
import { MdSave, MdSwapHoriz } from 'react-icons/md';
import { LicenseForm, LicenseSchema } from '~/views/licenses/form';
import { SubtitleItem, SubtitleBox } from '~/components/datatable/subtitle';
import confirm from '~/components/confirm';
import EnvironmentSetupModal from '~/views/licenses/modal-environments';
import CompaniesModal from '~/views/licenses/modal-companies';
import { accent } from '~/components/mixins/color';

const statusColors = {
  open: '#90d1ff',
  paid: '#a3d865',
  delayed: '#e06767',
};

const statusLabels = {
  open: 'Em aberto',
  paid: 'Pago',
  delayed: 'Atrasado',
};

const columns = [
  {
    name: '# Contrato',
    selector: 'contract',
    width: '90px',
    cell: (row) => {
      let text = `${_.get(row, 'contract.id')}`;
      return (
        <CellStatus title={text} color={statusColors[row.stats || 'open']}>
          <strong>{text}</strong>
        </CellStatus>
      );
    },
  },
  {
    name: 'Grupo de cliente',
    selector: 'contract',
    width: '20%',
    format: (row) =>
      `${_.get(row, 'contract.groupName')}`,
  },
  {
    name: 'Parceiro',
    selector: 'partner',
    format: (row) => `${formats.cnpj_cpf(_.get(row, 'partner.identity'))} - ${_.get(row, 'partner.name')}`
  },
  {
    name: 'Nº de clientes',
    selector: 'customers',
    hide: 'md',
    right: true,
    width: '120px',
    format: (row) => _.size(_.get(row, 'customers')),
  },
  {
    name: 'Cadastrada em',
    selector: 'createdAt',
    hide: 'md',
    right: true,
    width: '150px',
    format: (row) => formats.dateTimeZone(_.get(row, 'createdAt'), 'dd/MM/yyyy HH:mm'),
  }
];

function Licenses({ acls, history }) {
  const { state, actions } = useLicenses();
  const canWrite = acls.includes('W');

  const handleRefresh = async (data) => {
    const result = await confirm.show({
      title: 'Atenção',
      text: `Deseja reprocessar os dados da licença?`,
    });

    if (result) {
      actions.forceRefresh(_.pick(data, 'contractId'));
    }
  };

  return (
    <Container>
      <SubtitleBox bottom={35} left={100}>
        <span>Legenda:</span>
        {_.map(statusLabels, (label, status) => (
          <SubtitleItem key={status} color={statusColors[status]}>{label}</SubtitleItem>
        ))}
      </SubtitleBox>

      <Crud
        columns={columns}
        emptyText='Nenhuma licença encontrada'
        formTitle={(data) => data.isNew ? 'Nova Licença' : `Licença - Contrato # ${_.get(data, 'contract.id')}`}
        data={state.data}
        hideAdd={!canWrite}
        tableLoading={state.loading}
        formLoading={state.formLoading}
        onChangePage={actions.pageChange}
        onRowClicked={actions.load}
        rightWidth="700px"
        formOptions={{
          validationSchema: LicenseSchema,
          initialValues: state.model,
          initialTouched: { contract: true },
        }}
        keyFieldFn={(r) => `${_.get(r, 'contract.id')}}`}
        renderForm={(args) => (
          <LicenseForm
            {...args}
            history={history}
            previewMode={!canWrite}
            onListPartners={actions.listPartners}
            itemSelected={state.itemSelected}
            changeItemSelected={actions.changeItemSelected}
            openCompaniesModal={actions.openCompaniesModal}
            onBlock={actions.block}
            />
        )}
        actions={[
          {
            label: 'Salvar',
            icon: MdSave,
            isSubmit: true,
            isDisabled: ({ values, isValid }) => !isValid || !canWrite || !values.isNew,
            action: actions.createOrUpdate,
          },
          {
            label: 'Atualizar Licença',
            icon: MdSwapHoriz,
            isSubmit: false,
            color: accent.hex(),
            isDisabled: ({ values, isValid }) => !canWrite || values.isNew || values.synchronizedAt >= values.changedAt,
            action: handleRefresh
          }
        ]}
      />

    <CompaniesModal
      data={state.modalData || {}}
      isOpen={state.showCompaniesModal}
      closeModal={actions.closeCompaniesModal}
      title={<span>Clientes com o módulo "{`${_.get(state, 'modalData.groupName')} / ${_.get(state, 'modalData.moduleName')}`}"</span>}
      />

    <EnvironmentSetupModal
      loading={state.modalLoading}
      data={state.modalData || {}}
      environments={state.environments}
      selectedEnvironment={state.selectedEnvironment}
      handleOnSubmit={actions.submitEnvironmentSetupModal}
      changeSelectedEnvironment={actions.changeSelectedEnvironment}
      isOpen={state.showEnvironmentSetupModal}
      closeModal={actions.closeEnvironmentSetupModal}
      title={<span>DLL - Defina o ambiente de Produção</span>}
      />
    </Container>
  );
}

export default Licenses;
