import _ from 'lodash';
import { useEffect } from 'react';
import { showMessage } from '~/helper';
import api from '~/services/api';
import useGlobalContext from '~/view-data/use-global-context';
import useFetch from '~/view-data/use-fetch';
import useHeader from '~/view-data/use-header';

const initialState = {
  loading: false,
  formLoading: false,
  term: null,
  offset: 0,
  model: null,
  data: { rows: [], count: 0, offset: 0 },
};

const useRrpVersions = () => {
  const { state: { erpVersions = initialState }, dispatch } = useGlobalContext('erpVersions'),
    { actions: headerActions } = useHeader();

  const failure = (error) => {
    dispatch({ loading: false, formLoading: false, modalLoading: false });
    showMessage('error', error);
  };

  const { data, mutate } = useFetch({
    url: 'erp-versions/list',
    params: { term: erpVersions.term, offset: erpVersions.offset },
  });

  useEffect(() => {
    headerActions.configure({
      title: 'Versões ERP',
      count: _.get(data, 'count', 0),
      loading: _.get(erpVersions, 'loading', false),
      onSearch: (text) => list(0, text),
    });
    // eslint-disable-next-line
  }, [data, erpVersions]);

  const list = (offset = 0, term) => {
    dispatch({ offset, term });
  };

  const pageChange = (offset = 0) => {
    dispatch({ offset });
  };

  const load = async (data = {}) => {
    try {
      let id = data.id || 'new',
        model = { active: true };

      dispatch({ model: {}, formLoading: true });
      if (id !== 'new') {
        const response = await api.get('erp-versions/load', { params: { id } });
        model = _.get(response, 'data') || {};
      }
      dispatch({ model, formLoading: false });
    } catch (error) {
      failure(error);
    }
  };

  const createOrUpdate = async (model, actions) => {
    try {
      dispatch({ formLoading: true });

      let editing = !!(model && model.id),
        method = editing ? 'put' : 'post',
        path = editing ? 'update' : 'create';

      await api[method](`erp-versions/${path}`, model);

      mutate(data);
      showMessage('success', `Versão de ERP ${editing ? 'atualizado' : 'criado'} com sucesso`);
      actions.toogleForm && actions.toogleForm();
    } catch (error) {
      failure(error);
    }
  };

  return {
    state: {
      ...erpVersions,
      data: data || {},
      loading: !data,
    },
    actions: {
      pageChange,
      load,
      list,
      createOrUpdate,
    },
  };
};

export default useRrpVersions;
