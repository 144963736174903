import React from 'react';
import _ from 'lodash';
import Fieldset from '~/components/fieldset';
import { Checkbox, InputDate, InputGroup, InputMask, Select } from '~/components/form';
import { TabContainer } from '~/views/partners/styles';
import { Row, FlexRow } from '~/components/layout';
import { MASK_CNPJ, MASK_PHONE } from '~/constants';
import { onlyNumbers } from '~/helper';

const buildOptions = (list) => {
  return {
    values: [ {label: '', value: ''}, ..._.map(list, (r) => ({ label: r.name, value: `${r.id}` })) ]
  };
};

const TabGeneral = ({ previewMode, setFieldTouched, values = {}, errors = {}, touched = {}, handleSearchCnpj = _.noop, contractModels }) => {
  return (
    <TabContainer>
      <Checkbox name='active' label='Ativo' />

      <InputMask
        disabled={previewMode}
        mask={MASK_CNPJ}
        name='identity'
        label={'CNPJ'}
        hasError={errors.identity && touched.identity}
        onBlur={(e) => {
          const value = onlyNumbers(e.target.value) || '';
          setFieldTouched('identity', true);
          if (value && value.length === 14 && handleSearchCnpj && !values.name && !values.tradeName) {
            handleSearchCnpj(value, values);
          }
        }}
      />

      <InputGroup
        type='text'
        name='name'
        label='Razão Social'
        maxLength={100}
        disabled={previewMode}
        hasError={errors.name && touched.name}
      />

      <InputGroup
        type='text'
        name='tradeName'
        label='Nome Fantasia'
        maxLength={100}
        disabled={previewMode}
        hasError={errors.tradeName && touched.tradeName}
      />

      <FlexRow>
        <div style={{flex: '1'}}>
          <InputGroup
            type='email'
            name='email'
            label='Email'
            maxLength={100}
            disabled={previewMode}
            hasError={errors.email && touched.email}
            />
        </div>

        <div style={{flex: '0 0 150px'}}>
          <InputMask
            name='phone'
            label='Telefone'
            mask={MASK_PHONE}
            disabled={previewMode}
            value={values.phone}
            hasError={errors.phone && touched.phone}
            />
        </div>
      </FlexRow>


      <Fieldset label="Informações contratuais">
        <Row span={2}>
          <Select
            name="contractModel"
            label="Modelo"
            options={buildOptions(contractModels)}
            disabled={previewMode}
            hasError={errors.contractModel && touched.contractModel}
            />

          <InputDate
            name="contractStartDate"
            label="Data do início"
            disabled={previewMode}
            hasError={errors.contractStartDate && touched.contractStartDate}
            />
        </Row>
      </Fieldset>
    </TabContainer>
  )
};

export default TabGeneral;
