import { getMonth, getYear, lastDayOfMonth } from 'date-fns/esm';
import _ from 'lodash';
import { useEffect } from 'react';
import { formats } from '~/helper';
import useGlobalContext from '~/view-data/use-global-context';
import useHeader from '~/view-data/use-header';
import useFetch from '~/view-data/use-fetch';

const initialState = {
  loading: false,
  data: [],
};

const useDashboard = () => {
  const { state: { dashboard = initialState } } = useGlobalContext('dashboard'),
    { state: headerState, actions: headerActions } = useHeader();

  useEffect(() => {
    let now = new Date();
    headerActions.callFilter({ month: getMonth(now), year: getYear(now) });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    headerActions.configure({
      useFilter: true,
      title: prepareTitle(headerState.filter.data),
      count: 'none',
      loading: dashboard.loading,
    });
    // eslint-disable-next-line
  }, [
    dashboard.loading,
    headerState.filter.data.year,
    headerState.filter.data.month,
  ]);

  const { data } = useFetch({
    url: 'dashboard/load',
    params: _.get(headerState, 'filter.data'),
  });

  function prepareTitle(data) {
    const month = _.get(data, 'month'),
      year = _.get(data, 'year');

    if ((!month && month !== 0) || !year) {
      return '';
    }
    const startDate = new Date(year, month),
      endDate = lastDayOfMonth(startDate),
      timeStr = `${formats.date(startDate)} até ${formats.date(endDate)}`;

    return `Dashboard - Período: ${timeStr}`;
  }

  function load(params) {
    headerActions.callFilter(params);
  }

  return {
    state: {
      ...dashboard,
      loading: !data,
      data: data || {},
    },
    actions: {
      load,
    },
  };
};

export default useDashboard;
